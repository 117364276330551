import React, { useCallback, useEffect, useRef, useState } from "react";
import { AccessibilityMajor } from "@shopify/polaris-icons";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../config/store";
import { getCurrentUser, logout } from "../store/user.store.reducer";
//  import { setMessage,  clearMessage } from "store/toast.store.reducer";

import Menumain from "../config/menu";
import Logo_dark from "media/logo-becare-web-xanh.png";
import Logo_light from "media/logo-becare-web-xanh.png";

import {
  ActionList,
  Card,
  Frame,
  Toast,
  VisuallyHidden,
  Icon,
  TopBar,
  Button,
  FormLayout,
} from "@shopify/polaris";
import {
  EditMajor,
  LogOutMinor,
  ProfileMinor,
  ReadTimeMinor,
} from "@shopify/polaris-icons";
import { deleteCookie } from "utils/setCookie";

const checkAuthentication = (): boolean => {
  const session = localStorage.getItem("session");
  return !!session;
};

export default function AppFrame({ children }) {
  /**
   * Check login ...
   */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const skipToContentRef = useRef(null);

  // useEffect(() => {
  //   dispatch(getCurrentUser());
  // }, []);

  const account = useAppSelector((state) => state.user.account);
  // const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isAuthenticating = useAppSelector(
    (state) => state.user.isAuthenticating
  );

  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = checkAuthentication();
    setAuthenticated(isAuthenticated);
    console.log(isAuthenticated);
  }, []);
  const message = useAppSelector((state) => state.toast.message);

  /**
   * Check if login or not, not login? Redirect
   *
  useEffect( () => {
    if ( ! isAuthenticated ) {
      history.push('/login');
    }
  }, [isAuthenticated]);
*/

  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState(
    localStorage.getItem("CATEGORY_TYPE")
  );
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  /**
   * Thay doi giao dien sang toi
   * Lưu vào Cookie để dùng sau!
   */
  const [cookies, setCookie] = useCookies(["isDarkTheme"]);
  const isDarkTheme = String(cookies.isDarkTheme) === "dark" ? true : false;
  const handleThemeChange = () => {
    setCookie("isDarkTheme", !isDarkTheme ? "dark" : "light", {
      path: "/",
      secure: true,
      sameSite: "strict",
    });
  };

  const handleGetCurrentUser = async (_id) => {
    try {
      const response = await dispatch(getCurrentUser(_id));
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    const loggedInUserId = localStorage.getItem("AJAX_USERID");
    if (loggedInUserId) {
      handleGetCurrentUser(loggedInUserId);
    }
  }, []);

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((_userMenuActive) => !_userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (_mobileNavigationActive) => !_mobileNavigationActive
      ),
    []
  );

  const userMenuMarkup = isAuthenticated ? (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: "Thông tin của tôi",
              url: "/profile",
              icon: ProfileMinor,
            },
          ],
        },

        {
          items: [
            {
              content: "Đăng xuất",
              icon: LogOutMinor,
              onAction: async () => {
                localStorage.clear();
                deleteCookie("session");

                await dispatch(logout());

                setTimeout(() => {
                  window.location.href = "/login";
                }, 200);
              },
            },
          ],
        },
      ]}
      name={account?.display_name}
      detail={account?.user_email}
      initials={account?.display_name}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            { content: "Đăng nhập", url: "/login", icon: LogOutMinor },
            {
              content: "Đăng ký tài khoản",
              url: "/register",
              icon: ProfileMinor,
            },
          ],
        },
      ]}
      name="Chào mừng"
      detail="Quý khách"
      initials="U"
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const handCategoryChange = useCallback(() => {
    console.log(searchValue, "searchValue");
    localStorage.setItem("CATEGORY_TYPE", searchValue);
    window.location.reload();
  }, [searchValue]);

  const searchFieldMarkup = (
    <div>
      <FormLayout>
        <FormLayout.Group>
          {/* <div>{localStorage.getItem("AJAX_URL")}</div> */}
          <TopBar.SearchField
            onChange={handleSearchFieldChange}
            value={searchValue}
            placeholder="Input App Name"
          />
          <Button primary onClick={handCategoryChange}>
            Save
          </Button>
        </FormLayout.Group>
      </FormLayout>
    </div>
  );

  /**
   * Nút bật tắt chế độ ban đêm!
   */
  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <span>
          <Icon source={AccessibilityMajor} />
          <VisuallyHidden>Theme mode</VisuallyHidden>
        </span>
      }
      open={false}
      actions={[]}
      onOpen={handleThemeChange}
      onClose={() => {}}
    />
  );
  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
      // searchField={localStorage.getItem("AJAX_URL")}
      // searchField={searchFieldMarkup}
    />
  );

  /* 
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card>
            <TextContainer>
              <SkeletonDisplayText size="medium" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ); */

  const logo = {
    width: 200,
    topBarSource:
      isDarkTheme === true
        ? process.env.REACT_APP_HEADER_IMG
        : process.env.REACT_APP_HEADER_IMG,
    url: "/",
    accessibilityLabel: "nBee",
  };

  // const Toasty = message ? <Toast content={message} onDismiss={() => dispatch(clearMessage()) } duration={4000} /> : null;

  return (
    <div>
      <Frame
        topBar={topBarMarkup}
        logo={logo}
        navigation={<Menumain />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef.current}
      >
        {children}
      </Frame>
    </div>
  );
}
