import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie } from "utils/setCookie";

export const getListPost = async (params): Promise<any> => {
  const url = `${process.env.REACT_APP_AJAX_URL}community/list`;
  // ?order_by=DESC&post_status=all`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params: params,
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updateStatusPost = async (data: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `community/update`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.patch(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
