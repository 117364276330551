import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Exam } from "../interface";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  formatCreatedAt,
  formatDescription,
  formatDuration,
} from "../utils/format";

import "../assets/css/Practice.css";
import dayjs, { Dayjs } from "dayjs";
import {
  createExam,
  deleteExam,
  getDetailExam,
  getListQuestion,
  updateExam,
  uploadMedia,
} from "../service";
import CustomSnackbar from "components/snackbar";
import CreateExamModal from "./Modal/ModalCreateExam";
import EditExamModal from "./Modal/ModalEditExam";
import duration from "dayjs/plugin/duration";

import { FaArrowLeftLong } from "react-icons/fa6";
import ListQuestion from "./Listening/ListQuestions";
import { getCookie } from "utils/setCookie";

dayjs.extend(duration);
interface TabListeningProps {
  exams: Exam[];
  fetchExams: any;
}

const TabListening: React.FC<TabListeningProps> = ({ exams, fetchExams }) => {
  console.log("exams", exams);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [currentExamId, setCurrentExamId] = useState<string>("");
  const [selectedExam, setSelectedExam] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [formattedMilliseconds, setFormattedMilliseconds] = useState<
    number | null
  >(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const [isDetailView, setIsDetailView] = useState(false);
  const [selectedDetailExam, setSelectedDetailExam] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [groupedQuestions, setGroupedQuestions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>, exam: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedExam(exam);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedExam(null);
  };
  useEffect(() => {
    const selectedExam = exams?.find((exam) => exam._id === currentExamId);

    if (selectedExam?.media_id) {
      setUploadedFile({
        name: selectedExam.media_id?.media_file_name,
        id: selectedExam.media_id?._id,
      });
    }
  }, [exams, currentExamId]);

  const handleTimeChange = (newValue: Dayjs | null) => {
    setSelectedTime(newValue);
    if (newValue) {
      const hours = newValue.hour();
      const minutes = newValue.minute();
      const totalMilliseconds = hours * 3600000 + minutes * 60000;
      setFormattedMilliseconds(totalMilliseconds);
    }
  };

  const paginatedExams = exams.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpenCreate = () => {
    setTitle("");
    setDescription("");
    setSelectedTime(null);
    setStartTime(null);
    setEndTime(null);
    setFormattedMilliseconds(null);
    setOpenCreate(true);
  };

  const handleOpenUpdate = (exam: any) => {
    setCurrentExamId(exam._id);
    setTitle(exam.title);
    setDescription(exam.description);
    const durationTime = dayjs.duration(exam.duration_time);
    setSelectedTime(
      dayjs().hour(durationTime.hours()).minute(durationTime.minutes())
    );
    setFormattedMilliseconds(exam.duration_time);
    setStartTime(dayjs(exam.start_time));
    setEndTime(dayjs(exam.end_time));
    setOpenUpdate(true);
  };

  const handleCloseCreate = () => setOpenCreate(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    setIsLoading(true);

    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };

      const uploadResponse = await uploadMedia(file, headers);

      if (uploadResponse && uploadResponse.id) {
        const fileName = uploadResponse.callback?.media_file_name || file.name;

        setUploadedFile({ name: fileName, id: uploadResponse.id });
        setSnackbarMessage("File uploaded successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("File upload failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading the file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
  };

  const handleCreateExam = async () => {
    // Kiểm tra nếu thiếu thông tin
    if (
      !title ||
      !description ||
      !formattedMilliseconds ||
      !startTime ||
      !endTime
    ) {
      setSnackbarMessage("Vui lòng điền đầy đủ thông tin!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      // Gọi API tạo bài thi với các tham số hợp lệ
      const response = await createExam(
        title,
        uploadedFile.id,
        description,
        formattedMilliseconds,
        startTime.toISOString(), // Chuyển startTime thành chuỗi ISO 8601 hợp lệ
        endTime.toISOString(), // Chuyển endTime thành chuỗi ISO 8601 hợp lệ
        "listening" // Tham số cho loại bài thi
      );

      // Sau khi tạo bài thi thành công, fetch lại danh sách bài thi
      await fetchExams();

      // Hiển thị thông báo thành công
      setSnackbarMessage("Tạo bài thi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setOpenCreate(false);
    } catch (error) {
      // Xử lý lỗi khi không thể tạo bài thi
      setSnackbarMessage("Không thể tạo bài thi, vui lòng thử lại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleUpdateExam = async () => {
    try {
      if (!title || !description || !startTime || !endTime) {
        setSnackbarMessage("Vui lòng điền đầy đủ thông tin!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const mediaId = uploadedFile ? uploadedFile.id : "";

      await updateExam(
        currentExamId,
        title,
        mediaId,
        description,
        formattedMilliseconds,
        startTime?.toISOString() ?? "" + 1,
        endTime?.toISOString() ?? "" + 1,
        "listening"
      );
      await fetchExams();
      setSnackbarMessage("Cập nhật bài thi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setOpenUpdate(false);
    } catch (error) {
      setSnackbarMessage("Không thể cập nhật bài thi, vui lòng thử lại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async () => {
    if (selectedExam) {
      try {
        await deleteExam(selectedExam._id);
        setSnackbarMessage(`Đã xóa bài thi: ${selectedExam.title}`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        await fetchExams();
      } catch (error) {
        setSnackbarMessage("Không thể xóa bài thi, vui lòng thử lại.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const fetchQuestions = async (testId: string) => {
    try {
      const response = await getListQuestion(testId);
      setQuestions(response);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  };

  const groupQuestions = (questions: any[]) => {
    const answersByParentId = questions.reduce(
      (acc: Record<string, number>, curr) => {
        if (curr.parent_id) {
          acc[curr.parent_id] = (acc[curr.parent_id] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    const grouped = questions.map((q) => ({
      ...q,
      answerCount: answersByParentId[q._id] || 0,
    }));

    setGroupedQuestions(grouped);
  };

  const handleDetailClick = async (examId: string) => {
    setLoadingDetail(true);
    try {
      const examResponse = await getDetailExam(examId);
      setSelectedDetailExam(examResponse.data);
      await fetchQuestions(examId);
      groupQuestions(questions);
      setIsDetailView(true);
    } catch (error) {
      console.error("Failed to fetch exam details:", error);
    } finally {
      setLoadingDetail(false);
    }
  };

  useEffect(() => {
    groupQuestions(questions);
  }, [questions]);

  const handleBackToList = () => {
    setIsDetailView(false);
    setSelectedDetailExam(null);
  };

  useEffect(() => {
    const contentQuestions = questions.filter((q) => q.content);
    const answersByParentId = questions.reduce((acc, curr) => {
      if (curr.parent_id) {
        acc[curr.parent_id] = (acc[curr.parent_id] || 0) + 1;
      }
      return acc;
    }, {});

    const grouped = contentQuestions.map((q) => ({
      ...q,
      answerCount: answersByParentId[q._id] || 0,
    }));

    setGroupedQuestions(grouped);
  }, [questions]);

  return (
    <>
      <Backdrop
        open={loadingDetail}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isDetailView ? (
        <ListQuestion
          selectedDetailExam={selectedDetailExam}
          groupedQuestions={questions}
          handleBackToList={handleBackToList}
          dataQuestions={fetchQuestions}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
        />
      ) : (
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2, p: 1 }}
          >
            <Typography variant="body1" fontWeight={550} fontSize={20}>
              Danh sách bài thi
            </Typography>
            <Button
              className="save_exam"
              variant="contained"
              onClick={handleOpenCreate}
              sx={{
                border: "1px solid #B32519 !important",
                bgcolor: "#B32519 !important",
                "&:hover": {
                  bgcolor: "#B32519 !important",
                  border: "1px solid #B32519 !important",
                },
              }}
            >
              Tạo mới
            </Button>
          </Stack>
          {exams.length > 0 ? (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="listening exams table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Tên và Mô tả</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Thể loại</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Thời gian</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Ngày tạo</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Hành động</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedExams.map((exam) => (
                    <TableRow key={exam?._id}>
                      <TableCell>
                        <Typography variant="body1">{exam?.title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {formatDescription(exam?.description)}
                        </Typography>
                      </TableCell>
                      <TableCell>{exam?.type}</TableCell>
                      <TableCell>
                        {formatDuration(exam?.duration_time)}
                      </TableCell>
                      <TableCell>{formatCreatedAt(exam?.createdAt)}</TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleClick(e, exam)}>
                          <HiOutlineDotsVertical />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              handleDetailClick(selectedExam?._id);
                            }}
                          >
                            Chi tiết
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              handleOpenUpdate(selectedExam);
                            }}
                          >
                            Chỉnh sửa
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              handleDelete();
                            }}
                          >
                            Xóa
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={exams.length}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableContainer>
          ) : (
            <Typography>Không có bài Listening nào.</Typography>
          )}
          <CreateExamModal
            open={openCreate}
            onClose={handleCloseCreate}
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            onSubmit={handleCreateExam}
            handleTimeChange={handleTimeChange}
            onFileUpload={handleFileUpload}
            onRemoveFile={handleRemoveFile}
            uploadedFile={uploadedFile}
          />

          <EditExamModal
            open={openUpdate}
            onClose={() => setOpenUpdate(false)}
            _id={currentExamId}
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            onSubmit={handleUpdateExam}
            handleTimeChange={handleTimeChange}
            onFileUpload={handleFileUpload}
            onRemoveFile={handleRemoveFile}
            uploadedFile={uploadedFile}
          />

          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleSnackbarClose}
          />
        </Box>
      )}
    </>
  );
};

export default TabListening;
