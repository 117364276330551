import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { getListRigisterSpeaking, getListTeacher } from "../service";
import ItemRegisterSpeaking from "../components/item.register.speaking";
import { useInfiniteScroll } from "entities/community/components/useInfiniteScroll";
import { User } from "entities/users/interface";

const PracticeSpeaking = () => {
  const [teachers, setTeachers] = useState<User[]>([]);

  const { items: posts, loading } = useInfiniteScroll(
    getListRigisterSpeaking,
    { order_by: "DESC" },
    {
      initialLimit: 10,
      threshold: 100,
    }
  );

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const response = await getListTeacher({ user_role: "teacher" });
        setTeachers(response.data);
      } catch (err) {
      } finally {
      }
    };

    fetchTeacher();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        px: { xs: 1, sm: 2, md: 3, gap: 10, paddingTop: 10, paddingBottom: 10 },
        p: "30px 20px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550} mb={1}>
          Đề thi thử Speaking
        </Typography>
      </Stack>
      {posts.map((item, index) => (
        <ItemRegisterSpeaking key={index} data={item} teachers={teachers} />
      ))}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PracticeSpeaking;
