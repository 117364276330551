import { useState, useEffect, useCallback, useRef } from "react";

interface UseInfiniteScrollOptions {
  initialLimit?: number;
  threshold?: number;
}

interface UseInfiniteScrollResult<T> {
  items: T[];
  loading: boolean;
  hasMore: boolean;
  loadMore: () => void;
  reset: () => void;
  _requestData?: () => void;
}

export const useInfiniteScroll = <T>(
  fetchData: (param: any) => Promise<T[]>,
  params: any,
  options: UseInfiniteScrollOptions = {}
): UseInfiniteScrollResult<T> => {
  const { initialLimit = 10, threshold = 200 } = options;

  const [items, setItems] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadingRef = useRef(false);

  // Function to fetch more data
  const loadMore = useCallback(async () => {
    if (loading || loadingRef.current || !hasMore) return;
    console.log("res12");

    loadingRef.current = true;
    setLoading(true);
    try {
      fetchData({ page: page, limit: initialLimit, ...params }).then(
        (res: any) => {
          console.log("res.list..", res);
          const data = res.data;
          if (Array.isArray(data)) {
            if (data.length < initialLimit) {
              setHasMore(false);
            }
            setItems((prev) => [...prev, ...data]);
            setPage((prev) => prev + 1);
          }
        }
      );
    } catch (error) {
      console.error("Error loading more items:", error);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  }, [page, loading, hasMore, initialLimit, fetchData]);
  const _requestData = useCallback(() => {
    loadingRef.current = true;
    setLoading(true);
    try {
      fetchData({ page: "1", limit: initialLimit, ...params }).then(
        (res: any) => {
          console.log("res.list..", res);
          const data = res.data;
          if (Array.isArray(data)) {
            if (data.length < initialLimit) {
              setHasMore(false);
            }
            setItems(data);
            setPage(2);
          }
        }
      );
    } catch (error) {
      console.error("Error loading more items:", error);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  }, []);
  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  // Handle scroll event
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - threshold &&
        page > 1
      ) {
        loadMore();
      }
    }, 300); // 100ms debounce

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadMore, threshold]);

  // Initial load
  useEffect(() => {
    if (page === 1) {
      loadMore();
    }
  }, []);

  // Reset function
  const reset = () => {
    setItems([]);
    setPage(1);
    setHasMore(true);
    setLoading(false);
  };

  return {
    items,
    loading,
    hasMore,
    loadMore,
    reset,
    _requestData,
  };
};
