import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "utils/setCookie";

export const getListPodcast = async (params): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/list";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params,
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getListPodcastCategory = async (): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/list-category";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createNew = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/create";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.post(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const uploadMedia = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  axios.defaults.timeout = 30000;
  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_AJAX_URL}media/create`,
      headers,
      data: formData,
    };

    const response = await axios.request(config);

    if (
      response.data &&
      response.data[0] &&
      response.data[0].callback &&
      response.data[0].callback._id &&
      response.data[0].src
    ) {
      return { id: response.data[0].callback._id, src: response.data[0].src };
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const deletePodcast = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `podcast/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updatePodcast = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.patch(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createCategory = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/create-category";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.post(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const updateCategory = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "podcast/update-category";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.patch(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteCategory = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `podcast/delete-category/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
