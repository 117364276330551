import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateCouponModal from "./components/CreateCouponModal";
import { CouponData, CouponUpdate } from "./interface";
import {
  createCoupon,
  deleteCoupon,
  getListCoupon,
  updateCoupon,
} from "./service";
import PromoCard from "./components/PromoCard";
import EditCouponModal from "./components/EditCouponModal";
import CustomSnackbar from "components/snackbar";
import { EmptyState } from "@shopify/polaris";
import emptyIMG from "../../media/empty.png";
const Coupon = () => {
  const [coupons, setCoupons] = useState<CouponData[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [couponDataUpdate, setCouponDataUpdate] = useState<
    Partial<CouponUpdate>
  >({});
  const [selectedCoupon, setSelectedCoupon] = useState<CouponUpdate | null>(
    null
  );
  const [title, setTitle] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<string>("all");
  const [visible, setVisible] = useState<string>("public");
  const [adornment, setAdornment] = useState<string>("%");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [filters, setFilters] = useState({
    title: "",
    payment_method: "all",
    visible: "public",
  });

  const [couponData, setCouponData] = useState({
    title: "",
    code: "",
    description: "",
    payment_method: "all",
    type: "",
    total: "",
    promotion: "",
    promotion_type: "percentage",
    expired: "",
    availableAt: "",
    visible: "public",
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fetchCoupons = async () => {
    try {
      setIsLoading(true);
      const response = await getListCoupon(title, paymentMethod, visible);
      setCoupons(response.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [title, paymentMethod, visible]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleAdornment = () => {
    setAdornment((prev) => (prev === "%" ? "VND" : "%"));
    setCouponData({
      ...couponData,
      promotion_type: adornment === "%" ? "value" : "percentage",
    });
  };

  const handleCloseModalUpdate = () => {
    setOpenModalEdit(false);
    setCouponDataUpdate({});
    setSelectedCoupon(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCouponData({ ...couponData, [name]: value });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangePayment = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setPaymentMethod(value);
    setCouponData({ ...couponData, type: value });
  };

  const handleChangeVisible = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setVisible(event.target.value as string);
  };

  const handleOpenModalUpdate = (coupon: CouponData) => {
    const updatedCoupon: CouponUpdate = {
      ...coupon,
      expired: coupon.expired ? coupon.expired.split("T")[0] : "",
      availableAt: coupon.availableAt ? coupon.availableAt.split("T")[0] : "",
    };

    setSelectedCoupon(updatedCoupon);
    setCouponDataUpdate(updatedCoupon);
    setOpenModalEdit(true);
  };

  const handleChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCouponDataUpdate((prev) => ({
      ...prev,
      [name]:
        name === "code"
          ? value.toUpperCase()
          : name === "total" || name === "promotion"
          ? Number(value)
          : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await createCoupon(
        couponData.title,
        couponData.code,
        couponData.description,
        couponData.payment_method,
        "product",
        Number(couponData.promotion),
        couponData.promotion_type,
        Number(couponData.total),
        couponData.expired,
        couponData.availableAt,
        visible
      );
      setCoupons([...coupons, response.data]);
      setOpenModal(false);
      await fetchCoupons();

      setSnackbarMessage(`Tạo mã giảm giá thành công`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Tạo mã giảm giá thất bại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSubmitUpdate = async () => {
    if (!couponDataUpdate._id) {
      console.error("No coupon ID provided for update");
      return;
    }

    try {
      const updatedCode = (couponDataUpdate.code || "").toUpperCase();
      await updateCoupon(
        couponDataUpdate._id,
        couponDataUpdate.title || "",
        couponDataUpdate.code || "",
        couponDataUpdate.description || "",
        couponDataUpdate.payment_method || "",
        couponDataUpdate.type || "",
        couponDataUpdate.promotion || 0,
        couponDataUpdate.promotion_type || "",
        couponDataUpdate.total || 0,
        couponDataUpdate.expired || "",
        couponDataUpdate.availableAt || "",
        couponDataUpdate.visible || ""
      );
      fetchCoupons();
      handleCloseModalUpdate();
      await fetchCoupons();
      setSnackbarMessage(`Cập nhật mã giảm giá thành công!`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Cập nhật mã giảm giá thất bại.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteCoupon = async (couponId: string) => {
    if (window.confirm("Bạn có chắc xóa bõ mã giảm giá này không?")) {
      try {
        await deleteCoupon(couponId);
        fetchCoupons();
        setSnackbarMessage(`Xóa mã giảm giá thành công!`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        await fetchCoupons();

        handleCloseModalUpdate();
      } catch (error) {
        console.error("Error deleting coupon:", error);
        setSnackbarMessage("Xóa mã giảm giá thất bại.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMobileSmall = useMediaQuery("(max-width: 375px)");
  const isIphone14 = useMediaQuery("(max-width: 430px)");
  return (
    <Box width="100%" maxWidth={1100} margin="auto" p={1}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={isMobile ? 2 : 0}
        mb={2}
      >
        <Typography variant="h5" fontWeight={550}>
          Danh sách mã giảm giá
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <Button
            variant="contained"
            onClick={handleOpenModal}
            sx={{
              textTransform: "none",
              fontWeight: 550,
              bgcolor: "#B32519",
              "&:hover": { bgcolor: "#B32519" },
            }}
          >
            Tạo mã giảm giá
          </Button>
        </Stack>
      </Stack>

      <Stack spacing={2} mb={2}>
        <TextField
          label="Tiêu đề"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />

        <Stack direction="row" spacing={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="payment-method-label">
              Phương thức thanh toán
            </InputLabel>
            <Select
              labelId="payment-method-label"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              label="Phương thức thanh toán"
            >
              <MenuItem value="all">Tất cả</MenuItem>
              <MenuItem value="transfer">Chuyển khoản</MenuItem>
              <MenuItem value="vn_pay">VN Pay</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel id="visibility-label">Hiển thị</InputLabel>
            <Select
              labelId="visibility-label"
              value={visible}
              onChange={(e) => setVisible(e.target.value)}
              label="Hiển thị"
            >
              <MenuItem value="private">Riêng tư</MenuItem>
              <MenuItem value="public">Công khai</MenuItem>
              <MenuItem value="product">Sản phẩm</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {/* <Button variant="contained" onClick={fetchCoupons}>
          Lọc
        </Button> */}
      </Stack>
      {!isLoading && coupons.length == 0 ? (
        <EmptyState heading="Không có mã nào!" image={emptyIMG} />
      ) : (
        <Box
          display="grid"
          gridTemplateColumns={isMobile ? "1fr" : " 1fr 1fr 1fr"}
          gap={2}
        >
          {coupons.map((coupon) => (
            <Box key={coupon._id} onClick={() => handleOpenModalUpdate(coupon)}>
              <PromoCard coupon={coupon} />
            </Box>
          ))}
        </Box>
      )}

      <CreateCouponModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        couponData={couponData}
        handleChange={handleChange}
        handleChangePayment={handleChangePayment}
        paymentMethod={paymentMethod}
        handleChangeVisible={handleChangeVisible}
        visible={visible}
        toggleAdornment={toggleAdornment}
        adornment={adornment}
        handleSubmit={handleSubmit}
      />

      <EditCouponModal
        open={openModalEdit}
        onClose={handleCloseModalUpdate}
        couponData={couponDataUpdate}
        onChange={handleChangeUpdate}
        onSubmit={handleSubmitUpdate}
        setCouponData={setCouponData}
        onDelete={() => handleDeleteCoupon(couponDataUpdate._id!)}
        adornment={adornment}
        toggleAdornment={toggleAdornment}
      />

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Coupon;
