import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Pagination,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import {
  createCourse,
  deleteCourse,
  getDetailCourseById,
  getListCourse,
  getListTime,
  uploadMedia,
} from "./service";
import CreateCourse from "./components/course.create";
import { IoIosSearch } from "react-icons/io";
import { ListItemData } from "./interface";
import Masonry from "@mui/lab/Masonry";
import { format } from "date-fns";
import ListDetail from "./components/course.detail";
import CustomSnackbar from "components/snackbar";
import Time from "./components/course.time-available";
const initialFormData = {
  price: "",
  title: "",
  avatar: "",
  country: "country",
  version: "version",
  end_time: "",
  language: "en",
  media_id: "",
  media_video: "",
  class_duration: "",
  lession_count: 0,
  product_id: "product_id",
  start_time: "",
  description: "",
  trash_status: "trash_status",
  public_status: "draft",
  long_description: "",
  type: "Call 1-1",
  coupon_id: null,
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "600px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  borderRadius: "3px",
  p: 2,
};

const styleDetail = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "600px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  borderRadius: "3px",
};

const Course = () => {
  const itemsPerPage = 9;
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTime, setOpenModalTime] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCir, setLoadingCir] = useState(false);
  const [loadingCirImage, setLoadingCirImage] = useState(false);
  const [loadingCirVideo, setLoadingCirVideo] = useState(false);
  const [type, setType] = useState("self-learning");
  const [getIdMedia, setGetIdMedia] = useState<string>("");
  const [mediaSrc, setMediaSrc] = useState<string>("");
  const [mediaSrcImage, setMediaSrcImage] = useState("");
  const [mediaSrcVideo, setMediaSrcVideo] = useState("");
  const [mediaType, setMediaType] = useState<string>("");
  const [getDataListCourse, setGetDataListCourse] = useState([]);
  const [getDetailCourse, setGetDetailCourse] = useState(null);
  const [dataLabels, setDataLabels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [showUploadButtonImage, setShowUploadButtonImage] = useState(true);
  const [showUploadButtonVideo, setShowUploadButtonVideo] = useState(true);
  const [dataMyCourse, setDataMyCourse] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [formData, setFormData] = useState<ListItemData>({
    price: "",
    title: "",
    avatar: "",
    country: "country",
    version: "version",
    end_time: "",
    language: "en",
    media_id: "",
    media_video: "",
    class_duration: "",
    lession_count: 0,
    product_id: "product_id",
    start_time: "",
    description: "",
    trash_status: "trash_status",
    public_status: "success",
    long_description: "",
    type: "Call 1-1",
    coupon_id: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const dataCourse = await getListCourse();
        const courseList = dataCourse.data;
        courseList.reverse();
        setGetDataListCourse(courseList);
        setTotalCourses(courseList.length);
        const labels = courseList.map((course) => course.labels).flat();
        setDataLabels(labels);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDetailsClick = async (id) => {
    try {
      const response = await getDetailCourseById(id);
      setGetDetailCourse(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
    setOpenModalDetail(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };
  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setFormData({
      price: "",
      title: "",
      avatar: "",
      country: "country",
      version: "version",
      end_time: "",
      language: "en",
      media_id: "",
      media_video: "",
      class_duration: "",
      lession_count: 0,
      product_id: "product_id",
      start_time: "",
      description: "",
      trash_status: "trash_status",
      public_status: "success",
      long_description: "",
      type: "Call 1-1",
      coupon_id: null,
    });
    setOpenModal(false);
    setMediaSrcImage(null);
    setMediaSrcVideo(null);
    setShowUploadButtonImage(true);
    setShowUploadButtonVideo(true);
  };

  const handleOpenModalTime = async () => {
    const created_user_id = localStorage.getItem("AJAX_USERID");
    if (created_user_id) {
      try {
        const response = await getListTime(created_user_id);
        setDataMyCourse(response.data);
      } catch (err) {}
    } else {
    }
    setOpenModalTime(true);
  };

  const handleCloseModalTime = () => setOpenModalTime(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseModalDetail = () => setOpenModalDetail(false);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const filteredData = getDataListCourse.filter((course) => {
    return course.user_id?.display_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  });

  const currentData = filteredData.slice(startIndex, endIndex);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleFileChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        setLoadingCir(true);

        const file = files[0];
        const headers = {
          "X-Authorization":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8",
        };
        const { id, src } = await uploadMedia(file, headers);

        if (file.type.startsWith("image/")) {
          setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              avatar: id,
              media_id: null,
            };
            return updatedFormData;
          });
          setMediaType("image");
        } else if (file.type === "video/mp4") {
          setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              media_id: id,
            };
            return updatedFormData;
          });
          setMediaType("video");
        }
        setLoadingCir(false);
        setShowUploadButton(false);
        setGetIdMedia(id);
        setMediaSrc(src);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      setLoadingCir(false);
    } finally {
      console.log("Upload complete");
    }
  };

  const handleFileChangeAndUploadCallOneOne = async (event) => {
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        setLoadingCirImage(true);

        const file = files[0];
        const headers = {
          "X-Authorization":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8",
        };

        const { id, src } = await uploadMedia(file, headers);

        setFormData((prevFormData) => ({
          ...prevFormData,
          avatar: id,
          media_id: null,
        }));
        setMediaType("image");
        setShowUploadButtonImage(false);
        setLoadingCirImage(false);
        setMediaSrcImage(src);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      setLoadingCirImage(false);
    } finally {
      console.log("Upload complete");
      setLoadingCirImage(false);
    }
  };

  const handleFileChangeAndUploadCallVideo = async (event) => {
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        setLoadingCirVideo(true);

        const file = files[0];
        const headers = {
          "X-Authorization":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8",
        };

        const { id, src } = await uploadMedia(file, headers);

        setFormData((prevFormData) => ({
          ...prevFormData,
          media_video: id,
          media_id: null,
        }));
        setMediaType("video");
        setShowUploadButtonVideo(false);
        setLoadingCirVideo(false);
        setMediaSrcVideo(src);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      setLoadingCirVideo(false);
    } finally {
      console.log("Upload complete");
      setLoadingCirVideo(false);
    }
  };

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event: Event) => {
      handleFileChangeAndUpload(
        event as unknown as React.ChangeEvent<HTMLInputElement>
      );
    };
    input.click();
  };

  const handleImageClickOneOne = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event: Event) => {
      handleFileChangeAndUploadCallOneOne(
        event as unknown as React.ChangeEvent<HTMLInputElement>
      );
    };
    input.click();
  };

  const handleSubmit = async () => {
    try {
      const response = await createCourse(
        formData.price,
        formData.title,
        formData.avatar,
        formData.country,
        formData.version,
        formData.end_time,
        formData.language,
        formData.media_id,
        formData.media_video,
        formData.class_duration,
        formData.lession_count,
        formData.product_id,
        formData.start_time,
        formData.description,
        formData.trash_status,
        formData.public_status,
        formData.long_description,
        formData.type,
        formData.coupon_id
      );

      setFormData(response);
      setGetDataListCourse((prevCourses) => [response.data, ...prevCourses]);
      setTotalCourses((prevTotal) => prevTotal + 1);
      handleCloseModal();

      setSnackbarMessage("Tạo thành công");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Tạo thất bại");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCourse(id);
      setGetDataListCourse((prevCourses) =>
        prevCourses.filter((course) => course._id !== id)
      );
      setTotalCourses((prevTotal) => prevTotal - 1);

      setSnackbarMessage("Xóa thành công");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      setOpenModalDetail(false);
    } catch (error) {
      setSnackbarMessage("Xóa thất bại");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChangeCourse = (field: string, value: string) => {
    let parsedValue: number | string;

    if (field === "lession_count") {
      const intValue = parseInt(value, 10);

      parsedValue = isNaN(intValue) ? 0 : intValue;
    } else {
      parsedValue = value;
    }

    setFormData({
      ...formData,
      [field]: parsedValue,
    });
  };

  const formattedAmount = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  const statusColorMap = {
    active: "success",
    pending: "error",
  };

  const statusName = {
    draf: "Bản nháp",
    active: "Hoạt động",
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    return format(date, "'Ngày' dd 'tháng' MM 'năm' yyyy");
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "30px 20px",
      }}
    >
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateCourse
          handleCloseModal={handleCloseModal}
          handleImageClickOneOne={handleImageClickOneOne}
          handleImageClick={handleImageClick}
          showUploadButton={showUploadButton}
          showUploadButtonImage={showUploadButtonImage}
          showUploadButtonVideo={showUploadButtonVideo}
          loadingCir={loadingCir}
          loadingCirImage={loadingCirImage}
          loadingCirVideo={loadingCirVideo}
          mediaSrc={mediaSrc}
          mediaSrcVideo={mediaSrcVideo}
          mediaSrcImage={mediaSrcImage}
          mediaType={mediaType}
          handleChangeCourse={handleChangeCourse}
          handleSubmit={handleSubmit}
          formData={formData}
          style={style}
          handleFileChangeAndUpload={handleFileChangeAndUpload}
          handleFileChangeAndUploadCallOneOne={
            handleFileChangeAndUploadCallOneOne
          }
          handleFileChangeAndUploadCallVideo={
            handleFileChangeAndUploadCallVideo
          }
          type={type}
          handleChange={handleChange}
        />
      </Modal>

      <Modal
        open={openModalDetail}
        onClose={handleCloseModalDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ListDetail
          statusName={statusName}
          handleDelete={handleDelete}
          styleDetail={styleDetail}
          getDetailCourse={getDetailCourse}
          formattedAmount={formattedAmount}
          formatDate={formatDate}
          statusColorMap={statusColorMap}
        />
      </Modal>

      <Modal
        open={openModalTime}
        onClose={handleCloseModalTime}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Time
            setSnackBarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
            setSnackbarOpen={setSnackbarOpen}
            dataMyCourse={dataMyCourse}
          />
        </Box>
      </Modal>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550}>
          Danh sách Khóa học
        </Typography>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          color="success"
          sx={{
            fontWeight: 550,
            height: "45px",
            bgcolor: "#B32519",
            "&:hover": { bgcolor: "#B32519" },
          }}
        >
          Tạo khóa học
        </Button>
      </Stack>

      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          mt: 3,
          padding: 3,
          borderRadius: "3px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      >
        <Stack
          direction="row"
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              Danh mục ({totalCourses})
            </Typography>
            <TextField
              placeholder="Tìm kiếm khóa học"
              size="small"
              variant="outlined"
              sx={{ width: "100%", maxWidth: "250px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <IoIosSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Stack>
          <Button
            onClick={handleOpenModalTime}
            variant="contained"
            color="success"
            sx={{
              fontWeight: 550,
              height: "45px",
              bgcolor: "#B32519",
              "&:hover": { bgcolor: "#B32519" },
            }}
          >
            Thời gian khóa học 1-1
          </Button>
        </Stack>

        <Box>
          <Masonry columns={3} spacing={2} sx={{ margin: "0 auto !important" }}>
            {currentData.map((course, index) => (
              <Card
                key={index}
                onClick={() => handleDetailsClick(course._id)}
                sx={{
                  maxWidth: 400,
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  "&:hover": { backgroundColor: "rgba(251, 251, 251, 0.6)" },
                  cursor: "pointer",
                  zIndex: "1",
                }}
              >
                <CardMedia
                  component="img"
                  height="194"
                  image={course?.avatar?.media_url}
                  alt=""
                />
                <CardContent sx={{ color: "#333" }}>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" fontWeight={550} mt={1} mb={1}>
                      {course.title}
                    </Typography>
                    <Chip
                      label={statusName[course.public_status]}
                      color={statusColorMap[course.public_status]}
                      sx={{
                        backgroundColor:
                          course.public_status === "active"
                            ? "#50C878"
                            : "#FF5733",
                        color: "#fff",
                      }}
                    />
                  </Stack>
                  <Typography variant="body2" fontWeight={500} mt={1} mb={1}>
                    {course.user_id?.display_name || "Unknown User"}
                  </Typography>
                  <Typography variant="body2" fontWeight={550}>
                    {formattedAmount.format(course.price)}
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {course.review_count === 0 ? (
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        fontSize={12}
                      >
                        Chưa có đánh giá
                      </Typography>
                    ) : (
                      <Typography variant="body2" fontWeight={500}>
                        Có {course.review_count} đánh giá
                      </Typography>
                    )}
                  </Typography>
                  {course.labels &&
                    course.labels.map((label, index) => (
                      <Chip key={index} label={label} />
                    ))}
                </CardContent>
              </Card>
            ))}
          </Masonry>
          <Pagination
            count={Math.ceil(getDataListCourse.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Course;
