import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "utils/setCookie";

export const getListExam = async (type?: string): Promise<any[]> => {
  let url = process.env.REACT_APP_AJAX_URL + `test/list?order_by=DESC`;
  if (type) {
    url += `&type=${type}`;
  }

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch group data");
  }
};

export const getListQuestion = async (test_id?: string): Promise<any[]> => {
  let url =
    process.env.REACT_APP_AJAX_URL + `test/question/list?test_id=${test_id}`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch group data");
  }
};

export const getDetailExam = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `test/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getDetailQuestion = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `test/question/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createExam = async (
  title: string,
  media_id: string,
  description: string,
  duration_time: number,
  start_time: string,
  end_time: string,
  type: string
): Promise<any> => {
  const requestData = {
    title,
    media_id,
    description,
    duration_time,
    start_time,
    end_time,
    type,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "test/create";
  return axios.post(url, requestData, axiosConfig);
};

export const createExamReading = async (
  title: string,
  description: string,
  duration_time: number,
  start_time: string,
  end_time: string,
  type: string
): Promise<any> => {
  const requestData = {
    title,
    description,
    duration_time,
    start_time,
    end_time,
    type,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "test/create";
  return axios.post(url, requestData, axiosConfig);
};

export const createQuestion = async (data: {
  test_id: string;
  title: string;
  content: string;
  type: string;
  part: string;
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/create";

  return axios.post(url, { data: [data] }, axiosConfig);
};

export const createQuestionText = async (data: {
  test_id: string;
  index: number;
  title: string;
  answer: string;
  content: string;
  type: string;
  part: string;
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/create";

  return axios.post(url, { data: [data] }, axiosConfig);
};

export const createQuestionCheck = async (data: {
  test_id: string;
  index: number;
  title: string;
  content: string;
  type: string;
  part: string;
  options: string[];
  answer: string;
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/create";

  return axios.post(url, { data: [data] }, axiosConfig);
};

export const updateQuestion = async (data: {
  _id: string;
  test_id: string;
  title: string;
  content: string;
  type: string;
  part: string;
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/update";

  return axios.patch(url, data, axiosConfig);
};

export const updateQuestionText = async (data: {
  _id: string;
  test_id: string;
  index: number;
  title: string;
  answer: string;
  content: string;
  type: string;
  part: string;
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/update";

  return axios.patch(url, data, axiosConfig);
};

export const updateQuestionCheck = async (data: {
  _id: string;
  test_id: string;
  index: number;
  title: string;
  answer: string;
  content: string;
  type: string;
  part: string;
  options: string[];
}): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/update";

  return axios.patch(url, data, axiosConfig);
};

export const createAnswer = async (
  test_id: string,
  parent_id: string,
  index: number,
  title: string,
  answer: string,
  type: string,
  part: string
): Promise<any> => {
  const requestData = {
    data: [
      {
        test_id,
        parent_id,
        index,
        title,
        answer,
        type,
        part,
      },
    ],
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/create";
  return axios.post(url, requestData, axiosConfig);
};

export const createAnswerReading = async (
  test_id: string,
  parent_id: string,
  index: number,
  amount_of_option: number,
  title: string,
  answer: string,
  type: string,
  part: string,
  options: string[]
): Promise<any> => {
  const requestData = {
    data: [
      {
        test_id,
        parent_id,
        index,
        title,
        amount_of_option,
        answer,
        type,
        part,
        options,
      },
    ],
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/create";
  return axios.post(url, requestData, axiosConfig);
};

export const updateAnswerReading = async (
  _id: string,
  test_id: string,
  index: number,
  title: string,
  amount_of_option: number,

  answer: string,
  type: string,
  part: string
): Promise<any> => {
  const requestData = {
    _id,
    test_id,
    index,
    title,
    amount_of_option,
    answer,
    type,
    part,
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/update";
  return axios.patch(url, requestData, axiosConfig);
};

export const updateAnswer = async (
  _id: string,
  test_id: string,
  index: number,
  title: string,
  answer: string,
  type: string,
  part: string
): Promise<any> => {
  const requestData = {
    _id,
    test_id,
    index,
    title,
    answer,
    type,
    part,
  };

  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };

  const url = process.env.REACT_APP_AJAX_URL + "test/question/update";
  return axios.patch(url, requestData, axiosConfig);
};

export const uploadMedia = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const TIMEOUT_DURATION = 30 * 60 * 1000;
  let uploadTimeout: NodeJS.Timeout;

  try {
    const formData = new FormData();
    formData.append("file[]", file);

    const config: AxiosRequestConfig = {
      method: "post",
      url: `https://media.exam24h.com/upload-file?callback=${process.env.REACT_APP_AJAX_URL}media/create`,
      headers,
      data: formData,
      timeout: TIMEOUT_DURATION,
    };

    uploadTimeout = setTimeout(() => {
      console.error("Upload timed out");
      throw new Error("Upload timed out");
    }, TIMEOUT_DURATION);

    const response = await axios.request(config);

    clearTimeout(uploadTimeout);

    if (
      response.data &&
      response.data[0] &&
      response.data[0].callback &&
      response.data[0].callback._id &&
      response.data[0].src
    ) {
      return { id: response.data[0].callback._id, src: response.data[0].src };
    } else {
      throw new Error("Upload failed: Invalid response");
    }
  } catch (error) {
    clearTimeout(uploadTimeout);
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const updateExam = async (
  _id: string,
  title: string,
  media_id: string,
  description: string,
  duration_time: number,
  start_time: string,
  end_time: string,
  type: string
): Promise<any> => {
  const requestData = {
    _id,
    title,
    media_id,
    description,
    duration_time,
    start_time,
    end_time,
    type,
  };
  const url = process.env.REACT_APP_AJAX_URL + "test/update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updateExamReading = async (
  _id: string,
  title: string,
  description: string,
  duration_time: number,
  start_time: string,
  end_time: string,
  type: string
): Promise<any> => {
  const requestData = {
    _id,
    title,
    description,
    duration_time,
    start_time,
    end_time,
    type,
  };
  const url = process.env.REACT_APP_AJAX_URL + "test/update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteExam = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `test/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteQuestion = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `test/question/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
