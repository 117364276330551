// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePicker,
.timePicker {
    width: 120px;
}

.datePicker input,
.timePicker input {
   height: 10px;
}

.MuiOutlinedInput-root {
    margin-bottom: 10px;
}

.comingSoon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 550;
    color: #fff;
}

.redeem_mission {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 900px;
    background-color: #f5f5f5; 
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.5); 
    padding: 16px;
    height: 100%;
    max-height: 600px;
    overflow-x: auto;

}`, "",{"version":3,"sources":["webpack://./src/entities/mission/styles/mission.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB;;AAEA;;GAEG,YAAY;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,oCAAoC;IACpC,kBAAkB;IAClB,uCAAuC;IACvC,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;;AAEpB","sourcesContent":[".datePicker,\n.timePicker {\n    width: 120px;\n}\n\n.datePicker input,\n.timePicker input {\n   height: 10px;\n}\n\n.MuiOutlinedInput-root {\n    margin-bottom: 10px;\n}\n\n.comingSoon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: rgba(0, 0, 0, 0.9);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 25px;\n    font-weight: 550;\n    color: #fff;\n}\n\n.redeem_mission {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    max-width: 900px;\n    background-color: #f5f5f5; \n    border: 1px solid rgba(0, 0, 0, 0.5);\n    border-radius: 4px;\n    box-shadow: 0 0 24px rgba(0, 0, 0, 0.5); \n    padding: 16px;\n    height: 100%;\n    max-height: 600px;\n    overflow-x: auto;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
