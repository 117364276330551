import {
  Autocomplete,
  Badge,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  EmptyState,
  Form,
  FormLayout,
  Icon,
  Layout,
  Link,
  Modal,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  Toast,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/empty.png";
import Pagination from "../../components/pagination";
import {
  clearError,
  getEntities,
  searchDates,
} from "../../store/order.store.reducer";
import OrderFilter from "./filter";
import helpers from "../../helpers";
import dateandtime from "date-and-time";
import CountryName from "components/countries";
import "react-datepicker/dist/react-datepicker.css";
import { getListOrderUser, updateOrderUser } from "./service";
import { FaEdit } from "react-icons/fa";
import { Typography } from "@mui/material";
import CustomSnackbar from "components/snackbar";
export default function General_order() {
  const [initial_loading, setInitial_loading] = useState<boolean>(true);

  const errorMessage = useAppSelector((state) => state.order.errorMessage);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  // const [mainQuery, setMainQuery] = useState({
  //   ...{
  //     page: 1,
  //     limit: 20,
  //     order_by: "DESC",
  //   },
  //   ...StringQuery,
  // });
  const [queryValue, setQueryValue] = useState("");

  /**
   * Change page number
   */

  const [numberPage, setNumberPage] = useState(1);

  const onChangePageNumber = useCallback((numPage) => {
    setNumberPage(numPage);
  }, []);

  useEffect(() => {
    setMainQueryDate({ ...mainQueryDate, page: numberPage });
  }, [numberPage]);

  const [selectedVersion, setSelectedVersion] = useState("");

  const [post_plan_selected, setPost_plan_selected] = useState("");

  /**
   * Status
   */

  const [status, setStatus] = useState("");
  const handleStatus = useCallback((_value) => {
    setStatus(_value);
  }, []);

  /**
   * Payment
   */

  const [payment, setPayment] = useState("");
  const handlePayment = useCallback((_value) => {
    setPayment(_value);
  }, []);

  /**
   * Limit
   */

  const [limit, setLimit] = useState(100);
  const handleLimit = useCallback((value) => {
    setLimit(value);
  }, []);

  /**
   * Input
   */
  const [input, setInput] = useState("");
  const handleFiltersQueryChange = useCallback((value) => setInput(value), []);

  useEffect(() => {
    setMainQueryDate({ ...mainQueryDate, ...{ user_id: input } });
  }, [input]);

  /**
   * Date Filter
   */

  const [mainQueryDate, setMainQueryDate] = useState({
    ...{
      page: 1,
      limit: limit,
      type: "order",
      select: "_id",
      order_by: "DESC",
      from: new Date("01/01/2022").toISOString(),
      to: new Date().toISOString(),
    },
  });

  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItem] = useState(0);
  const [openModalUpdate, setModalUpdate] = useState(false);
  const [orderSelected, setOrderSelected] = useState<any>({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [updateStatus, setUpdateStatus] = useState("");
  const filterDataAction = async () => {
    const data = {
      user_id: input,
      payment_method: payment,
      status: status,
      limit: limit,
    };
    setInitial_loading(true);
    if (data.user_id.trim() == "") delete data.user_id;
    if (data.payment_method.trim() == "") delete data.payment_method;
    if (data.status.trim() == "") delete data.status;
    try {
      const dataOrder = await getListOrderUser(data);
      const orderList = dataOrder.data;
      orderList.reverse();
      setEntities(orderList);
      setInitial_loading(false);
      setTotalItem(orderList.length);
    } catch (err) {
      console.log(err);
    }
  };
  const clearDate = useCallback(async () => {
    // dispatch(getEntities(mainQuery));

    setStatus("");
    setPayment("");
    setLimit(100);
    setInitial_loading(true);
    try {
      const dataOrder = await getListOrderUser();
      const orderList = dataOrder.data;
      orderList.reverse();
      setEntities(orderList);
      setInitial_loading(false);
      setTotalItem(orderList.length);
    } catch (err) {
      console.log(err);
    }
  }, []);
  const updateOrderAction = async () => {
    try {
      const data = {
        _id: orderSelected._id,
        status: updateStatus == "" ? orderSelected.status : updateStatus,
      };
      const dataOrder = await updateOrderUser(data);
      setSnackbarMessage("Cập nhật order thành công!");
      setSnackbarSeverity("success");
      setModalUpdate(false);
      filterDataAction();
    } catch (err) {
      console.log(err);
      setSnackbarMessage("Cập nhật order thất bại!");
      setModalUpdate(false);
      setSnackbarSeverity("error");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataOrder = await getListOrderUser();
        const orderList = dataOrder.data;
        setEntities(orderList);
        setInitial_loading(false);
        setTotalItem(orderList.length);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  // useEffect(() => {
  //   dispatch(searchDates(mainQueryDate));
  // }, [mainQueryDate]);

  const paymentMethodMap: Record<string, string> = {
    vn_pay: "VNPAY",
    coin: "COIN",
    smart_banking: "Smart Banking",
  };

  const renderItem = (order: any) => {
    const { _id, user_id, price, payment_method, status, createdAt } = order;
    let formatter = new Intl.NumberFormat("vi-VN");

    return [
      _id,
      user_id?.display_name,
      user_id?._id,
      formatter.format(price) + "đ",
      status === "pending" ? (
        <Badge status="warning" progress="complete">
          Pending
        </Badge>
      ) : status === "processing" ? (
        <Badge status="attention" progress="partiallyComplete">
          Processing
        </Badge>
      ) : status === "fraud" ? (
        <Badge status="warning" progress="partiallyComplete">
          Fraud
        </Badge>
      ) : status === "draft" ? (
        <Badge status="info" progress="complete">
          Draft
        </Badge>
      ) : status === "close" ? (
        <Badge status="critical" progress="complete">
          Close
        </Badge>
      ) : status === "error" ? (
        <Badge status="critical" progress="incomplete">
          Error
        </Badge>
      ) : status === "trial_false" ? (
        <Badge progress="partiallyComplete" status="critical">
          Trial False
        </Badge>
      ) : status === "trial" ? (
        <Badge progress="incomplete" status="success">
          Trial
        </Badge>
      ) : status === "success" ? (
        <Badge status="success" progress="complete">
          Success
        </Badge>
      ) : status === "done" ? (
        <Badge status="success" progress="partiallyComplete">
          Done
        </Badge>
      ) : null,
      paymentMethodMap[payment_method] || "Smart Banking", // ✅ Fix hiển thị đầy đủ các phương thức thanh toán
      <time>
        {createdAt
          ? dateandtime.format(new Date(createdAt), "DD-MM-YYYY HH:mm:ss")
          : "-"}
      </time>,
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setModalUpdate(true);
          setOrderSelected(order);
          setUpdateStatus(status);
        }}
      >
        <FaEdit />
      </div>,
    ];
  };

  const PagesList = (
    // totalItems > 0 ? (
    <>
      <DataTable
        // sortable={[false, false, true, false, false, false, false, true, false]}
        // defaultSortDirection="descending"
        // initialSortColumnIndex={7}
        // onSort={handleSort}
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          <Typography fontSize={14} fontWeight={"bold"}>
            ID
          </Typography>,
          <Typography fontSize={14} fontWeight={"bold"}>
            Họ tên
          </Typography>,
          // "User Login",
          // "User Country",
          <Typography fontSize={14} fontWeight={"bold"}>
            User ID
          </Typography>,
          <Typography fontSize={14} fontWeight={"bold"}>
            Số tiền
          </Typography>,
          // "Age",
          <Typography fontSize={14} fontWeight={"bold"}>
            Trạng thái
          </Typography>,
          // "Plan Name",
          <Typography fontSize={14} fontWeight={"bold"}>
            Hình thức thanh toán
          </Typography>,
          <Typography fontSize={14} fontWeight={"bold"}>
            Thời gian tạo
          </Typography>,
          <Typography fontSize={14} fontWeight={"bold"}>
            Hành động
          </Typography>,
        ]}
        // totals={["", "", `${totalItems}`, `${price}$`, "", "", "", "", "", ""]}
        rows={entities?.map(renderItem)}
        hideScrollIndicator
        footerContent={`Display page ${mainQueryDate.page} of total ${totalItems} results...`}
      />
      <style>{`
        .small-icon {
          font-size: 12px;
          padding :0 ;
          width: 15px;
          height: auto;
        }
      `}</style>
    </>
  );
  // ) : (
  //   emptyData
  // );

  const Actual_page = (
    <Page title="Order" fullWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <Stack distribution="equalSpacing">
                <OrderFilter
                  queryValue={StringQuery?.query}
                  onChange={handleFiltersQueryChange}
                />

                <Select
                  label=""
                  value={status}
                  onChange={handleStatus}
                  options={[
                    { label: "Status", value: "" },
                    { label: "Pending", value: "pending" },
                    { label: "Processing", value: "processing" },
                    { label: "Success", value: "success" },
                  ]}
                />

                {/* <Select label="" options={option_country} onChange={changeHandler} value={country_selected} /> */}

                <Select
                  label=""
                  value={payment}
                  onChange={handlePayment}
                  options={[
                    { label: "Payment", value: "" },
                    { label: "VNPAY", value: "vn_pay" },
                    { label: "Smart Banking", value: "smart_banking" },
                    { label: "Coins", value: "coin" },
                  ]}
                />

                <TextField
                  label=""
                  type="number"
                  value={limit.toString()}
                  onChange={handleLimit}
                  autoComplete="off"
                />

                <Stack.Item>
                  <p>Total</p>
                  <Badge status="success">{totalItems.toString()}</Badge>
                </Stack.Item>

                <ButtonGroup>
                  <Button primary onClick={filterDataAction}>
                    Search
                  </Button>
                  <Button onClick={clearDate}>Clear</Button>
                </ButtonGroup>
              </Stack>
            </div>
            {PagesList}
          </Card>
          <br />
          {/* {totalItems > mainQuery.limit ? ( */}
          <Pagination
            TotalRecord={totalItems}
            activeCurrentPage={mainQueryDate.page}
            pageSize={mainQueryDate.limit}
            onChangePage={onChangePageNumber}
          />
          {/* ) : null} */}
        </Layout.Section>
        <Modal
          title="Cập nhật order"
          open={openModalUpdate}
          onClose={() => setModalUpdate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={{ padding: "8px 20px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h6">
              Order ID: {orderSelected?._id}
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h6">
              Số tiền thanh toán:{" "}
              {Number(orderSelected?.price).toLocaleString("vi-VN")} đ
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h6">
              Hình thức thanh toán:{" "}
              {orderSelected?._payment_method == "vn_pay"
                ? "VNPAY"
                : "Smart Banking"}
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6"
              mt={2}
              style={{ fontWeight: "bold" }}
            >
              Cập nhật trạng thái thanh toán
            </Typography>
            <Select
              label=""
              value={updateStatus}
              onChange={(e) => setUpdateStatus(e)}
              options={[
                { label: "Status", value: "" },
                { label: "Pending", value: "pending" },
                { label: "Processing", value: "processing" },
                { label: "Success", value: "success" },
              ]}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                margin: "12px 0px",
              }}
            >
              <Button onClick={() => setModalUpdate(false)}>Thoát</Button>
              <Button primary onClick={() => updateOrderAction()}>
                Lưu lại
              </Button>
            </div>
          </div>
        </Modal>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </Layout>
    </Page>
  );

  const skeleton_loading = (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const toastMarkup = errorMessage ? (
    <Toast content={errorMessage} error onDismiss={toggleActive} />
  ) : null;

  return (
    <>
      {toastMarkup}
      {/* {loading ? skeleton_loading : Actual_page} */}
      {initial_loading ? skeleton_loading : Actual_page}
    </>
  );
}
