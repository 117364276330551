import type { Dayjs, OpUnitType } from "dayjs";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

// ----------------------------------------------------------------------

dayjs.extend(duration);
dayjs.extend(relativeTime);
export type DatePickerFormat = Dayjs | Date | string;

export function fToNow(date: DatePickerFormat) {
  if (!date) {
    return null;
  }
  const isValid = dayjs(date).isValid();

  return isValid ? dayjs(date).toNow(true) : "Invalid time value";
}
