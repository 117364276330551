import React, { useEffect, useRef, useState } from "react";
import { getListPost } from "../service";
import { Box, CircularProgress } from "@mui/material";
import ItemCommunity from "../components/item.community";
import { useInfiniteScroll } from "../components/useInfiniteScroll";
import CustomSnackbar from "components/snackbar";

const Community = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const { items: posts, loading } = useInfiniteScroll(
    getListPost,
    { sort_by: "DES", post_status: "all" },
    {
      initialLimit: 10,
      threshold: 100,
    }
  );
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        px: { xs: 1, sm: 2, md: 3 }, // Responsive padding
      }}
    >
      {posts.map((post, index) => (
        <ItemCommunity
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarMessage={setSnackbarMessage}
          key={index}
          post={post}
        />
      ))}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
          <CircularProgress />
        </Box>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Community;
