import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import React, { useState, useEffect } from "react";
import { User } from "./interface";
import { getListUser, getUserById, uploadAvatar } from "./service";
import "./styles/user.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Textarea from "@mui/joy/Textarea";

import facebook from "../../media/social/facebook.png";
import noData from "../../media/empty.png";
import ListUser from "./components/users.list";
import CustomSnackbar from "components/snackbar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "600px",
  overflowY: "auto",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "3px",
};

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [userData, setUserData] = useState(null);
  const [blockUser, setBlockUser] = useState([]);
  const [follows, setFollows] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<null | any>(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [role, setRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [noUsersFound, setNoUsersFound] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleFileChangeAndUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setLoadingAvatar(true);
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const headers = {
          "X-Authorization":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8",
        };
        const imageUrl = await uploadAvatar(file, headers);
        setImageURL(imageUrl);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoadingAvatar(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getListUser();
      setUsers(response.data);
    } catch (err) {
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.display_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.user_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.user_login.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredUsers(filtered);
    setNoUsersFound(filtered.length === 0);
  }, [searchQuery, users]);

  const handleDetailsClick = async (id) => {
    try {
      const response = await getUserById(id);
      setUserData(response.data);
      if (response.data) {
        setImageURL(response.data.user_avatar);
        setBlockUser(response.data.block_users);
        setFollows(response.data.follow_users);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
    handleOpenDetails();
    handleClose();
  };

  useEffect(() => {
    if (userData && userData.user_avatar) {
      setImageURL(userData.user_avatar);
    }
  }, [userData]);

  const columns = [
    {
      field: "user_avatar",
      headerName: "Ảnh đại diện",
      width: 80,
      renderCell: (params) => {
        return <Avatar alt="Avatar" src={params.value} />;
      },
      cellClassName: "avatar-cell",
    },
    { field: "display_name", headerName: "Họ và tên", width: 200 },
    { field: "user_email", headerName: "Email", width: 200 },
    { field: "user_login", headerName: "Tài khoản", width: 200 },
    { field: "user_role", headerName: "Quyền", width: 80 },
    {
      field: "actions",
      headerName: "Hành động",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleMenuClick(e, params.row)}
            >
              <HiOutlineDotsVertical style={{ color: "#333" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open && currentRow && currentRow._id === params.row._id}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleDetailsClick(params.row._id)}>
                Chi tiết
              </MenuItem>
              <MenuItem>Xóa</MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        padding: "20px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h5" fontWeight={550} mb={2}>
        Danh sách người dùng
      </Typography>

      <Box sx={{ width: "100%", backgroundColor: "#fff", padding: 2 }}>
        <TextField
          label="Tìm kiếm người dùng"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        {noUsersFound && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={noData} style={{ opacity: "0.5" }} />
            <Typography variant="body1" sx={{ fontSize: "20px", mt: 2 }}>
              Không có kết quả tìm kiếm!
            </Typography>
          </Box>
        )}
        {filteredUsers.length > 0 && (
          <DataGrid
            rows={filteredUsers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row._id}
          />
        )}
      </Box>

      <ListUser
        style={style}
        userData={userData}
        imageURL={imageURL}
        handleFileChangeAndUpload={handleFileChangeAndUpload}
        handleChange={handleChange}
        blockUser={blockUser}
        role={role}
        fetchUsers={fetchUsers}
        handleCloseDetails={handleCloseDetails}
        openDetails={openDetails}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Users;
