import React from "react";
import { Box, Typography, Divider, Stack } from "@mui/material";
import { HiTicket } from "react-icons/hi2";
import { CouponData } from "../interface";

interface PromoCardProps {
  coupon: CouponData;
}

const PromoCard: React.FC<PromoCardProps> = ({ coupon }) => {
  const paymentMethod =
    coupon.payment_method === "all" ? "Toàn bộ" : coupon.payment_method;
  const discount =
    coupon.promotion_type === "percentage"
      ? `${coupon.promotion}%`
      : `${coupon.promotion}₫`;

  const calculateBgColor = (): string => {
    if (!coupon.expired) {
      return "#4caf50";
    }

    const now = new Date();
    const expired = new Date(coupon.expired);
    const availableAt = coupon.availableAt
      ? new Date(coupon.availableAt)
      : null;

    if (!availableAt) {
      return "#4caf50";
    }

    const diffInTime = expired.getTime() - now.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

    if (diffInDays <= 0) {
      return "#333";
    } else if (diffInDays <= 1) {
      return "#f44336";
    } else if (diffInDays <= 3) {
      return "#e1ad29";
    }

    return "#4caf50";
  };

  const expiredDate = coupon.expired
    ? new Date(coupon.expired)
        .toLocaleDateString("vi-VN")
        .split("/")
        .map((item, index) =>
          item.length === 1 && index < 2 ? `0${item}` : item
        )
        .join("/")
    : "Không giới hạn";

  const availableAt = coupon.availableAt ? new Date(coupon.availableAt) : null;
  const now = new Date();

  const isPromoStarted =
    availableAt && availableAt.setHours(0, 0, 0, 0) <= now.setHours(0, 0, 0, 0);

  const bgColor = calculateBgColor();

  return (
    <Box
      position="relative"
      width="100%"
      height={200}
      overflow="hidden"
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        bgcolor: bgColor,
        transition: "all 0.3s ease",
        "&:hover": {
          background: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2)), ${bgColor}`,
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <Box pt={3} pl={3} pr={3} pb={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            variant="body2"
            color="#fff"
            fontSize={12}
            fontWeight={550}
            p={0.5}
            width={200}
            textAlign="center"
            borderRadius={3}
            bgcolor="#385fc2"
          >
            Thể loại thanh toán: <strong>{paymentMethod}</strong>
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <HiTicket fontSize={25} color="#fff" />
            <Typography variant="body2" color="#fff" mt={0.5}>
              {coupon.total}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          width="100%"
          direction="row"
          mt={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              variant="body1"
              fontWeight={700}
              color="#fff"
              fontSize={20}
            >
              Giảm giá: {discount}
            </Typography>
            <Typography variant="body2" color="#fff">
              {coupon.title}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            color="#fff"
            fontWeight={700}
            fontSize={20}
          >
            {coupon.code}
          </Typography>
        </Stack>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={2}
        sx={{ position: "relative" }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "-10px",
            width: 20,
            height: 20,
            bgcolor: "#fff",
            borderRadius: "50%",
            zIndex: 1,
          }}
        />
        <Divider
          sx={{
            flex: 1,
            borderColor: "#fff",
            borderBottomWidth: "2px",
            marginLeft: 3,
            marginRight: 3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            right: "-10px",
            width: 20,
            height: 20,
            bgcolor: "#fff",
            borderRadius: "50%",
            zIndex: 1,
          }}
        />
      </Box>

      <Box pl={3} pr={3} pt={1.5}>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" fontWeight={600} color="#fff">
            Ngày hết hạn: {expiredDate}
          </Typography>
          <Typography
            variant="body2"
            bgcolor="black"
            p={0.8}
            fontSize={11}
            color="#fff"
            borderRadius={3}
          >
            {coupon.visible}
          </Typography>
        </Stack>
      </Box>

      {availableAt && !isPromoStarted && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.6)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="5px"
          textAlign="center"
          zIndex={2}
        >
          <Stack>
            <Typography variant="h6" color="#fff">
              Sắp bắt đầu
            </Typography>
            <Typography variant="body1" color="#fff" mt={1}>
              {availableAt &&
                `${String(availableAt.getDate()).padStart(2, "0")}/${String(
                  availableAt.getMonth() + 1
                ).padStart(2, "0")}/${availableAt.getFullYear()}`}
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default PromoCard;
