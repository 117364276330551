import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Button,
  TextField,
  Modal,
  Pagination,
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CiTrash } from "react-icons/ci";
import {
  createAnswer,
  createQuestion,
  createQuestionCheck,
  createQuestionText,
  getDetailQuestion,
  getListQuestion,
  uploadMedia,
} from "entities/practice-exam/service";
import { getCookie } from "utils/setCookie";
import CreateQuestionModal from "./ModalCreate";
import { QuestionDetailModal } from "./ModalDetailQuestion";
import CustomSnackbar from "components/snackbar";
interface Question {
  _id: string;
  type?: string;
  answerCount: number;
  title?: string;
  description?: string;
}

interface ListQuestionProps {
  selectedDetailExam: { _id: string; title: string };
  groupedQuestions: Question[];
  handleBackToList: () => void;
  dataQuestions: any;
}

interface Answer {
  _id: string;
  answer: string;
  index: number;
  parent_id: string;
}

const ListQuestion: React.FC<ListQuestionProps> = ({
  selectedDetailExam,
  groupedQuestions,
  handleBackToList,
  dataQuestions,
}) => {
  const [openCreateQuestion, setOpenCreateQuestion] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [editorData, setEditorData] = useState<string>("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    duration_time: 0,
    start_time: "",
    end_time: "",
    type: "wrapper",
    content: "",
  });
  const [textFields, setTextFields] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [answerText, setAnswerText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );
  const [answersRadio, setAnswersRadio] = useState([
    { label: "A", value: "" },
    { label: "B", value: "" },
    { label: "C", value: "" },
    { label: "D", value: "" },
  ]);

  const [questions, setQuestions] = useState([
    {
      title: "",
      content: "",
      answers: [
        { label: "A", value: "" },
        { label: "B", value: "" },
        { label: "C", value: "" },
        { label: "D", value: "" },
      ],
    },
  ]);

  const [textBox, setTextBox] = useState([
    {
      title: "",
      content: "",
      answer: "",
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 5;

  const paginatedQuestions = groupedQuestions.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const addQuestion = () => {
    const newQuestion = {
      title: "",
      content: "",
      answers: [
        { label: "A", value: "" },
        { label: "B", value: "" },
        { label: "C", value: "" },
        { label: "D", value: "" },
      ],
    };
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  const handleDeleteQuestion = (questionIndex: number) => {
    const updatedQuestions = questions.filter(
      (_, index) => index !== questionIndex
    );

    const reIndexedQuestions = updatedQuestions.map((question, index) => ({
      ...question,
      title: `Câu hỏi ${index + 1}`,
    }));

    setQuestions(reIndexedQuestions);
  };

  const handleAddTextBox = () => {
    setTextBox([...textBox, { title: "", content: "", answer: "" }]);
  };

  const handleDeleteTextBox = (index: number) => {
    const updatedTextBox = [...textBox];
    updatedTextBox.splice(index, 1);
    setTextBox(updatedTextBox);
  };

  const handleAnswerChange = (
    questionIndex: number,
    answerIndex: number,
    newValue: string
  ) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers[answerIndex].value = newValue;
    setQuestions(updatedQuestions);
  };

  const handleDeleteAnswer = (questionIndex: number, answerIndex: number) => {
    const updatedQuestions = [...questions];
    if (updatedQuestions[questionIndex].answers.length > 1) {
      updatedQuestions[questionIndex].answers.splice(answerIndex, 1);
      updatedQuestions[questionIndex].answers = updatedQuestions[
        questionIndex
      ].answers.map((answer, i) => ({
        ...answer,
        label: String.fromCharCode(65 + i),
      }));
      setQuestions(updatedQuestions);
    }
  };

  const addAnswer = (questionIndex: number) => {
    const updatedQuestions = [...questions];
    const newLabel = String.fromCharCode(
      65 + updatedQuestions[questionIndex].answers.length
    );
    if (newLabel <= "Z") {
      updatedQuestions[questionIndex].answers.push({
        label: newLabel,
        value: "",
      });
      setQuestions(updatedQuestions);
    }
  };

  const handleSelectCorrectAnswer = (
    questionIndex: number,
    answerLabel: string
  ) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers = updatedQuestions[
      questionIndex
    ].answers.map((answer) => ({
      ...answer,
      checked: answer.label === answerLabel,
    }));
    setQuestions(updatedQuestions);
  };

  const handleSelectCorrectAnswerCheckBox = (
    questionIndex: number,
    answerIndex: number,
    isChecked: boolean
  ) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers[answerIndex].checked = isChecked;
    setQuestions(updatedQuestions);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleBoxClick = async (id: string) => {
    setOpenModal(true);
    setIsLoading(true);

    try {
      const response = await getDetailQuestion(id);
      setSelectedQuestion(response.data);

      const questionList = await getListQuestion(response.data.test_id);

      const answers = questionList.filter((item) => item.parent_id === id);

      setAnswers(answers);
    } catch (error) {
      console.error("Failed to fetch question details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedQuestion(null);
  };

  const handleOpenCreateQuestion = (): void => {
    setOpenCreateQuestion(true);
  };

  const handleCloseCreateQuestion = (): void => {
    setOpenCreateQuestion(false);
    setTitle("");
  };

  const handleAddTextField = () => {
    setTextFields([...textFields, ""]);
  };

  const handleTextFieldChange = (index: number, value: string) => {
    const updatedFields = [...textFields];
    updatedFields[index] = value;
    setTextFields(updatedFields);
  };

  const handleDeleteTextField = (index: number) => {
    const updatedFields = textFields.filter((_, i) => i !== index);
    setTextFields(updatedFields);
  };

  const handleFieldChange = (
    questionIndex: number,
    field: string,
    value: any
  ) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, index) =>
        index === questionIndex ? { ...question, [field]: value } : question
      )
    );
  };

  const handleEditorChange = (content: string) => {
    setEditorData(content);
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    setIsLoading(true);

    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };

      const uploadResponse = await uploadMedia(file, headers);

      if (uploadResponse && uploadResponse.id) {
        const fileName = uploadResponse.callback?.media_file_name || file.name;

        setUploadedFile({ name: fileName, id: uploadResponse.id });
        setFormData((prev) => ({ ...prev, media_id: uploadResponse.id }));
        setSnackbarMessage("File uploaded successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("File upload failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading the file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    setFormData((prev) => ({ ...prev, media_id: "" }));
  };

  const handleSaveQuestionText = async () => {
    try {
      const questionData = {
        test_id: selectedDetailExam._id,
        title: formData.title,
        content: formData.content,
        type: "text",
        part: "listening",
      };

      const questionResponse = await createQuestion(questionData);

      const questionId = questionResponse.data[0]._id;

      const answerPromises = textFields.map((answer, index) =>
        createAnswer(
          selectedDetailExam._id,
          questionId,
          index + 1,
          `Câu trả lời số ${index + 1}`,
          answer,
          "text",
          "listening"
        )
      );

      await Promise.all(answerPromises);
      handleCloseCreateQuestion();
      await dataQuestions(selectedDetailExam._id);

      setSnackbarMessage("Câu hỏi và câu trả lời đã được tạo thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Lỗi khi tạo câu hỏi hoặc câu trả lời");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveQuestionCheck = async () => {
    try {
      const questionData = {
        test_id: selectedDetailExam._id,
        title: formData.title,
        content: formData.content,
        type: "checkbox",
        part: "listening",
      };

      const questionResponse = await createQuestion(questionData);

      const questionId = questionResponse.data[0]._id;

      const answerPromises = textFields.map((answer, index) => {
        const letter = String.fromCharCode(65 + index);
        return createAnswer(
          selectedDetailExam._id,
          questionId,
          index + 1,
          `Câu ${letter}`,
          answer,
          "checkbox",
          "listening"
        );
      });

      await Promise.all(answerPromises);
      handleCloseCreateQuestion();
      await dataQuestions(selectedDetailExam._id);

      setSnackbarMessage("Câu hỏi và câu trả lời đã được tạo thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Lỗi khi tạo câu hỏi hoặc câu trả lời");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const fetchMaxIndex = async (test_id: string): Promise<number> => {
    try {
      const questions = await getListQuestion(test_id);
      const indices = questions.map((q) => q.index || 0);
      return Math.max(0, ...indices);
    } catch (error) {
      console.error("Failed to fetch max index:", error);
      return 0;
    }
  };

  const handleSaveQuestion = async () => {
    try {
      const maxIndex = await fetchMaxIndex(selectedDetailExam._id);

      const dataToSave = questions.map((question, questionIndex) => {
        return {
          test_id: selectedDetailExam._id,
          index: maxIndex + questionIndex + 1,
          title: question.title,
          content: question.content,
          type: "radio",
          part: "listening",
          options: question.answers.map((answer) => answer.value).flat(),
          answer:
            question.answers
              .map((answer) => (answer.checked ? answer.value : ""))
              .find(Boolean) || "",
        };
      });

      const questionResponse = await Promise.all(
        dataToSave.map((data) => createQuestionCheck(data))
      );

      await dataQuestions(selectedDetailExam._id);

      setSnackbarMessage("Câu hỏi và câu trả lời đã được tạo thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Lỗi khi tạo câu hỏi hoặc câu trả lời");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveQuestionCheckBox = async () => {
    try {
      const maxIndex = await fetchMaxIndex(selectedDetailExam._id);

      const dataToSave = questions.map((question, questionIndex) => {
        return {
          test_id: selectedDetailExam._id,
          index: maxIndex + questionIndex + 1,
          title: question.title,
          content: question.content,
          type: "checkbox",
          part: "listening",
          options: question.answers.map((answer) => answer.value).flat(),
          answer: question.answers
            .filter((answer) => answer.checked)
            .map((answer) => answer.value)
            .join(", "),
        };
      });

      const questionResponse = await Promise.all(
        dataToSave.map((data) => createQuestionCheck(data))
      );

      await dataQuestions(selectedDetailExam._id);

      setSnackbarMessage("Câu hỏi và câu trả lời đã được tạo thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Lỗi khi tạo câu hỏi hoặc câu trả lời");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveQuestionsText = async () => {
    try {
      const maxIndex = await fetchMaxIndex(selectedDetailExam._id);

      const savePromises = textBox.map((question, idx) => {
        const questionData = {
          test_id: selectedDetailExam._id,
          index: maxIndex + idx + 1,
          title: question.title,
          content: question.content,
          answer: question.answer,
          type: "text",
          part: "listening",
        };
        return createQuestionText(questionData);
      });

      await Promise.all(savePromises);
      await dataQuestions(selectedDetailExam._id);

      setSnackbarMessage("Tạo câu hỏi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving questions:", error);
      setSnackbarMessage("Lỗi khi tạo câu hỏi!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ mb: 3, mt: 2 }}
        >
          <IconButton onClick={handleBackToList}>
            <FaArrowLeftLong fontSize={15} />
          </IconButton>
          <Typography variant="body1" fontWeight={550} fontSize={20}>
            {selectedDetailExam.title}
          </Typography>
        </Stack>
        <Button
          className="save_exam"
          variant="contained"
          onClick={handleOpenCreateQuestion}
        >
          Tạo câu hỏi
        </Button>
      </Stack>

      {paginatedQuestions.map((question, index) => (
        <Box
          key={question._id}
          width="100%"
          border="1px solid #e0e0e0"
          p={3}
          mb={2}
          borderRadius={2}
          onClick={() => handleBoxClick(question._id)}
          sx={{
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "rgba(211, 211, 211, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              Thể loại: {question.type || "Không xác định"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Số câu trả lời: {question.answerCount}
            </Typography>
          </Stack>

          <Stack direction="row" gap={1}>
            <Box width={30} height={30}>
              <Typography
                variant="body1"
                borderRadius="50%"
                bgcolor="#3f87e5"
                width={30}
                height={30}
                textAlign="center"
                lineHeight="30px"
                color="white"
              >
                {index + 1}
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={550}>
              {question.title || "Không có tiêu đề"}
            </Typography>
          </Stack>
        </Box>
      ))}
      <Stack alignItems="end" mt={2}>
        <Pagination
          count={Math.ceil(groupedQuestions.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Stack>

      <QuestionDetailModal
        open={openModal}
        answers={answers}
        onClose={handleCloseModal}
        question={selectedQuestion}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        formData={formData}
        textFields={textFields}
        uploadedFile={uploadedFile}
        onSaveText={handleSaveQuestionText}
        onFieldChange={handleFieldChange}
        onFileUpload={handleFileUpload}
        onRemoveFile={handleRemoveFile}
        onAddAnswer={handleAddTextField}
        onAnswerChange={handleTextFieldChange}
        onDeleteAnswer={handleDeleteTextField}
        dataQuestions={dataQuestions}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        textBox={textBox}
        setTextBox={setTextBox}
        handleAddTextBox={handleAddTextBox}
        handleDeleteTextBox={handleDeleteTextBox}
        handleSelectCorrectAnswer={handleSelectCorrectAnswer}
      />

      <CreateQuestionModal
        open={openCreateQuestion}
        formData={formData}
        textFields={textFields}
        uploadedFile={uploadedFile}
        onClose={handleCloseCreateQuestion}
        onSaveText={handleSaveQuestionText}
        onSaveCheck={handleSaveQuestionCheck}
        onFieldChange={handleFieldChange}
        onFileUpload={handleFileUpload}
        onRemoveFile={handleRemoveFile}
        onAddAnswer={handleAddTextField}
        answer={answerText}
        setAnswer={(value) => setAnswerText(value)}
        onDeleteAnswer={handleDeleteTextField}
        isLoading={isLoading}
        answersRadio={answersRadio}
        questions={questions}
        addQuestion={addQuestion}
        handleAnswerChange={handleAnswerChange}
        handleDeleteAnswer={handleDeleteAnswer}
        addAnswer={addAnswer}
        setAnswersRadio={setAnswersRadio}
        handleDeleteQuestion={handleDeleteQuestion}
        handleSaveQuestion={handleSaveQuestion}
        handleSaveQuestionsText={handleSaveQuestionsText}
        textBox={textBox}
        setTextBox={setTextBox}
        handleAddTextBox={handleAddTextBox}
        handleDeleteTextBox={handleDeleteTextBox}
        handleSelectCorrectAnswer={handleSelectCorrectAnswer}
        handleSelectCorrectAnswerCheckBox={handleSelectCorrectAnswerCheckBox}
        handleSaveQuestionCheckBox={handleSaveQuestionCheckBox}
      />

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ListQuestion;
