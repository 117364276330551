import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { EmptyState } from "@shopify/polaris";
import {
  deleteStudentSelfLearning,
  getClassDetail,
  getListStudentSelfLearning,
  restoreStudentSelfLearning,
} from "../services";
import EvaluationForm from "../components/evaluation.form";
import emptyIMG from "../../../media/empty.png";
import EvaluationList from "./evaluation.list";
import WarningDeleteUser from "../components/warningDeleteUser";
const style = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "80%",
  borderRadius: 2,
  bgcolor: "white",
  boxShadow: "2px 4px 10px #ededeb",
  marginLeft: "10%",
  p: 2,
};
const ListStudents = () => {
  const params = useParams();
  const { id } = params;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [currentStudent, setcurrentStudent] = React.useState<number>();
  const [classIndex, setclassIndex] = React.useState<string>("");
  const [active, setActive] = React.useState(false);
  const [messageSnackBar, setMessageSnackBar] = React.useState("");

  const [dataStudentSelf, setDataStudentSelf] = useState([]);

  const toggleActive = React.useCallback(
    () => setActive((active) => !active),
    []
  );
  const handleOpenModal = (index, classIndex) => {
    setclassIndex(classIndex);
    setcurrentStudent(index);
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  React.useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    getClassDetail(id).then((res) => {
      setLoading(false);
      if (!res.isError) {
        setData(res.data);
      } else {
        throw new Error(res.message);
      }
    });
    getListStudentSelfLearning(id).then((res) => {
      if (res?.data) {
        setDataStudentSelf(res.data);
      }
    });
  };

  const renderStudentList = (item, index: number, classIndex) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderWidth: "0px 0px 1px 0px",
          borderStyle: "solid",
          borderColor: "#e0dede",
          p: 1,
        }}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <img
            src={item.user_avatar || item.user_avatar_thumbnail}
            style={{ height: 50, width: 50, borderRadius: 100 }}
          />
          <Typography variant="body1">{item.display_name}</Typography>
        </Stack>
        <Button onClick={() => handleOpenModal(index, classIndex)}>
          {/* <IconButton
            aria-label="more"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleMenuClick(e, item._id)}
          >
            <HiOutlineDotsVertical style={{ color: "#333" }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && currentRow && currentRow === item._id}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
             onClick={() => editCategory(params.row)

             }
            >
              Đánh giá
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (window.confirm("Bạn muốn xoá danh mục này không")) {
                  //   handleDeleteCategory(params.row._id)
                }
              }}
            >
              Xóa
            </MenuItem>
          </Menu> */}
          <Typography sx={{ color: "green" }}>Đánh giá</Typography>
        </Button>
      </Box>
    );
  };
  const renderItem = (item, classIndex) => {
    return (
      <Stack gap={1}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">{item.name}</Typography>
          <Stack>
            <EvaluationList classId={item._id} />
          </Stack>
        </Stack>
        {item.members.length > 0 ? (
          <Stack gap={1} bgcolor={"white"} padding={1}>
            {item.members.map((member, index) => (
              <Box key={index}>
                {renderStudentList(member, index, classIndex)}
              </Box>
            ))}
          </Stack>
        ) : (
          <Typography>There are no students in this class</Typography>
        )}
      </Stack>
    );
  };
  //   const handleClose = (
  //     event: React.SyntheticEvent | Event,
  //     reason?: SnackbarCloseReason
  //   ) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }

  //     setOpen(false);
  //   };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!loading && dataStudentSelf.length == 0 && data.length == 0) {
    return <EmptyState heading="Classes are empty!" image={emptyIMG} />;
  }

  return (
    <Box
      // sx={{
      //   display: "flex",
      //   flexDirection: "column",
      //   px: 2,
      //   gap: 2,
      //   // width: "100%",
      //   // maxWidth: "80%",
      //   borderRadius: 2,
      //   bgcolor: "white",
      //   boxShadow: "2px 4px 10px #ededeb",
      //   // marginLeft: "10%",
      //   marginTop: 2,
      //   p: 2,
      // }}
      sx={style}
    >
      <Typography variant="h5">{"Danh sách học sinh"}</Typography>
      {data.map((item, index) => (
        <Stack gap={2} key={index}>
          {renderItem(item, index)}
        </Stack>
      ))}
      {data.length == 0 &&
        dataStudentSelf.map((item) => (
          <ItemStudentSelfLearning id={id} item={item} key={item._id} />
        ))}

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <EvaluationForm
            //   messageSnackBar={messageSnackBar}
            setMessageSnackBar={setMessageSnackBar}
            handleCloseModal={handleCloseModal}
            classId={data[classIndex]?._id}
            studentId={data[classIndex]?.members[currentStudent]?._id}
            toggleActive={toggleActive}
          />
        </Box>
      </Modal>
      <Snackbar
        open={active}
        autoHideDuration={6000}
        onClose={toggleActive}
        message={messageSnackBar}
        color="#58fc66"
        // action={action}
      />
    </Box>
  );
};

const ItemStudentSelfLearning = ({ item, id }) => {
  const [isDelete, setIsDelete] = useState(false);
  const [studentId, setStudentId] = useState("");

  const deleteUserCourse = (idStudent: string) => {
    const data = {
      course_id: id,
      user_id: idStudent,
    };
    setStudentId("");
    deleteStudentSelfLearning(data).then((res) => {
      console.log("res...", res);
      setIsDelete(true);
    });
  };

  const restoreUserCourse = (idStudent: string) => {
    const data = {
      course_id: id,
      user_id: idStudent,
    };
    restoreStudentSelfLearning(data).then((res) => {
      console.log("res...", res);
      setIsDelete(false);
    });
  };
  return (
    <>
      <Box
        key={item._id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderWidth: "0px 0px 1px 0px",
          borderStyle: "solid",
          borderColor: "#e0dede",
          p: 1,
        }}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <img
            src={item.user_avatar || item.user_avatar_thumbnail}
            style={{ height: 50, width: 50, borderRadius: 100 }}
          />
          <Typography variant="body1">{item.display_name}</Typography>
        </Stack>
        {isDelete ? (
          <Button onClick={() => restoreUserCourse(item._id)}>
            <Typography sx={{ color: "green" }}>Khôi phục</Typography>
          </Button>
        ) : (
          <Button onClick={() => setStudentId(item._id)}>
            <Typography sx={{ color: "red" }}>Xoá</Typography>
          </Button>
        )}
      </Box>
      <WarningDeleteUser
        deleteUser={() => deleteUserCourse(studentId)}
        openDeleteConfirm={studentId !== ""}
        handleClose={() => setStudentId("")}
        title={"Bạn có chắc muốn xoá học sinh này khỏi lớp?"}
        message={"Bạn không thể hoàn tác lại sau khi đã xoá học sinh khỏi lớp."}
      />
    </>
  );
};

export default React.memo(ListStudents);
