export const formatDescription = (description: string) => {
  const words = description.split(" ");
  if (words.length > 5) {
    return words.slice(0, 5).join(" ") + "...";
  }
  return description;
};

export const formatDuration = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;
  const remainingHours = hours % 24;

  let duration = "";
  if (days > 0) duration += `${days} ngày `;
  if (remainingHours > 0) duration += `${remainingHours} giờ `;
  if (remainingMinutes > 0) duration += `${remainingMinutes} phút `;
  if (remainingSeconds > 0) duration += `${remainingSeconds} giây`;

  return duration.trim() || "0 giây";
};

export const formatCreatedAt = (createdAt: string) => {
  const date = new Date(createdAt);
  return date.toLocaleDateString("vi-VN");
};
