import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomSnackbar from "components/snackbar";
import { uploadMedia } from "entities/course/service";
import {
  createQuestion,
  getDetailQuestion,
  getListQuestion,
} from "entities/practice-exam/service";
import React, { useState } from "react";
import { CiTrash } from "react-icons/ci";
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactQuill from "react-quill";
import { getCookie } from "utils/setCookie";
import CreateQuestionModal from "./ModalCreate";
import { QuestionDetailModal } from "./ModalDetail";
interface Question {
  _id: string;
  type?: string;
  answerCount: number;
  title?: string;
  description?: string;
}

interface ListQuestionProps {
  selectedDetailExam: { _id: string; title: string };
  groupedQuestions: Question[];
  handleBackToList: () => void;
  dataQuestions: any;
}

interface Answer {
  _id: string;
  answer: string;
  index: number;
  parent_id: string;
}

const ListWriting: React.FC<ListQuestionProps> = ({
  selectedDetailExam,
  groupedQuestions,
  handleBackToList,
  dataQuestions,
}) => {
  const [openCreateQuestion, setOpenCreateQuestion] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    duration_time: 0,
    start_time: "",
    end_time: "",
    type: "wrapper",
    content: "",
    media_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [openModal, setOpenModal] = useState(false);
  const [answers, setAnswers] = useState<Answer[]>([]);

  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    id: string;
  } | null>(null);

  const handleOpenCreateQuestion = (): void => {
    setOpenCreateQuestion(true);
  };

  const handleCloseCreateQuestion = (): void => {
    setOpenCreateQuestion(false);
    setTitle("");
  };

  const handleFieldChange = (key: keyof typeof formData, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedQuestion(null);
  };

  const paginatedQuestions = groupedQuestions.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handleBoxClick = async (id: string) => {
    setOpenModal(true);
    setIsLoading(true);

    try {
      const response = await getDetailQuestion(id);
      setSelectedQuestion(response.data);

      const questionList = await getListQuestion(response.data.test_id);

      // const answers = questionList.filter((item) => item.parent_id === id);

      // setAnswers(answers);
    } catch (error) {
      console.error("Failed to fetch question details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    setIsLoading(true);

    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };

      const uploadResponse = await uploadMedia(file, headers);

      if (uploadResponse && uploadResponse.id) {
        const fileName = uploadResponse.callback?.media_file_name || file.name;

        setUploadedFile({ name: fileName, id: uploadResponse.id });
        setFormData((prev) => ({ ...prev, media_id: uploadResponse.id }));
        setSnackbarMessage("File uploaded successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("File upload failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading the file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    setFormData((prev) => ({ ...prev, media_id: "" }));
  };

  const handleSaveQuestion = async () => {
    try {
      const questionData = {
        test_id: selectedDetailExam._id,
        title: formData.title,
        content: formData.content,
        type: "wrapper",
        part: "writing",
      };

      const questionResponse = await createQuestion(questionData);

      const questionId = questionResponse.data[0]._id;

      // const answerPromises = textFields.map((answer, index) =>
      //   createAnswer(
      //     selectedDetailExam._id,
      //     formData.media_id,
      //     questionId,
      //     index + 1,
      //     `Câu trả lời số ${index + 1}`,
      //     answer,
      //     "text",
      //     "listening"
      //   )
      // );

      // await Promise.all(answerPromises);
      handleCloseCreateQuestion();
      await dataQuestions(selectedDetailExam._id);
      setSnackbarMessage("Câu hỏi và câu trả lời đã được tạo thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Lỗi khi tạo câu hỏi hoặc câu trả lời");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ mb: 3, mt: 2 }}
        >
          <IconButton onClick={handleBackToList}>
            <FaArrowLeftLong fontSize={15} />
          </IconButton>
          <Typography variant="body1" fontWeight={550} fontSize={20}>
            {selectedDetailExam.title}
          </Typography>
        </Stack>
        <Button
          className="save_exam"
          variant="contained"
          onClick={handleOpenCreateQuestion}
        >
          Tạo câu hỏi
        </Button>
      </Stack>

      {paginatedQuestions.map((question, index) => (
        <Box
          key={question._id}
          width="100%"
          border="1px solid #e0e0e0"
          p={3}
          mb={2}
          borderRadius={2}
          onClick={() => handleBoxClick(question._id)}
          sx={{
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "rgba(211, 211, 211, 0.3)",
              backdropFilter: "blur(5px)",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              Thể loại: {question.type || "Không xác định"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Số câu trả lời: {question.answerCount}
            </Typography>
          </Stack>

          <Stack direction="row" gap={1}>
            <Box width={30} height={30}>
              <Typography
                variant="body1"
                borderRadius="50%"
                bgcolor="#3f87e5"
                width={30}
                height={30}
                textAlign="center"
                lineHeight="30px"
                color="white"
              >
                {index + 1}
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={550}>
              {question.title || "Không có tiêu đề"}
            </Typography>
          </Stack>
        </Box>
      ))}
      <Stack alignItems="end" mt={2}>
        <Pagination
          count={Math.ceil(groupedQuestions.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Stack>

      <QuestionDetailModal
        open={openModal}
        answers={answers}
        onClose={handleCloseModal}
        question={selectedQuestion}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        formData={formData}
        uploadedFile={uploadedFile}
        onSave={handleSaveQuestion}
        onFieldChange={handleFieldChange}
        onFileUpload={handleFileUpload}
        onRemoveFile={handleRemoveFile}
        dataQuestions={dataQuestions}
      />

      <CreateQuestionModal
        open={openCreateQuestion}
        formData={formData}
        uploadedFile={uploadedFile}
        isLoading={isLoading}
        onClose={handleCloseCreateQuestion}
        onSave={handleSaveQuestion}
        onFieldChange={handleFieldChange}
        onFileUpload={handleFileUpload}
        onRemoveFile={handleRemoveFile}
      />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ListWriting;
