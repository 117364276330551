import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fToNow } from "../utils/formant-time";
import { updateStatusPost } from "../service";
import PostImages from "./post.image";
import { useLightBox } from "components/lightbox";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";

const ItemCommunity = ({
  post,
  setSnackbarOpen,
  setSnackbarSeverity,
  setSnackbarMessage,
}) => {
  const [postStatus, setPostStatus] = useState(post.post_status);

  const updateStatusCommunity = (status: string) => {
    updateStatusPost({ _id: post._id, post_status: status })
      .then((res) => {
        setPostStatus(status);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        if (status === "publish") {
          setSnackbarMessage("Duyệt bài thành công");
        } else {
          setSnackbarMessage("Đã từ chối");
        }
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

  const postsWithImages = post.attach_files;

  const slides = postsWithImages
    .flatMap((post) =>
      post.attach_files
        ? post.attach_files.map((file) => ({
            src: file.media_url,
          }))
        : []
    )
    .filter((slide) => slide.src !== undefined) as { src: string }[];

  const lightbox = useLightBox(slides);

  const formatText = (text: string): JSX.Element[] => {
    return text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  const handleApprove = () => {
    if (window.confirm("Bạn có chắc muốn duyệt bài này không?")) {
      updateStatusCommunity("publish");
    }
  };
  const handleReject = () => {
    if (window.confirm("Bạn có chắc muốn từ chối bài này không?")) {
      updateStatusCommunity("rejected");
    }
  };

  const ButtonApprove = () => {
    return (
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={handleApprove}
        // startIcon={<CheckCircleOutlineIcon />}
        sx={{
          fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
          minWidth: { xs: "60px", sm: "auto" },
        }}
      >
        Phê duyệt
      </Button>
    );
  };
  const ButtonReject = () => {
    return (
      <Button
        variant="contained"
        color="error"
        size="small"
        onClick={handleReject}
        // startIcon={<CancelOutlinedIcon />}
        sx={{
          fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
          minWidth: { xs: "60px", sm: "auto" },
        }}
      >
        Từ chối
      </Button>
    );
  };

  return (
    <Card
      sx={{
        mb: 2,
        width: {
          xs: "95%", // Full width - margin on mobile
          sm: "90%", // Slightly smaller on tablet
          md: "700px", // Original width on desktop
        },
        mx: "auto", // Center the card
      }}
    >
      <CardHeader
        disableTypography
        avatar={
          <Avatar
            src={post?.user_id?.user_avatar}
            alt={post?.user_id?.display_name}
          >
            {post?.user_id?.display_name?.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={
          <Typography fontWeight={"bold"} variant="subtitle1" fontSize={14}>
            {post?.user_id?.display_name}
          </Typography>
        }
        subheader={
          <Stack gap={1}>
            <Box
              sx={{ color: "text.disabled", typography: "caption", mt: 0.5 }}
            >
              {fToNow(post?.createdAt)}
            </Box>
            {postStatus === "publish" && (
              <Chip
                label="Đã phê duyệt"
                color="success"
                size="small"
                variant="outlined"
                icon={<FaCheckCircle size={16} />}
                sx={{
                  fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
                  maxWidth: 120,
                }}
              />
            )}
            {postStatus === "rejected" && (
              <Chip
                label="Đã từ chối"
                color="error"
                size="small"
                variant="outlined"
                icon={<IoIosCloseCircle size={20} />}
                sx={{
                  fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
                  maxWidth: 100,
                }}
              />
            )}
          </Stack>
        }
        action={
          <Box sx={{ display: "flex", gap: 1 }}>
            {postStatus === "pending" && (
              <>
                <ButtonApprove />
                <ButtonReject />
              </>
            )}
            {postStatus === "publish" && (
              <>
                {/* <Chip
                  label="Đã phê duyệt"
                  color="success"
                  size="small"
                  // icon={<CheckCircleOutlineIcon />}
                  sx={{
                    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
                  }}
                /> */}
                <ButtonReject />
              </>
            )}
            {postStatus === "rejected" && (
              <>
                {/* <Chip
                  label="Đã từ chối"
                  color="error"
                  size="small"
                  // icon={<CancelOutlinedIcon />}
                  sx={{
                    fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
                  }}
                /> */}
                <ButtonApprove />
              </>
            )}
          </Box>
        }
      />
      <Typography
        variant="body2"
        sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}
      >
        {formatText(post?.post_content || "")}
      </Typography>
      <Box sx={{ p: 1 }}>
        <PostImages
          attach_files={post.attach_files}
          lightbox={lightbox}
          post={post}
        />
      </Box>
    </Card>
  );
};

export default ItemCommunity;
