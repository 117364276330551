import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { FaRegEye } from "react-icons/fa6";
import "../assets/css/Practice.css";
import React, { useEffect, useState } from "react";
import TabListening from "../components/TabListening";
import TabReading from "../components/TabReading";
import TabWriting from "../components/TabWriting";
import { Exam } from "../interface";
import { getListExam } from "../service";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Practice = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [examList, setExamList] = useState<Exam[]>([]);

  const fetchExams = async (type?: string) => {
    try {
      const data = await getListExam(type);
      setExamList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let type = "";
    if (value === 0) type = "listening";
    else if (value === 1) type = "reading";
    else if (value === 2) type = "writing";
    else if (value === 3) type = "speaking";

    fetchExams(type);
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const listeningExams = examList.filter((exam) => exam.type === "listening");
  const readingExams = examList.filter((exam) => exam.type === "reading");
  const writingExams = examList.filter((exam) => exam.type === "writing");

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box width="100%" maxWidth={1100} margin="auto" p={"30px 20px"}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550}>
          Đề thi thử
        </Typography>
      </Stack>

      <Box mt={2}>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100vh",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Vertical Tabs"
            sx={{
              width: "100%",
              borderRight: "1px solid #e0e0e0",
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 600,
                alignItems: "flex-start",
                padding: "8px 16px",
              },
            }}
          >
            <Tab label="Listening" {...a11yProps(0)} />
            <Tab label="Reading" {...a11yProps(1)} />
            <Tab label="Writing" {...a11yProps(2)} />
          </Tabs>
          <Box sx={{ flexGrow: 1 }}>
            <TabPanel value={value} index={0}>
              <TabListening exams={listeningExams} fetchExams={fetchExams} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TabReading exams={readingExams} fetchExams={fetchExams} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabWriting exams={writingExams} fetchExams={fetchExams} />
            </TabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Practice;
