import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { getListCourse } from "../services";
import { useInfiniteScroll } from "entities/community/components/useInfiniteScroll";
import { Link } from "@shopify/polaris";

const EvaluationStudent = () => {
  const {
    items: listCourse,
    loading,
    hasMore,
  } = useInfiniteScroll(
    getListCourse,
    {
      order_by: "DESC",
    },
    { initialLimit: 12 }
  );

  const renderItem = (item) => {
    return (
      <Button>
        <Box>
          <img
            src={item.avatar?.media_url}
            style={{
              height: 200,
              width: "100%",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          />
          <Typography variant="subtitle1">{item?.title}</Typography>
        </Box>
      </Button>
    );
  };
  return (
    <Box>
      {/* <Typography>EvaluationStudent</Typography> */}
      <Box
        sx={{
          display: "flex",
          // flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
          px: {
            xs: 1,
            sm: 2,
            md: 3,
            gap: 10,
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      >
        {listCourse.map((item: any, index) => (
          <Box
            sx={{
              borderRadius: 3,
              bgcolor: "white",
              width: "100%",
              maxWidth: "20%",
              // p: 2,
              boxShadow: "2px 4px rgb(224, 224, 224)",
            }}
            key={index}
          >
            <Link url={item._id}>{renderItem(item)}</Link>
          </Box>
        ))}
      </Box>
      {!loading && hasMore && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default EvaluationStudent;
