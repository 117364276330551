import axios from "axios";
import { getCookie } from "utils/setCookie";
import { Redeem, RedeemMission } from "../interface";

export const createRedeem = async (
  title: string,
  start_time: string,
  end_time: string,
  point: number,
  coin: number
): Promise<any> => {
  const requestData: Redeem = { title, start_time, end_time, point, coin };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "redeem/create";
  return axios.post(url, requestData, axiosConfig);
};

export const createRedeemMission = async (
  redeem_id: string,
  title: string,
  action_type: string,
  action_target: string,
  action_amount: number,
  point: number,
  coin: number,
  navigate: string
): Promise<any> => {
  const requestData: RedeemMission = {
    redeem_id,
    title,
    action_type,
    action_target,
    action_amount,
    point,
    coin,
    navigate,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "redeem/mission/create";
  return axios.post(url, requestData, axiosConfig);
};

export const getListUser = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `user/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getListRedeemUser = async (): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "redeem/user";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const uploadRedeem = async (
  _id: string,
  title: string,
  start_time: string,
  end_time: string,
  point: number,
  coin: number
): Promise<any> => {
  const requestData = { _id, title, start_time, end_time, point, coin };
  const url = process.env.REACT_APP_AJAX_URL + "redeem/update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const uploadRedeemMission = async (
  _id: string,
  title: string,
  redeem_id: string,
  point: number,
  coin: number,
  action_type: string,
  action_target: string,
  action_amount: number,
  navigate: string
): Promise<any> => {
  const requestData = {
    _id,
    title,
    redeem_id,
    point,
    coin,
    action_type,
    action_target,
    action_amount,
    navigate,
  };
  const url = process.env.REACT_APP_AJAX_URL + "redeem/mission/update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.patch(url, requestData, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteRedeem = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `redeem/delete/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteRedeemMission = async (id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `redeem/delete/mission/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
