// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview_exam {
  border: 1px solid #b9bdc1;
  color: #333;
  transition: 0.2s ease;
  text-transform: none;
  border-radius: 8px;
}

.preview_exam:hover {
  border: 1px solid #b9bdc1;
}

.save_exam {
  background-color: #3f87e5 !important;
  color: #fff !important;
  border: 1px solid #3f87e5 !important;
  text-transform: none !important;
  border-radius: 8px !important;
  transition: 0.2s ease !important;
  font-size: 15px !important;
}

.save_exam:hover {
  background-color: #3f87e5 !important;
  color: #fff !important;
  border: 1px solid #3f87e5 !important;
}
`, "",{"version":3,"sources":["webpack://./src/entities/config-page/assets/css/Practice.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;EACrB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,oCAAoC;EACpC,+BAA+B;EAC/B,6BAA6B;EAC7B,gCAAgC;EAChC,0BAA0B;AAC5B;;AAEA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".preview_exam {\n  border: 1px solid #b9bdc1;\n  color: #333;\n  transition: 0.2s ease;\n  text-transform: none;\n  border-radius: 8px;\n}\n\n.preview_exam:hover {\n  border: 1px solid #b9bdc1;\n}\n\n.save_exam {\n  background-color: #3f87e5 !important;\n  color: #fff !important;\n  border: 1px solid #3f87e5 !important;\n  text-transform: none !important;\n  border-radius: 8px !important;\n  transition: 0.2s ease !important;\n  font-size: 15px !important;\n}\n\n.save_exam:hover {\n  background-color: #3f87e5 !important;\n  color: #fff !important;\n  border: 1px solid #3f87e5 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
