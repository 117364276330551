import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListOrderUser = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "order/admin-list?order_by=DESC";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params: data,
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updateOrderUser = async (data?: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "order/admin-update";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.post(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
