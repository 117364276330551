import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { getTime, getTimeAvailable, updateCourseTime } from "../service";
import emptyIMG from "../../media/empty.png";
import { EmptyState } from "@shopify/polaris";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const getCurrentDayIndex = () => {
  const currentDate = new Date();
  return currentDate.getDay();
};

function generateTimes(start, end, interval) {
  let times = [];
  let currentHour = start;
  let currentMinute = 0;

  for (let i = 0; i < end; i++) {
    let hourLabel = currentHour.toString().padStart(2, "0");
    let minuteLabel = currentMinute.toString().padStart(2, "0");
    let label = `${hourLabel}:${minuteLabel}`;
    times.push(label);

    // Increment minutes
    currentMinute += interval;
    if (currentMinute >= 60) {
      currentHour = (currentHour + 1) % 24;
      currentMinute = currentMinute % 60;
    }
  }

  return times;
}

// Usage examples with intervals of 30 minutes
const earlyMorningTimes = generateTimes(0, 12, 30); // from 00:00 to 05:30
const morningTimes = generateTimes(6, 12, 30); // from 06:00 to 11:30
const afternoonTimes = generateTimes(12, 12, 30); // from 12:00 to 17:30
const eveningTimes = generateTimes(18, 12, 30);

const Time = ({
  dataMyCourse,
  setSnackBarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) => {
  const [dataTime, setDataTime] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDayTimes, setSelectedDayTimes] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  // useEffect(() => {
  //     if (dataMyCourse && dataMyCourse.length > 0) {
  //       const fetchData = async () => {
  //         try {
  //           const response = await getTime(dataMyCourse[0]._id);
  //           setDataTime(response.data);
  //           setError(null);
  //         } catch (err) {
  //           setError("Error fetching time available data");
  //         }
  //       };

  //       fetchData();
  //     } else {
  //       setError("Invalid course data");
  //     }
  //   }, [dataMyCourse]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTimeAvailable();

        if (data && Array.isArray(data.data.schedule)) {
          const timesToCheck = {};
          const daysToCheck = [];

          data.data.schedule.forEach((schedule) => {
            const day = schedule.day;
            const dayTimes = schedule.time_available.map(
              (time) => time.time_start
            );
            timesToCheck[day] = dayTimes;
            daysToCheck.push(day);
          });

          setSelectedDayTimes(timesToCheck);
          setSelectedDays(daysToCheck);
        }
      } catch (error) {
        setSelectedDays([0]);
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const isChecked = (day: number, time: string) => {
    return selectedDayTimes[day]?.includes(time);
  };

  const handleDayButtonClick = (index: number) => {
    if (!isEditing) return;
    const isSelected = selectedDays.includes(index);

    if (isSelected) {
      setSelectedDays(selectedDays.filter((day) => day !== index));
      setSelectedDayTimes((prevTimes) => {
        const updatedTimes = { ...prevTimes };
        delete updatedTimes[index];
        return updatedTimes;
      });
    } else {
      setSelectedDays([...selectedDays, index]);
      setSelectedDayTimes((prevTimes) => ({
        ...prevTimes,
        [index]: [],
      }));
    }
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleBackButtonClick = () => {
    setIsEditing(false);
  };

  const handleTimeChange = (day: number, time: string) => {
    if (!isEditing) return;
    setSelectedDayTimes((prevTimes) => {
      const timesForDay = prevTimes[day] || [];
      if (timesForDay.includes(time)) {
        return {
          ...prevTimes,
          [day]: timesForDay.filter((t) => t !== time),
        };
      } else {
        return {
          ...prevTimes,
          [day]: [...timesForDay, time],
        };
      }
    });
  };
  console.log(selectedDayTimes, selectedDays);
  const handleSaveButtonClick = async () => {
    try {
      const updatedSchedule = selectedDays.map((day) => ({
        day: day,
        time_available: selectedDayTimes[day].map((time) => ({
          time_start: time,
          time_end: calculateTimeEnd(time), // Hàm tính toán time_end
        })),
      }));

      await updateCourseTime(updatedSchedule);
      setSnackBarMessage("Course time updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      console.log("Course time updated successfully", updatedSchedule);
    } catch (error) {
      setSnackBarMessage("Course time updated fail");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error updating course time:", error);
    }
  };

  const calculateTimeEnd = (timeStart) => {
    const [hour, minute] = timeStart.split(":").map(Number);

    let endHour = hour;
    let endMinute = minute + 30;

    if (endMinute >= 60) {
      endHour = (endHour + 1) % 24;
      endMinute = endMinute % 60;
    }

    return `${endHour.toString().padStart(2, "0")}:${endMinute
      .toString()
      .padStart(2, "0")}`;
  };
  // if(dataTime.length == 0) return     <EmptyState heading="Thời gian khóa " image={emptyIMG}>

  return (
    <Box>
      {dataTime ? (
        <Box>
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {daysOfWeek.map((dayWeek, index) => (
              <Button
                key={index}
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: selectedDays.includes(index)
                    ? "rgba(19, 44, 51, 0.8)"
                    : "#132c33",
                  color: "#fff",
                  margin: "5px",
                }}
                onClick={() => handleDayButtonClick(index)}
              >
                {dayWeek}
              </Button>
            ))}
          </Stack>

          <Stack direction="row" gap={1} justifyContent="space-between">
            {selectedDays.map((day) => (
              <FormGroup key={day} sx={{ flexGrow: 1 }}>
                <Typography variant="body1">{daysOfWeek[day]}</Typography>
                {earlyMorningTimes
                  .concat(morningTimes, afternoonTimes, eveningTimes)
                  .map((time, index, array) => {
                    // Check if current time index is not the last in the array
                    if (index < array.length - 1) {
                      // Create label for time range, e.g., "00:00 - 00:30"
                      let nextTime = array[index + 1];
                      let timeLabel = `${time} - ${nextTime.substring(0, 5)}`;

                      return (
                        <FormControlLabel
                          key={`${day}-${time}`}
                          control={
                            <Checkbox
                              checked={isChecked(day, time)}
                              onChange={() => handleTimeChange(day, time)}
                            />
                          }
                          sx={{ "& .MuiTypography-root": { margin: "0 auto" } }}
                          label={timeLabel}
                        />
                      );
                    } else {
                      return null; // Handle last item in the array
                    }
                  })}
              </FormGroup>
            ))}
          </Stack>
          {!isEditing ? (
            <Button
              variant="contained"
              sx={{ float: "right" }}
              onClick={handleEditButtonClick}
            >
              Chỉnh sửa
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                sx={{ float: "right", ml: 1 }}
                onClick={handleSaveButtonClick}
              >
                Lưu
              </Button>
              <Button sx={{ float: "right" }} onClick={handleBackButtonClick}>
                Quay lại
              </Button>
            </>
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
      {/* {dataMyCourse.map((time, index) => (
            <Box key={index}>{time.title}</Box>
        ))} */}
    </Box>
  );
};

export default Time;
