import React, { useState } from "react";
import { Box, Typography, Stack, TextField, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePicker,
  TimePicker,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";

const CreateRedeem = ({
  handleCloseModal,
  handleSubmit,
  style,
  point,
  coin,
  setPoint,
  setCoin,
  title,
  setTitle,
}) => {
  const handlePointChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setPoint(value);
    } else {
      setPoint(0);
    }
  };

  const handleCoinChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setCoin(value);
    } else {
      setCoin(0);
    }
  };
  return (
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        mb={2}
        fontWeight={550}
      >
        Tạo nhiệm vụ thưởng
      </Typography>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "3px",
          position: "relative",
        }}
      >
        <Typography className="comingSoon" variant="body1">
          Coming soon!
        </Typography>
        <Typography
          sx={{ borderBottom: "1px solid rgba(0,0,0,0.2)", padding: 1 }}
          variant="body1"
        >
          Time Picker
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Box>
            <Typography variant="body2">Starts</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="column">
                <MobileDatePicker
                  className="datePicker"
                  defaultValue={dayjs("2022-04-17")}
                />
                <MobileTimePicker
                  className="timePicker"
                  defaultValue={dayjs("2022-04-17T15:30")}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography variant="body2">End</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="column">
                <MobileDatePicker
                  className="datePicker"
                  defaultValue={dayjs("2022-04-17")}
                />
                <MobileTimePicker
                  className="timePicker"
                  defaultValue={dayjs("2022-04-17T15:30")}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Stack>
      </Box>

      <Box mt={1} mb={1}>
        <TextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          placeholder="Điền tên thưởng"
          variant="outlined"
        />
        <Stack direction="row" gap={2} alignItems="center" mt={1}>
          <TextField
            value={point}
            label="Point"
            onChange={handlePointChange}
            fullWidth
            placeholder="Number point"
            variant="outlined"
          />
          <TextField
            value={coin}
            label="Coin"
            onChange={handleCoinChange}
            fullWidth
            placeholder="Number coin"
            variant="outlined"
          />
        </Stack>
      </Box>
      <Stack direction="row" gap={2}>
        <Button
          onClick={handleCloseModal}
          fullWidth
          variant="outlined"
          sx={{ border: "1px solid #2e3192", color: "#333", fontWeight: 550 }}
        >
          Thoát
        </Button>
        <Button
          onClick={(event) => {
            handleSubmit(event);
            handleCloseModal();
          }}
          fullWidth
          variant="contained"
          sx={{ backgroundColor: "#2e3192", fontWeight: 550 }}
        >
          Tạo
        </Button>
      </Stack>
    </Box>
  );
};

export default CreateRedeem;
