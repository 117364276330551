import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { FaRegEye } from "react-icons/fa6";
import "../assets/css/Practice.css";
import React, { Suspense, useEffect, useState } from "react";
// import TabAffiliate from "../components/TabAffiliate";
// import TabBank from "../components/TabBank";
// import TabCallGroup from "../components/TabCallgroup";
import TabLiveStream from "../components/TabLivestream";
import TabGift from "../components/TabGift";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabAffiliate = React.lazy(() => import("../components/TabAffiliate"));
const TabBank = React.lazy(() => import("../components/TabBank"));
const TabCallGroup = React.lazy(() => import("../components/TabCallgroup"));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const ConfigPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box width="100%" maxWidth={1100} margin="auto" p={"30px 20px"}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550} mb={1}>
          Config
        </Typography>
      </Stack>

      <Box mt={2}>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100vh",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Vertical Tabs"
            sx={{
              width: "100%",
              borderRight: "1px solid #e0e0e0",
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 600,
                alignItems: "flex-start",
                padding: "8px 16px",
              },
            }}
          >
            <Tab label="Affiliate" {...a11yProps(0)} />
            <Tab label="Bank" {...a11yProps(1)} />
            <Tab label="CallGroup" {...a11yProps(2)} />
            {/* <Tab label="LiveStream" {...a11yProps(3)} /> */}
          </Tabs>
          <Suspense fallback={<div>Loading...</div>}>
            <TabPanel value={value} index={0}>
              <TabAffiliate />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TabBank />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabCallGroup />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
              <TabLiveStream />
            </TabPanel> */}
            {/* <TabPanel value={value} index={4}>
              <TabGift  />
            </TabPanel> */}
          </Suspense>
        </Paper>
      </Box>
    </Box>
  );
};

export default ConfigPage;
