import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "./assets/images/logo-ikes.png";
import { bg } from "date-fns/locale";
import { getListClub } from "./service";
import ListClub from "./components/ListClub";
const Club = () => {
  const [clubs, setClubs] = useState<any[]>([]);
  const fetchClubs = async () => {
    try {
      const data = await getListClub();
      setClubs(data);
    } catch (error) {
      console.error("Error fetching clubs:", error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        margin: "0 auto",
        padding: "30px 10px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={550}>
          Danh sách Câu lạc bộ
        </Typography>
      </Stack>
      <ListClub clubs={clubs} placeholderImage={img} />;
    </Box>
  );
};

export default Club;
