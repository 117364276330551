import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useInfiniteScroll } from "entities/community/components/useInfiniteScroll";
import React from "react";
import { useParams } from "react-router-dom";
import { deleteEvaluation, getListEvaluation } from "../services";
import { DataGrid } from "@mui/x-data-grid";
import { HiOutlineDotsVertical } from "react-icons/hi";
import dayjs from "dayjs";
import EvaluationForm from "../components/evaluation.form";
import eventEmitter from "service/eventa-emiter";
const styleContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
};
const EditModal = React.memo(
  ({ dataParams, reset }: { dataParams: any[]; reset: () => void }) => {
    // console.log("123", dataParams);
    const [open, setOpen] = React.useState<boolean>(false);
    const handleCloseModal = () => setOpen(false);
    const [messageSnackBar, setMessageSnackBar] = React.useState("");
    const [active, setActive] = React.useState(false);

    const toggleActive = React.useCallback(
      () => setActive((active) => !active),
      []
    );
    const handleOpenModal = () => {
      setOpen(true);
    };
    return (
      <Box>
        <Button onClick={handleOpenModal}>
          <Typography>Sửa</Typography>
        </Button>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleContainer}>
            <EvaluationForm
              dataParams={dataParams}
              handleCloseModal={handleCloseModal}
              setMessageSnackBar={setMessageSnackBar}
              toggleActive={toggleActive}
              reset={reset}
            />
          </Box>
        </Modal>
        <Snackbar
          open={active}
          autoHideDuration={6000}
          onClose={toggleActive}
          message={messageSnackBar}
          color="#58fc66"
          // action={action}
        />
      </Box>
    );
  }
);
const RenderList = React.memo(({ classId }: { classId: string }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = React.useState<null | any>(null);
  const { items: listData, _requestData } = useInfiniteScroll(
    getListEvaluation,
    {
      classId: classId,
      order_by: "DESC",
    },
    { initialLimit: 10 }
  );
  React.useEffect(() => {
    eventEmitter.on("reload_evaluation", _requestData);
    return () => {
      eventEmitter.off("reload_evaluation", _requestData);
    };
  }, []);
  const columns = [
    {
      field: "Student",
      headerName: "Học viên",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
            <img
              src={
                params.row?.studentId?.user_avatar ||
                params.row?.studentId?.user_avatar_thumbnail
              }
              style={{ height: 30, width: 30, borderRadius: 100 }}
            />
            <Typography variant="body1">
              {params.row?.studentId?.display_name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Người tạo",
      width: 200,
      renderCell: (params) => {
        return params.row?.user_id?.display_name;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        return (
          // <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Typography>
            {dayjs(params.row?.date).format("DD/MM/YYYY")}
          </Typography>
          // </Stack>
        );
      },
    },
    {
      field: "category_content",
      headerName: "Mô tả",
      width: 400,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography>{params.row?.onTime}</Typography>
            <Typography>{params.row?.lessonParticipation}</Typography>
            <Typography>{params.row?.improvementPoints}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "Hành động",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              aria-label="more"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleMenuClick(e, params.row)}
            >
              <HiOutlineDotsVertical style={{ color: "#333" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open && currentRow && currentRow._id === params.row._id}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <EditModal dataParams={currentRow} reset={_requestData} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (window.confirm("Bạn muốn xoá danh mục này không")) {
                    handleDelete(params.row._id);
                  }
                }}
              >
                <Button>
                  <Typography>Xóa</Typography>
                </Button>
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };
  const handleDelete = (id: string) => {
    deleteEvaluation(id, _requestData);
  };

  return (
    <DataGrid
      rows={listData}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[5, 10]}
      // checkboxSelection
      getRowId={(row) => row._id}
    />
  );
});
const EvaluationList = ({ classId }: { classId?: string }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleCloseModal = () => setOpen(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Button onClick={handleOpenModal}>
        <Typography>Lịch sử đánh giá</Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <RenderList classId={classId} /> */}
        <Box sx={styleContainer}>
          <RenderList classId={classId} />
        </Box>
      </Modal>
    </Box>
  );
};

export default React.memo(EvaluationList);
