import React from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { IoSwapVerticalOutline, IoTrashOutline } from "react-icons/io5";
import { CouponUpdate } from "../interface";

interface EditCouponModalProps {
  open: boolean;
  onClose: () => void;
  couponData: Partial<CouponUpdate>;
  setCouponData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onDelete: () => void;
  adornment: string;
  toggleAdornment: () => void;
}

const EditCouponModal: React.FC<EditCouponModalProps> = ({
  open,
  onClose,
  couponData,
  onChange,
  onSubmit,
  onDelete,
  setCouponData,
  adornment,
  toggleAdornment,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6" fontWeight={550}>
            Chỉnh sửa mã giảm giá
          </Typography>
          <IoTrashOutline
            fontSize={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={onDelete}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <TextField
              label="Tiêu đề"
              name="title"
              size="small"
              value={couponData.title || ""}
              onChange={onChange}
              fullWidth
            />
            <TextField
              label="Mã giảm giá"
              name="code"
              size="small"
              value={couponData.code || ""}
              onChange={onChange}
              fullWidth
            />
          </Stack>
          <TextField
            label="Mô tả"
            name="description"
            size="small"
            value={couponData.description || ""}
            onChange={onChange}
            fullWidth
          />
          <Stack direction="row" spacing={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="payment-method-label">
                Phương thức thanh toán
              </InputLabel>
              <Select
                labelId="payment-method-label"
                value={couponData.payment_method || "all"}
                onChange={(e) =>
                  onChange({
                    target: { name: "payment_method", value: e.target.value },
                  })
                }
                label="Phương thức thanh toán"
              >
                <MenuItem value="all">Tất cả</MenuItem>
                <MenuItem value="transfer">Chuyển khoản</MenuItem>
                <MenuItem value="vn_pay">VN Pay</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel id="visibility-label">Hiển thị</InputLabel>
              <Select
                labelId="visibility-label"
                value={couponData.visible || "public"}
                onChange={(e) =>
                  onChange({
                    target: { name: "visible", value: e.target.value },
                  })
                }
                label="Hiển thị"
              >
                <MenuItem value="private">Riêng tư</MenuItem>
                <MenuItem value="public">Công khai</MenuItem>
                <MenuItem value="product">Sản phẩm</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Tổng số vé"
              name="total"
              size="small"
              type="number"
              value={couponData.total?.toString() || ""}
              onChange={onChange}
              fullWidth
            />
            <TextField
              label="Giảm giá"
              name="promotion"
              size="small"
              type="number"
              value={couponData.promotion?.toString() || ""}
              onChange={onChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">{adornment}</InputAdornment>
                    <IconButton onClick={toggleAdornment} size="small">
                      <IoSwapVerticalOutline />
                    </IconButton>
                  </>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Ngày bắt đầu"
              name="availableAt"
              size="small"
              type="date"
              value={couponData.availableAt || ""}
              onChange={onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Ngày kết thúc"
              name="expired"
              size="small"
              type="date"
              value={couponData.expired || ""}
              onChange={onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
          <Button onClick={onClose}>Hủy</Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            sx={{ bgcolor: "#B32519", "&:hover": { bgcolor: "#B32519" } }}
          >
            Cập nhật
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditCouponModal;
