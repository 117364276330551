import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Stack,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import CustomSnackbar from "components/snackbar";
import { uploadMedia } from "entities/course/service";
import {
  createAnswer,
  deleteQuestion,
  updateAnswer,
  updateQuestion,
  updateQuestionCheck,
  updateQuestionText,
} from "entities/practice-exam/service";
import { useEffect, useState } from "react";
import { CiTrash } from "react-icons/ci";
import ReactQuill from "react-quill";
import { getCookie } from "utils/setCookie";

interface Question {
  _id: string;
  type?: string;
  answerCount: number;
  title?: string;
  content?: string;
  description?: string;
}

interface QuestionDetailModalProps {
  open: boolean;
  question: any;
  answers: any[];
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  formData: {
    title: string;
    content: string;
  };
  textFields: string[];
  uploadedFile: { name: string; id: string } | null;
  onClose: () => void;
  onSaveText: () => void;
  onFieldChange: (key: string, value: string) => void;
  onFileUpload: (files: FileList | null) => void;
  onRemoveFile: () => void;
  onAddAnswer: () => void;
  onAnswerChange: (index: number, value: string) => void;
  onDeleteAnswer: (index: number) => void;
  dataQuestions: any;
  setSnackbarOpen: (value: boolean) => void;
  setSnackbarMessage: (value: string) => void;
  setSnackbarSeverity: (value: "success" | "error") => void;
  textBox: any[];
  setTextBox: (value: any[]) => void;
  handleAddTextBox: () => void;
  handleDeleteTextBox: (index: number) => void;
  handleSelectCorrectAnswer: (index: number) => void;
}

export const QuestionDetailModal: React.FC<QuestionDetailModalProps> = ({
  open,
  onClose,
  question: initialQuestion,
  answers: initialAnswers,
  isLoading,
  setIsLoading,
  dataQuestions,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  textBox,
  setTextBox,
  handleAddTextBox,
  handleDeleteTextBox,
  handleSelectCorrectAnswer,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [answers, setAnswers] = useState(initialAnswers);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  console.log("datatiesptso", question);
  useEffect(() => {
    if (open) {
      setQuestion(initialQuestion);
      setAnswers(initialAnswers);
    }
  }, [open, initialQuestion, initialAnswers]);

  const handleFieldChange = (field, value) => {
    if (field === "options") {
      setQuestion((prev) => {
        const updatedOptions = [...prev.options];
        updatedOptions[value.index] = value.value;
        return { ...prev, options: updatedOptions };
      });
    } else {
      setQuestion((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleAnswerChange = (index: number, value: string) => {
    setAnswers((prev) =>
      prev.map((answer, i) =>
        i === index ? { ...answer, answer: value } : answer
      )
    );
  };

  const handleDeleteOption = (index: number) => {
    const updatedOptions = question.options.filter((_, i) => i !== index);
    setQuestion((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
  };

  const handleSave = async () => {
    try {
      if (textBox.length > 0) {
        const updatedData = {
          _id: question._id,
          test_id: question.test_id || "",
          index: question.index,
          title: question.title,
          answer: question.answer,
          content: question.content,
          type: "text",
          part: "reading",
        };

        await updateQuestionText(updatedData);

        setSnackbarMessage("Cập nhật câu hỏi thành công!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        if (dataQuestions) {
          await dataQuestions(question.test_id || "");
        }

        onClose();
      }
    } catch (error) {
      setSnackbarMessage("Cập nhật câu hỏi thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveCheck = async () => {
    try {
      const data = {
        _id: question._id,
        test_id: question.test_id,
        index: question.index,
        title: question.title,
        answer: question.answer,
        content: question.content,
        type: "radio",
        part: "reading",
        options: question.options,
      };

      const response = await updateQuestionCheck(data);

      setSnackbarMessage("Cập nhật câu hỏi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      if (dataQuestions) {
        await dataQuestions(question.test_id || "");
      }

      onClose();
    } catch (error) {
      setSnackbarMessage("Cập nhật câu hỏi thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSaveCheckBox = async () => {
    try {
      const cleanedAnswer = (question.answer || "")
        .split(", ")
        .filter((value) => question.options.includes(value))
        .join(", ");

      const data = {
        _id: question._id,
        test_id: question.test_id,
        index: question.index,
        title: question.title,
        answer: cleanedAnswer,
        content: question.content,
        type: "checkbox",
        part: "reading",
        options: question.options,
      };

      const response = await updateQuestionCheck(data);

      setSnackbarMessage("Cập nhật câu hỏi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      if (dataQuestions) {
        await dataQuestions(question.test_id || "");
      }

      onClose();
    } catch (error) {
      setSnackbarMessage("Cập nhật câu hỏi thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async (id: string) => {
    onClose();

    try {
      setIsLoading(true);
      await deleteQuestion(id);
      setSnackbarMessage("Xóa câu hỏi thành công!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      if (dataQuestions) {
        await dataQuestions(question.test_id || "");
      }
    } catch (error) {
      console.error("Failed to delete question:", error);
      setSnackbarMessage("Xóa câu hỏi thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 800,
          height: "600px",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
        ) : (
          <>
            {question?.type === "text" && (
              <>
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    id="create-question-title"
                    variant="h6"
                    component="h2"
                  >
                    Câu hỏi và câu trả lời
                  </Typography>
                  <IconButton color="error" onClick={handleDeleteDialogOpen}>
                    <CiTrash />
                  </IconButton>
                </Stack>
                <TextField
                  id="question-title"
                  label="Tiêu đề câu hỏi"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={question?.title || ""}
                  onChange={(e) => handleFieldChange("title", e.target.value)}
                  sx={{ mb: 3 }}
                />

                <Box sx={{ mb: 4 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap={1}
                    mb={2}
                  >
                    <Box width="100%">
                      <ReactQuill
                        value={question?.content || ""}
                        onChange={(value) =>
                          handleFieldChange("content", value || "")
                        }
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ header: [1, 2, 3, false] }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                          ],
                        }}
                        theme="snow"
                        style={{ width: "100%", boxSizing: "border-box" }}
                      />
                    </Box>

                    <TextField
                      size="small"
                      placeholder="Nhập câu trả lời"
                      value={question?.answer || ""}
                      onChange={(e) =>
                        handleFieldChange("answer", e.target.value)
                      }
                    />
                  </Stack>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    marginTop: "auto",
                  }}
                >
                  <Button variant="outlined" onClick={onClose}>
                    Hủy
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Lưu
                  </Button>
                </Box>
              </>
            )}
            {question?.type === "radio" && (
              <>
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    id="create-question-title"
                    variant="h6"
                    component="h2"
                  >
                    Câu hỏi và câu trả lời
                  </Typography>
                  <IconButton color="error" onClick={handleDeleteDialogOpen}>
                    <CiTrash />
                  </IconButton>
                </Stack>
                <Box
                  sx={{
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      label="Tiêu đề câu hỏi"
                      value={question.title}
                      onChange={(e) =>
                        handleFieldChange("title", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>

                  <ReactQuill
                    value={question?.content || ""}
                    onChange={(value) =>
                      handleFieldChange("content", value || "")
                    }
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                      ],
                    }}
                    theme="snow"
                    style={{ marginBottom: "20px" }}
                  />

                  {question.type === "radio" && (
                    <Box
                      sx={{
                        border: "1px solid #81818199",

                        borderRadius: "8px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Câu trả lời
                      </Typography>
                      <RadioGroup
                        value={question.answer}
                        onChange={(e) =>
                          handleFieldChange("answer", e.target.value)
                        }
                      >
                        {(question.options || []).map((option, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <FormControlLabel
                              value={option}
                              control={<Radio />}
                              label={`Câu ${String.fromCharCode(65 + index)}`}
                              sx={{ mr: 2 }}
                            />
                            <TextField
                              value={option}
                              size="small"
                              onChange={(e) =>
                                handleFieldChange("options", {
                                  index,
                                  value: e.target.value,
                                })
                              }
                              sx={{ flex: 1 }}
                            />
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteOption(index)}
                              disabled={question.options.length <= 2}
                            >
                              <CiTrash />
                            </IconButton>
                          </Box>
                        ))}
                      </RadioGroup>
                    </Box>
                  )}
                  <Box sx={{ textAlign: "right", mt: 4 }}>
                    <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                      Hủy
                    </Button>
                    <Button variant="contained" onClick={handleSaveCheck}>
                      Lưu
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            {question?.type === "checkbox" && (
              <>
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    id="create-question-title"
                    variant="h6"
                    component="h2"
                  >
                    Câu hỏi và câu trả lời
                  </Typography>
                  <IconButton color="error" onClick={handleDeleteDialogOpen}>
                    <CiTrash />
                  </IconButton>
                </Stack>
                <Box
                  sx={{
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      label="Tiêu đề câu hỏi"
                      value={question.title}
                      onChange={(e) =>
                        handleFieldChange("title", e.target.value)
                      }
                      fullWidth
                    />
                  </Box>

                  <ReactQuill
                    value={question?.content || ""}
                    onChange={(value) =>
                      handleFieldChange("content", value || "")
                    }
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                      ],
                    }}
                    theme="snow"
                    style={{ marginBottom: "20px" }}
                  />

                  <Box
                    sx={{
                      border: "1px solid #81818199",
                      borderRadius: "8px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Câu trả lời
                    </Typography>
                    {(question.options || []).map((option, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(question.answer || "")
                                .split(", ")
                                .includes(option)}
                              onChange={(e) => {
                                const updatedAnswers = new Set(
                                  (question.answer || "").split(", ")
                                );

                                if (e.target.checked) {
                                  updatedAnswers.add(option);
                                } else {
                                  updatedAnswers.delete(option);
                                }

                                handleFieldChange(
                                  "answer",
                                  Array.from(updatedAnswers).join(", ")
                                );
                              }}
                            />
                          }
                          label={`Câu ${String.fromCharCode(65 + index)}`}
                          sx={{ mr: 2 }}
                        />

                        <TextField
                          value={option}
                          size="small"
                          onChange={(e) =>
                            handleFieldChange("options", {
                              index,
                              value: e.target.value,
                            })
                          }
                          sx={{ flex: 1 }}
                        />

                        {/* Xóa option */}
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteOption(index)}
                          disabled={question.options.length <= 2}
                        >
                          <CiTrash />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>

                  {/* Nút Lưu và Hủy */}
                  <Box sx={{ textAlign: "right", mt: 4 }}>
                    <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                      Hủy
                    </Button>
                    <Button variant="contained" onClick={handleSaveCheckBox}>
                      Lưu
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}

        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Bạn có chắc chắn muốn xóa câu hỏi này không?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Hủy
            </Button>
            <Button
              onClick={() => handleDelete(question._id)}
              color="secondary"
              autoFocus
            >
              Xóa
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoadingUpload}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Modal>
  );
};
