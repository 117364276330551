import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Event as EventIcon,
  CircleOutlined as StatusIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import { updateSpeakingStatusAdmin } from "../service";

const InfoRow = ({ icon, label, value }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
    {icon}
    <Box>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  </Box>
);

const BookingItem = ({ data, teachers }) => {
  const getStatusColor = (status) => {
    const statusColors = {
      pending: "warning",
      approved: "success",
      canceled: "error",
      default: "default",
      testting: "default",
      tested: "default",
      successed: "default",
    };
    return statusColors[status] || statusColors.default;
  };

  const [localBooking, setLocalBooking] = useState(data);
  const [dataBooking, setDataBooking] = useState(data);
  const hasChanges = useMemo(() => {
    return (
      localBooking.status !== dataBooking.status ||
      localBooking.teacher_id?._id !== dataBooking.teacher_id?._id
    );
  }, [localBooking, dataBooking]);

  const handleTeacherChange = (event) => {
    const idTeacher = event.target.value;
    const newTeacher = teachers.find((user) => user._id === idTeacher);
    setLocalBooking((prev) => ({
      ...prev,
      teacher_id: newTeacher,
    }));
  };

  const handleStatusChange = (event) => {
    setLocalBooking((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  // const onUpdate = ({ status, teacher_id }) => {
  //   console.log({
  //     status: localBooking.status,
  //     teacher_id: localBooking.teacher_id,
  //   });
  // };

  const handleUpdate = () => {
    const data = {
      _id: localBooking._id,
      status: localBooking.status,
      teacher_id: localBooking.teacher_id?._id || null,
    };
    updateSpeakingStatusAdmin(data).then((res: any) => {
      const newDataBooking = res.data;
      console.log("res.updata..", newDataBooking);
      setDataBooking(newDataBooking);
    });
  };

  return (
    <Card sx={{ maxWidth: 800, width: "100%" }}>
      <CardContent>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <InfoRow
              icon={<PersonIcon color="action" />}
              label="Full Name"
              value={data.full_name}
            />
            <InfoRow
              icon={<EmailIcon color="action" />}
              label="Email"
              value={data.email}
            />
            <InfoRow
              icon={<PhoneIcon color="action" />}
              label="Phone Number"
              value={data.phone_number}
            />
            <InfoRow
              icon={<EventIcon color="action" />}
              label="Date & Time"
              value={format(new Date(data.date_time), "HH:mm dd/MM/yyyy")}
            />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <InfoRow
              icon={<PersonIcon color="action" />}
              label="Student"
              value={data?.student_id?.display_name || ""}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <PersonIcon color="action" />
              <Box sx={{ flexGrow: 1 }}>
                <FormControl fullWidth size="small">
                  <InputLabel>Teacher</InputLabel>
                  <Select
                    value={localBooking.teacher_id?._id || ""}
                    onChange={handleTeacherChange}
                    label="Teacher"
                  >
                    <MenuItem value="">
                      <em>Not assigned</em>
                    </MenuItem>
                    {teachers.map((teacher) => (
                      <MenuItem key={teacher._id} value={teacher._id}>
                        {teacher.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <StatusIcon color="action" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  Status
                </Typography>
                <Box>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      mt: 0.5,
                      borderColor: getStatusColor(localBooking.status),
                    }}
                  >
                    <Select
                      value={localBooking.status || "pending"}
                      onChange={handleStatusChange}
                      size="small"
                      sx={{ height: 32 }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="approved">Approved</MenuItem>
                      <MenuItem value="canceled">Canceled</MenuItem>
                      <MenuItem value="testting">Testing</MenuItem>
                      <MenuItem value="tested">Tested</MenuItem>
                      <MenuItem value="successed">Successed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            {/* Update Button */}
            {hasChanges && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleUpdate}
                  size="small"
                >
                  Update
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BookingItem;
