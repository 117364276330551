// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-cell {
    display: flex !important;
    align-items: center !important;
}

.MuiPaper-elevation {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.selectedCountry .MuiOutlinedInput-root {
}`, "",{"version":3,"sources":["webpack://./src/entities/users/styles/user.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,8BAA8B;AAClC;;AAEA;IACI,4DAA4D;AAChE;;AAEA;AACA","sourcesContent":[".avatar-cell {\n    display: flex !important;\n    align-items: center !important;\n}\n\n.MuiPaper-elevation {\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;\n}\n\n.selectedCountry .MuiOutlinedInput-root {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
