import React, { ChangeEvent } from "react";
import {
  Modal,
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { CreateCouponModalProps } from "../interface";
import { IoSwapVerticalOutline } from "react-icons/io5";
const CreateCouponModal: React.FC<CreateCouponModalProps> = ({
  openModal,
  handleCloseModal,
  couponData,
  handleChange,
  handleChangePayment,
  paymentMethod,
  handleChangeVisible,
  visible,
  toggleAdornment,
  adornment,
  handleSubmit,
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography variant="h6" mb={2} fontWeight={550}>
          Tạo mã giảm giá
        </Typography>
        <Stack spacing={2}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Tiêu đề"
              name="title"
              size="small"
              value={couponData.title}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Mã giảm giá"
              name="code"
              size="small"
              value={couponData.code}
              onChange={handleChange}
              fullWidth
            />
          </Stack>
          <TextField
            label="Mô tả"
            name="description"
            size="small"
            value={couponData.description}
            onChange={handleChange}
            fullWidth
          />
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <FormControl size="small" fullWidth>
              <InputLabel id="payment-method-label">
                Phương thức thanh toán
              </InputLabel>
              <Select
                labelId="payment-method-label"
                value={paymentMethod}
                onChange={handleChangePayment}
                label="Phương thức thanh toán"
              >
                <MenuItem value="all">Tất cả</MenuItem>
                <MenuItem value="transfer">Chuyển khoản</MenuItem>
                <MenuItem value="vn_pay">VN Pay</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel id="payment-method-label">Hiển thị</InputLabel>
              <Select
                labelId="payment-method-label"
                value={visible}
                onChange={handleChangeVisible}
                label="Hiển thị"
              >
                <MenuItem value="private">Riêng tư</MenuItem>
                <MenuItem value="public">Công khai</MenuItem>
                <MenuItem value="product">Sản phẩm</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Tổng số vé"
              name="total"
              size="small"
              value={couponData.total}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Giảm giá"
              name="promotion"
              size="small"
              value={couponData.promotion}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">{adornment}</InputAdornment>
                    <IconButton onClick={toggleAdornment} size="small">
                      <IoSwapVerticalOutline />
                    </IconButton>
                  </>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              label="Ngày bắt đầu"
              name="availableAt"
              size="small"
              type="date"
              value={couponData.availableAt}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Ngày hết hạn"
              name="expired"
              size="small"
              type="date"
              value={couponData.expired}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
          <Button onClick={handleCloseModal}>Hủy</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ bgcolor: "#B32519", "&:hover": { bgcolor: "#B32519" } }}
          >
            Tạo mã giảm giá
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CreateCouponModal;
