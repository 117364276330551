import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import { CiTrash } from "react-icons/ci";

interface UploadedFile {
  name: string;
  id: string;
}

interface CreateQuestionModalProps {
  open: boolean;
  formData: {
    title: string;
    content: string;
  };
  uploadedFile: UploadedFile | null;
  isLoading: boolean;
  onClose: () => void;
  onSave: () => void;
  onFieldChange: (field: string, value: string) => void;
  onFileUpload: (files: FileList | null) => void;
  onRemoveFile: () => void;
}

const CreateQuestionModal: React.FC<CreateQuestionModalProps> = ({
  open,
  formData,
  uploadedFile,
  isLoading,
  onClose,
  onSave,
  onFieldChange,
  onFileUpload,
  onRemoveFile,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-question-title"
      aria-describedby="create-question-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 600,
          height: "600px",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          id="create-question-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Tạo câu hỏi mới
        </Typography>

        <TextField
          id="question-title"
          label="Tiêu đề câu hỏi"
          variant="outlined"
          size="small"
          fullWidth
          value={formData.title}
          onChange={(e) => onFieldChange("title", e.target.value)}
          sx={{ mb: 3 }}
        />

        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <ReactQuill
              value={formData.content || ""} 
              onChange={(content) => onFieldChange("content", content || "")}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
              ],
            }}
            theme="snow"
            style={{
              flex: 1,
              overflow: "auto",
              maxHeight: "100%",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: "auto",
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Hủy
          </Button>
          <Button variant="contained" onClick={onSave}>
            Lưu
          </Button>
        </Box>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Modal>
  );
};

export default CreateQuestionModal;
