import { Box, Grid } from "@mui/material";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Image } from "./image";

const PostImages = ({ attach_files }: any) => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  if (!attach_files || attach_files.length === 0) return null;

  const handleImageClick = (index: number) => {
    setCurrentImage(index);
    setOpen(true);
  };

  const images = attach_files
    .filter(isImage)
    .map((file: any) => ({ src: file.media_url, alt: file.media_file_name }));
  const videos = attach_files
    .filter(isVideo)
    .map((file: any) => file.media_url);

  return (
    <>
      {attach_files.length === 1 && (
        <Box sx={{ position: "relative", width: "100%" }}>
          {isImage(attach_files[0]) ? (
            <Image
              src={attach_files[0].media_url}
              alt={attach_files[0].media_file_name}
              ratio="16/9"
              onClick={() => handleImageClick(0)}
              style={{
                width: "100%",
                borderRadius: 1.5,
                cursor: "pointer",
              }}
            />
          ) : isVideo(attach_files[0]) ? (
            <video
              src={attach_files[0].media_url}
              controls
              style={{
                width: "100%",
                borderRadius: 1.5,
                cursor: "pointer",
              }}
            />
          ) : null}
        </Box>
      )}

      {attach_files.length === 2 && (
        <Grid container spacing={2}>
          {attach_files.map((file: any, index: number) => (
            <Grid key={file._id} item xs={6}>
              <Box sx={{ position: "relative", width: "100%" }}>
                {isImage(file) ? (
                  <Image
                    src={file.media_url}
                    alt={file.media_file_name}
                    ratio="16/9"
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : isVideo(file) ? (
                  <video
                    src={file.media_url}
                    controls
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {attach_files.length === 3 && (
        <Grid container spacing={2}>
          {attach_files.slice(0, 3).map((file: any, index: number) => (
            <Grid key={index} item xs={index < 2 ? 6 : 12}>
              <Box sx={{ position: "relative", width: "100%" }}>
                {isImage(file) ? (
                  <Image
                    src={file.media_url}
                    alt={file.media_file_name}
                    ratio="16/9"
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : isVideo(file) ? (
                  <video
                    src={file.media_url}
                    controls
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {attach_files.length === 4 && (
        <Grid container spacing={2}>
          {attach_files.slice(0, 4).map((file: any, index: number) => (
            <Grid key={index} item xs={6}>
              <Box sx={{ position: "relative", width: "100%" }}>
                {isImage(file) ? (
                  <Image
                    src={file.media_url}
                    alt={file.media_file_name}
                    ratio="16/9"
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : isVideo(file) ? (
                  <video
                    src={file.media_url}
                    controls
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {attach_files.length > 4 && (
        <Grid container spacing={2}>
          {attach_files.slice(0, 2).map((file: any, index: number) => (
            <Grid key={index} item xs={6}>
              <Box sx={{ position: "relative", width: "100%" }}>
                {isImage(file) ? (
                  <Image
                    src={file.media_url}
                    alt={file.media_file_name}
                    ratio="16/9"
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : isVideo(file) ? (
                  <video
                    src={file.media_url}
                    controls
                    style={{
                      width: "100%",
                      borderRadius: 1.5,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
          ))}

          {attach_files.slice(2, 5).map((file: any, index: number) => {
            const isLastVisible = index === 2 && attach_files.length > 5;
            return (
              <Grid key={index} item xs={4}>
                <Box sx={{ position: "relative", width: "100%" }}>
                  {isImage(file) ? (
                    <Image
                      src={file.media_url}
                      alt={file.media_file_name}
                      ratio="16/9"
                      onClick={() => handleImageClick(index + 2)}
                      style={{
                        width: "100%",
                        borderRadius: 1.5,
                        cursor: "pointer",
                        filter: isLastVisible ? "brightness(50%)" : "none",
                      }}
                    />
                  ) : isVideo(file) ? (
                    <video
                      src={file.media_url}
                      controls
                      style={{
                        width: "100%",
                        borderRadius: 1.5,
                        cursor: "pointer",
                        filter: isLastVisible ? "brightness(50%)" : "none",
                      }}
                    />
                  ) : null}
                  {isLastVisible && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        fontSize: "24px",
                        fontWeight: "bold",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      +{attach_files.length - 5}
                    </Box>
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images}
        plugins={[Thumbnails]}
        thumbnails={{
          position: "bottom",
          showToggle: true,
          width: 120,
          height: 80,
        }}
      />
    </>
  );
};

const isImage = (file: any) => {
  return file.media_mime_type && file.media_mime_type.startsWith("image");
};

const isVideo = (file: any) => {
  return file.media_mime_type && file.media_mime_type.startsWith("video");
};

export default PostImages;
