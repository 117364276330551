import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListCourse = async (params): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "course/v2/list";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params: params,
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getClassDetail = async (course_id: string): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL +
    `course/class/list?order_by=DESC&course_id=${course_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getListStudentSelfLearning = async (
  course_id: string
): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL +
    `course/self-learning/list?order_by=DESC&course_id=${course_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const deleteStudentSelfLearning = async (data: {
  course_id: string;
  user_id: string;
}): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL + `course/self-learning/remove-member`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.post(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const restoreStudentSelfLearning = async (data: {
  course_id: string;
  user_id: string;
}): Promise<any> => {
  const url =
    process.env.REACT_APP_AJAX_URL + `course/self-learning/add-member`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    return await axios.post(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const createEvaluation = async (data: {
  studentId?: string;
  classId?: string;
  date: any;
  onTime?: string;
  goodAttitude?: boolean;
  lessonParticipation?: string;
  takesNotes?: boolean;
  doesHomework?: boolean;
  improvementPoints?: string;
}) => {
  const url = `${process.env.REACT_APP_AJAX_URL}course/create-evaluation`;
  const options = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  try {
    const response = await axios.post(url, data, options);
    return response.data;
  } catch (error: any) {
    console.error("Failed ", error);
    throw new Error(
      "Failed : " + (error.response?.data?.message || error.message)
    );
  }
};

export const updateEvaluation = async (data: {
  _id: string;
  date: any;
  onTime?: string;
  goodAttitude?: boolean;
  lessonParticipation?: string;
  takesNotes?: boolean;
  doesHomework?: boolean;
  improvementPoints?: string;
}) => {
  const url = `${process.env.REACT_APP_AJAX_URL}course/update-evaluation`;
  const options = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  try {
    const response = await axios.patch(url, data, options);
    return response.data;
  } catch (error: any) {
    console.error("Failed ", error);
    throw new Error(
      "Failed : " + (error.response?.data?.message || error.message)
    );
  }
};
export const getListEvaluation = async (params: {
  page?: number;
  limit?: number;
  classId?: string;
  studentId?: string;
  order_by?: string;
  endDate?: string;
  startDate?: string;
}): Promise<any> => {
  const url = `${process.env.REACT_APP_AJAX_URL}course/list-evaluation`;
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
    params,
  };
  try {
    const response = await axios.get(url, axiosConfig);
    return response;
  } catch (error: any) {
    console.error("Failed ", error);
    throw new Error(
      "Failed : " + (error.response?.data?.message || error.message)
    );
  }
};

export const deleteEvaluation = async (
  id: string,
  _requestData: () => void
) => {
  const url = `${process.env.REACT_APP_AJAX_URL}course/delete-evaluation/${id}`;
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
    // params,
  };
  try {
    const response = await axios.delete(url, axiosConfig);
    if (!response?.isError) {
      alert("Xóa thành công");
      _requestData();
    }
  } catch (error: any) {
    console.error("Failed ", error);
    alert(error.response?.data?.message || error.message);
    // throw new Error(
    //   "Failed : " + (error.response?.data?.message || error.message)
    // );
  }
};
