import React from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { EditExamReadingModalProps } from "entities/practice-exam/interface";
import { CiTrash } from "react-icons/ci";
import { DatePicker } from "@mui/x-date-pickers";

const EditExamModal: React.FC<EditExamReadingModalProps> = ({
  open,
  onClose,
  _id,
  title,
  setTitle,
  description,
  setDescription,
  selectedTime,
  setSelectedTime,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  onSubmit,
  handleTimeChange,
  uploadedFile,
  onFileUpload,
  onRemoveFile,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-exam-title"
      aria-describedby="edit-exam-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 4,
        }}
      >
        <Typography id="edit-exam-title" variant="h6" gutterBottom>
          Chỉnh sửa bài thi
        </Typography>
        <Box component="form">
          
          <TextField
            label="Tên bài thi"
            fullWidth
            variant="outlined"
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Mô tả"
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box mt={2}>
              <TimePicker
                label="Thời gian"
                ampm={false}
                value={selectedTime}
                onChange={handleTimeChange}
                slotProps={{
                  textField: { fullWidth: true },
                  popper: { disablePortal: true },
                }}
                sx={{ width: "100%" }}
              />
            </Box>
            <Stack direction="row" alignItems="center" gap={2} mt={3}>
              <DatePicker
                label="Ngày bắt đầu"
                value={startTime}
                onChange={setStartTime}
                slotProps={{ popper: { disablePortal: true } }}
                sx={{ width: "100%" }}
              />
              <DatePicker
                label="Ngày kết thúc"
                value={endTime}
                onChange={setEndTime}
                slotProps={{ popper: { disablePortal: true } }}
                sx={{ width: "100%" }}
              />
            </Stack>
          </LocalizationProvider>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              sx={{ mr: 1 }}
            >
              Cập nhật
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Hủy
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditExamModal;
