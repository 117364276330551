import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Stack,
  Backdrop,
} from "@mui/material";
import CustomSnackbar from "components/snackbar";
import { uploadMedia } from "entities/course/service";
import {
  createAnswer,
  updateAnswer,
  updateQuestion,
} from "entities/practice-exam/service";
import { useEffect, useState } from "react";
import { CiTrash } from "react-icons/ci";
import ReactQuill from "react-quill";
import { getCookie } from "utils/setCookie";

interface Question {
  _id: string;
  type?: string;
  answerCount: number;
  title?: string;
  content?: string;
  description?: string;
}

interface QuestionDetailModalProps {
  open: boolean;
  question: any;
  answers: any[];
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  formData: {
    title: string;
    content: string;
  };
  uploadedFile: { name: string; id: string } | null;
  onClose: () => void;
  onSave: () => void;
  onFieldChange: (key: string, value: string) => void;
  onFileUpload: (files: FileList | null) => void;
  onRemoveFile: () => void;
  dataQuestions: any;
}

export const QuestionDetailModal: React.FC<QuestionDetailModalProps> = ({
  open,
  onClose,
  question: initialQuestion,
  answers: initialAnswers,
  isLoading,
  setIsLoading,
  dataQuestions,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [answers, setAnswers] = useState(initialAnswers);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  useEffect(() => {
    if (open) {
      setQuestion(initialQuestion);
    }
  }, [open, initialQuestion, initialAnswers]);

  const handleFieldChange = (field: string, value: string) => {
    setQuestion((prev) => ({ ...prev, [field]: value }));
  };

  //   const handleAnswerChange = (index: number, value: string) => {
  //     setAnswers((prev) =>
  //       prev.map((answer, i) =>
  //         i === index ? { ...answer, answer: value } : answer
  //       )
  //     );
  //   };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //   const handleDeleteAnswer = (index: number) => {
  //     setAnswers((prev) => prev.filter((_, i) => i !== index));
  //   };

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    setIsLoadingUpload(true);
    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };

      const uploadResponse = await uploadMedia(file, headers);

      if (uploadResponse && uploadResponse.id) {
        const fileName = uploadResponse.callback?.media_file_name || file.name;

        setUploadedFile({ name: fileName, id: uploadResponse.id });
        setQuestion((prev) => ({ ...prev, media_id: uploadResponse.id }));
        setSnackbarMessage("File uploaded successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("File upload failed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading the file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    setQuestion((prev) => ({ ...prev, media_id: "" }));
  };

  //   const handleAddAnswer = () => {
  //     const nextIndex = answers.length + 1;

  //     const newAnswer = {
  //       _id: "",
  //       test_id: question?.test_id || "",
  //       parent_id: question?._id || "",
  //       media_id: uploadedFile?.id || "",
  //       index: nextIndex,
  //       title: `Câu trả lời số ${nextIndex}`,
  //       answer: "",
  //       type: "text",
  //       part: "listening",
  //     };

  //     setAnswers((prev) => [...prev, newAnswer]);
  //   };

  //   const handleSave = async () => {
  //     try {
  //       if (question) {
  //         const updatedQuestionResponse = await updateQuestion({
  //           _id: question._id,
  //           test_id: question.test_id,
  //           media_id: question.media_id || question.media_id?._id || "",
  //           title: question.title,
  //           content: question.content,
  //           type: question.type,
  //           part: question.part,
  //         });

  //         // const parentId = updatedQuestionResponse.data?._id || question._id;
  //         // const mediaId =
  //         //   updatedQuestionResponse?.data?.media_id || question.media_id || "";

  //         // for (let i = 0; i < answers.length; i++) {
  //         //   const answer = answers[i];

  //         //   const answerMediaId = mediaId;

  //         //   console.log("answerMediaId for answer", i, ":", answerMediaId);

  //         //   if (answer._id && answer._id !== "") {
  //         //     const answerUpdateResponse = await updateAnswer(
  //         //       answer._id,
  //         //       question.test_id,
  //         //       parentId,
  //         //       answerMediaId,
  //         //       answer.index,
  //         //       answer.title || "",
  //         //       answer.answer,
  //         //       answer.type || "",
  //         //       answer.part || ""
  //         //     );
  //         //   } else {
  //         //     const answerCreateResponse = await createAnswer(
  //         //       question.test_id,
  //         //       answerMediaId,
  //         //       parentId,
  //         //       answer.index,
  //         //       answer.title || "",
  //         //       answer.answer,
  //         //       answer.type || "",
  //         //       answer.part || ""
  //         //     );
  //         //   }
  //         // }
  //       }

  //       setSnackbarMessage("Cập nhật thành công!");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);
  //       await question();
  //       onClose();
  //     } catch (error) {
  //       setSnackbarMessage("Cập nhật thất bại!");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   };

  const handleSave = async () => {
    try {
      if (question) {
        console.log("Question to save:", question.test_id);
        const mediaId =
          typeof question.media_id === "object" && question.media_id !== null
            ? question.media_id._id
            : question.media_id || "";

        if (!question.test_id) {
          throw new Error("test_id is missing in question");
        }

        const updatedQuestionResponse = await updateQuestion({
          _id: question._id,
          test_id: question.test_id,
          title: question.title,
          content: question.content,
          type: question.type,
          part: question.part,
        });

        setSnackbarMessage("Cập nhật thành công!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        if (dataQuestions) {
          await dataQuestions(question.test_id);
        }

        onClose();
      }
    } catch (error) {
      console.error("Error during save:", error);
      setSnackbarMessage("Cập nhật thất bại!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 600,
          height: "600px",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
        ) : (
          <>
            <Typography
              id="create-question-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              Câu hỏi và câu trả lời
            </Typography>

            <TextField
              id="question-title"
              label="Tiêu đề câu hỏi"
              variant="outlined"
              size="small"
              fullWidth
              value={question?.title || ""}
              onChange={(e) => handleFieldChange("title", e.target.value)}
              sx={{ mb: 3 }}
            />

            <Stack direction="row" gap={1} mb={2}>
              <Box
                sx={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <ReactQuill
                  value={question?.content || ""}
                  onChange={(content) => handleFieldChange("content", content)}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ header: [1, 2, 3, false] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image"],
                    ],
                  }}
                  theme="snow"
                  style={{
                    flex: 1,
                    overflow: "auto",
                    maxHeight: "100%",
                  }}
                />
              </Box>
              {/* <Box width="100%" maxWidth={230}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none", minWidth: 230, height: 30 }}
                  onClick={handleAddAnswer}
                >
                  Thêm câu trả lời
                </Button>
                {answers
                  .sort((a, b) => a.index - b.index)
                  .map((answer, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder={`Câu trả lời ${answer.index}`}
                        value={answer.answer}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                      />
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => handleDeleteAnswer(index)}
                      >
                        <CiTrash />
                      </IconButton>
                    </Box>
                  ))}
              </Box> */}
            </Stack>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: "auto",
              }}
            >
              <Button variant="outlined" onClick={onClose}>
                Hủy
              </Button>
              <Button variant="contained" onClick={handleSave}>
                Lưu
              </Button>
            </Box>
          </>
        )}
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoadingUpload}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Modal>
  );
};
