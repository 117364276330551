import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { createEvaluation, updateEvaluation } from "../services";
import { Toast } from "@shopify/polaris";
import eventEmitter from "service/eventa-emiter";
const styleContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  gap: 1,
};
const style = { display: "flex", gap: 1, flexDirection: "column" };
const options = {
  onTime: {
    title: "On Time",
    options: [
      { name: "On Time", value: "Vào đúng giờ" },
      { name: "Late", value: "Đi muộn" },
      { name: "Excused Absence", value: "Nghỉ có phép" },
      { name: "Unexcused Absence", value: "Nghỉ không phép" },
    ],
  },
  attitude: {
    title: "Learning Attitude",
    options: [
      { name: "Good", value: true },
      { name: "Not Good", value: false },
    ],
  },
  takeNotes: {
    title: "Note-taking",
    options: [
      { name: "Complete", value: true },
      { name: "Incomplete", value: false },
    ],
  },
  doesHomework: {
    title: "Homework Completion",
    options: [
      { name: "Homework Completed", value: true },
      { name: "Homework Not Completed", value: false },
    ],
  },
};

interface FormPickerProps {
  title: string;
  options: Array<any>;
  value: any;
  callBack: (
    event: SelectChangeEvent,
    setState: React.Dispatch<React.SetStateAction<string | undefined | boolean>>
  ) => void;
  setState: React.Dispatch<React.SetStateAction<string | undefined | boolean>>;
}

const FormPicker: React.FC<FormPickerProps> = ({
  title,
  options,
  value,
  callBack,
  setState,
}) => {
  return (
    <Box sx={style}>
      <Typography>{title}</Typography>
      <FormControl sx={{ width: "100%" }}>
        <Select
          size="small"
          value={value ?? ""}
          onChange={(event) => callBack(event, setState)}
        >
          {options.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const EvaluationForm = ({
  dataParams,
  studentId,
  classId,
  handleCloseModal,
  setMessageSnackBar,
  //   messageSnackBar,
  toggleActive,
  reset,
}: {
  dataParams?: any;
  studentId?: string;
  classId?: string;
  handleCloseModal: () => void;
  setMessageSnackBar?: React.Dispatch<React.SetStateAction<string>>;
  //   messageSnackBar: string;
  toggleActive?: () => void;
  reset?: () => void;
}) => {
  const [date, setDate] = React.useState<Dayjs | null>(dayjs(dataParams?.date));
  const [onTime, setOnTime] = React.useState<string>(dataParams?.onTime || "");
  const [attitude, setAttitude] = React.useState<boolean>(
    dataParams?.goodAttitude
  );
  const [lessonParticipation, setLessonParticipation] = React.useState<string>(
    dataParams?.lessonParticipation || ""
  );
  const [improvementPoints, setImprovementPoints] = React.useState<string>(
    dataParams?.improvementPoints || ""
  );
  const [doesHomework, setDoesHomework] = React.useState<boolean>(
    dataParams?.doesHomework
  );
  const [takesNotes, setTakesNotes] = React.useState<boolean>(
    dataParams?.takesNotes
  );
  const isEdit = dataParams?.onTime;
  const isCanSubmit =
    !!onTime &&
    attitude !== undefined &&
    !!lessonParticipation &&
    doesHomework !== undefined &&
    takesNotes !== undefined;

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  };
  const handleChange = (
    event: SelectChangeEvent,
    setState: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    setState(event.target.value);
  };
  const handleSubmit = React.useCallback(() => {
    const data = {
      date: dataParams?.date || date,
      onTime: onTime,
      goodAttitude: attitude,
      lessonParticipation: lessonParticipation,
      takesNotes: takesNotes,
      doesHomework: doesHomework,
      improvementPoints: improvementPoints,
    };

    if (!isEdit) {
      const dataCreate = {
        studentId: studentId,
        classId: classId,
        ...data,
      };
      console.log("Create data====", dataCreate);
      createEvaluation(dataCreate).then((res) => {
        if (!res.isError) {
          setMessageSnackBar("Gửi đánh giá thành công");
          toggleActive();
          //   <Toast content= onDismiss={toggleActive} />;
          eventEmitter.emit("reload_evaluation");
          // !!reset && reset();
        } else {
          setMessageSnackBar("Có lỗi xảy ra!");
          toggleActive();
        }
      });
    } else {
      const dataUpdate = {
        _id: dataParams._id,
        ...data,
      };
      console.log("updated data====", dataUpdate);
      updateEvaluation(dataUpdate).then((res) => {
        if (!res.isError) {
          setMessageSnackBar("Cập nhật đánh giá thành công");
          toggleActive();
          eventEmitter.emit("reload_evaluation");
          // !!reset && reset();
        } else {
          setMessageSnackBar("Có lỗi xảy ra!");
          toggleActive();
        }
      });
    }
    handleCloseModal();
  }, [
    date,
    onTime,
    attitude,
    lessonParticipation,
    improvementPoints,
    doesHomework,
    takesNotes,
  ]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Box sx={style}>
          <Typography>Date</Typography>
          <DatePicker
            disabled={!!dataParams?.date ? true : false}
            defaultValue={date}
            onChange={handleDateChange}
          />
        </Box>
        <Box sx={style}>
          <FormPicker
            title={options.onTime.title}
            options={options.onTime.options}
            value={onTime}
            callBack={handleChange}
            setState={setOnTime}
          />
        </Box>
        <Box>
          <FormPicker
            title={options.attitude.title}
            options={options.attitude.options}
            value={attitude}
            callBack={handleChange}
            setState={setAttitude}
          />
        </Box>
        <Box sx={style}>
          <Typography>Participate in Lesson</Typography>
          <TextField
            size="small"
            value={lessonParticipation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, setLessonParticipation)
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Box>
          <FormPicker
            title={options.takeNotes.title}
            options={options.takeNotes.options}
            value={takesNotes}
            callBack={handleChange}
            setState={setTakesNotes}
          />
        </Box>
        <Box>
          <FormPicker
            title={options.doesHomework.title}
            options={options.doesHomework.options}
            value={doesHomework}
            callBack={handleChange}
            setState={setDoesHomework}
          />
        </Box>
        <Box sx={style}>
          <Typography>Points to Improve</Typography>
          <TextField
            size="small"
            value={improvementPoints}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, setImprovementPoints)
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          marginTop={2}
        >
          <Button
            sx={{ mt: 1, maxWidth: 100, width: "100%" }}
            onClick={handleCloseModal}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!isCanSubmit}
            sx={{ mt: 1, maxWidth: 100, width: "100%" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            //   startIcon={
            //     <SvgIcon>
            //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            //         <path
            //           d="M19 13C19.7 13 20.37 13.13 21 13.35V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H13.35C13.13 20.37 13 19.7 13 19C13 15.69 15.69 13 19 13M14 4.5L19.5 10H14V4.5M22.5 17.25L17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25Z"
            //           fill="currentColor"
            //         />
            //       </svg>
            //     </SvgIcon>
            //   }
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default React.memo(EvaluationForm);
