import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie } from "utils/setCookie";

export const getListRigisterSpeaking = async (params): Promise<any> => {
  const url = `${process.env.REACT_APP_AJAX_URL}test/speaking/list/admin`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params: params,
    };
    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updateSpeakingStatusAdmin = async (data: any): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `test/speaking/admin/update`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.patch(url, data, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getListTeacher = async (params): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + "user/list";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
      params,
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
