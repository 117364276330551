import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CiTrash } from "react-icons/ci";

interface CreateQuestionModalProps {
  open: boolean;
  formData: {
    title: string;
    content: string;
  };
  textFields: string[];
  uploadedFile: { name: string; id: string } | null;
  onClose: () => void;
  onSaveText: () => void;
  onSaveCheck: () => void;
  onFieldChange: (key: string, value: string) => void;
  onFileUpload: (files: FileList | null) => void;
  onRemoveFile: () => void;
  onAddAnswer: () => void;
  onAnswerChange: (index: number, value: string) => void;
  onDeleteAnswer: (index: number) => void;
  questions: Question[];
  addQuestion: () => void;
  handleAnswerChange: (
    questionIndex: number,
    answerIndex: number,
    newValue: string
  ) => void;
  handleDeleteAnswer: (questionIndex: number, answerIndex: number) => void;
  addAnswer: (questionIndex: number) => void;
  answersRadio: { label: string; value: string }[];
  setAnswersRadio: (value: { label: string; value: string }[]) => void;
  handleDeleteQuestion: (index: number) => void;
  handleSaveQuestion: () => void;
  handleSaveQuestionsText: () => void;
  handleSelectCorrectAnswer: (index: number) => void;
  setAnswer: (value: string) => void;
  answer: string;
  textBox: { title: string; content: string; answer: string }[];
  setTextBox: (
    value: { title: string; content: string; answer: string }[]
  ) => void;
  handleAddTextBox: () => void;
  handleDeleteTextBox: (index: number) => void;
  handleSelectCorrectAnswerCheckBox: (index: number) => void;
  handleSaveQuestionCheckBox: () => void;
  isLoading: boolean;
}

const TabPanel: React.FC<{ value: number; index: number }> = ({
  value,
  index,
  children,
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

const CreateQuestionModal: React.FC<CreateQuestionModalProps> = ({
  open,
  formData,
  onClose,
  onSaveText,
  onFieldChange,
  questions,
  addQuestion,
  handleAnswerChange,
  handleDeleteAnswer,
  addAnswer,
  handleDeleteQuestion,
  handleSaveQuestion,
  handleSaveQuestionsText,
  handleSelectCorrectAnswer,
  textBox,
  setTextBox,
  handleAddTextBox,
  handleDeleteTextBox,
  handleSelectCorrectAnswerCheckBox,
  handleSaveQuestionCheckBox,
  isLoading,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-question-title"
      aria-describedby="create-question-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 800,
          height: "600px",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          id="create-question-title"
          variant="h6"
          component="h2"
          sx={{ mb: 1 }}
        >
          Tạo câu hỏi mới
        </Typography>
        <Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Question Tabs"
          >
            <Tab label="Nhập câu hỏi" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Chọn 1 đáp án" id="tab-1" aria-controls="tabpanel-1" />
            <Tab
              label="Chọn nhiều đáp án"
              id="tab-2"
              aria-controls="tabpanel-2"
            />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            {/* <TextField
              id="question-title"
              label="Tiêu đề câu hỏi"
              variant="outlined"
              size="small"
              fullWidth
              value={formData.title}
              onChange={(e) => onFieldChange("title", e.target.value)}
              sx={{ mb: 3 }}
            /> */}

            {/* <Stack
              direction="row"
              justifyContent="space-between"
              gap={1}
              mb={2}
            >
              <Box width="100%">
                <ReactQuill
                  value={formData.content}
                  onChange={(content) => onFieldChange("content", content)}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ header: [1, 2, 3, false] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image"],
                    ],
                  }}
                  theme="snow"
                  style={{ width: "100%", boxSizing: "border-box" }}
                />
              </Box>
              <TextField
                size="small"
                placeholder="Nhập câu trả lời"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              /> */}
            {/* <Box>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none", minWidth: 230, height: 30 }}
                  onClick={onAddAnswer}
                >
                  Thêm câu trả lời
                </Button>
                {textFields.map((value, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={`Câu trả lời ${index + 1}`}
                      value={value}
                      onChange={(e) => onAnswerChange(index, e.target.value)}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => onDeleteAnswer(index)}
                    >
                      <CiTrash />
                    </IconButton>
                  </Box>
                ))}
              </Box> */}
            {/* </Stack> */}
            {textBox.map((question, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <TextField
                    id={`question-title-${index}`}
                    label={`Tiêu đề câu hỏi ${index + 1}`}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={question.title}
                    onChange={(e) => {
                      const updatedTextBox = [...textBox];
                      updatedTextBox[index].title = e.target.value;
                      setTextBox(updatedTextBox);
                    }}
                    sx={{}}
                  />
                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => handleDeleteTextBox(index)}
                    sx={{ ml: 1 }}
                  >
                    <CiTrash />
                  </IconButton>
                </Box>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={1}
                  mb={2}
                >
                  <Box width="100%">
                    <ReactQuill
                      value={question.content}
                      onChange={(content) => {
                        const updatedTextBox = [...textBox];
                        updatedTextBox[index].content = content || "";
                        setTextBox(updatedTextBox);
                      }}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          [{ header: [1, 2, 3, false] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                        ],
                      }}
                      theme="snow"
                      style={{ width: "100%", boxSizing: "border-box" }}
                    />
                  </Box>

                  <TextField
                    size="small"
                    placeholder={`Nhập câu trả lời cho câu hỏi ${index + 1}`}
                    value={question.answer}
                    onChange={(e) => {
                      const updatedTextBox = [...textBox];
                      updatedTextBox[index].answer = e.target.value;
                      setTextBox(updatedTextBox);
                    }}
                  />
                </Stack>
              </Box>
            ))}
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={handleAddTextBox}
            >
              Thêm câu hỏi
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: "auto",
                mt: 2,
              }}
            >
              <Button variant="outlined" onClick={onClose}>
                Hủy
              </Button>
              <Button variant="contained" onClick={handleSaveQuestionsText}>
                Lưu
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Box sx={{ p: 1 }}>
              {questions.map((question, questionIndex) => (
                <Box key={questionIndex} sx={{ mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      value={question.title}
                      onChange={(e) =>
                        onFieldChange(questionIndex, "title", e.target.value)
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ flex: 1 }}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => handleDeleteQuestion(questionIndex)}
                      sx={{ ml: 1 }}
                    >
                      <CiTrash />
                    </IconButton>
                  </Box>
                  <ReactQuill
                    value={question.content || ""}
                    onChange={(content) =>
                      onFieldChange(questionIndex, "content", content || "")
                    }
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ header: [1, 2, 3, false] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                      ],
                    }}
                    theme="snow"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      marginBottom: "10px",
                    }}
                  />
                  <Box
                    sx={{
                      border: "1px solid #81818199",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <RadioGroup
                      value={
                        question.answers.find((answer) => answer.checked)
                          ?.label || ""
                      }
                      onChange={(e) =>
                        handleSelectCorrectAnswer(questionIndex, e.target.value)
                      }
                    >
                      {question.answers.map((answer, answerIndex) => (
                        <Box
                          key={answer.label}
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <FormControlLabel
                            value={answer.label}
                            control={<Radio />}
                            label={`Câu ${answer.label}`}
                            sx={{ mr: 2 }}
                          />
                          <TextField
                            placeholder={`Nhập câu trả lời cho Câu ${answer.label}`}
                            variant="outlined"
                            size="small"
                            value={answer.value}
                            onChange={(e) =>
                              handleAnswerChange(
                                questionIndex,
                                answerIndex,
                                e.target.value
                              )
                            }
                            sx={{ flex: 1 }}
                          />
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() =>
                              handleDeleteAnswer(questionIndex, answerIndex)
                            }
                            disabled={question.answers.length <= 1}
                            sx={{ ml: 1 }}
                          >
                            <CiTrash />
                          </IconButton>
                        </Box>
                      ))}
                    </RadioGroup>

                    <Button
                      variant="contained"
                      onClick={() => addAnswer(questionIndex)}
                      sx={{ mt: 2, textTransform: "none" }}
                    >
                      Thêm câu trả lời
                    </Button>
                  </Box>
                </Box>
              ))}

              <Button
                variant="outlined"
                onClick={addQuestion}
                fullWidth
                sx={{ textTransform: "none" }}
              >
                Thêm câu hỏi
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginTop: "auto",
                  mt: 2,
                }}
              >
                <Button variant="outlined">Hủy</Button>
                <Button variant="contained" onClick={handleSaveQuestion}>
                  Lưu
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <Box sx={{ p: 1 }}>
              {questions.map((question, questionIndex) => (
                <Box key={questionIndex} sx={{ mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      value={question.title}
                      onChange={(e) =>
                        onFieldChange(questionIndex, "title", e.target.value)
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ flex: 1 }}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => handleDeleteQuestion(questionIndex)}
                      sx={{ ml: 1 }}
                    >
                      <CiTrash />
                    </IconButton>
                  </Box>
                  <ReactQuill
                    value={question.content || ""}
                    onChange={(content) =>
                      onFieldChange(questionIndex, "content", content || "")
                    }
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ header: [1, 2, 3, false] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                      ],
                    }}
                    theme="snow"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      marginBottom: "10px",
                    }}
                  />
                  <Box
                    sx={{
                      border: "1px solid #81818199",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    {question.answers.map((answer, answerIndex) => (
                      <Box
                        key={answer.label}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={answer.checked || false}
                              onChange={(e) =>
                                handleSelectCorrectAnswerCheckBox(
                                  questionIndex,
                                  answerIndex,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={`Câu ${answer.label}`}
                          sx={{ mr: 2 }}
                        />

                        <TextField
                          placeholder={`Nhập câu trả lời cho Câu ${answer.label}`}
                          variant="outlined"
                          size="small"
                          value={answer.value}
                          onChange={(e) =>
                            handleAnswerChange(
                              questionIndex,
                              answerIndex,
                              e.target.value
                            )
                          }
                          sx={{ flex: 1 }}
                        />
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() =>
                            handleDeleteAnswer(questionIndex, answerIndex)
                          }
                          disabled={question.answers.length <= 1}
                          sx={{ ml: 1 }}
                        >
                          <CiTrash />
                        </IconButton>
                      </Box>
                    ))}

                    <Button
                      variant="contained"
                      onClick={() => addAnswer(questionIndex)}
                      sx={{ mt: 2, textTransform: "none" }}
                    >
                      Thêm câu trả lời
                    </Button>
                  </Box>
                </Box>
              ))}

              <Button
                variant="outlined"
                onClick={addQuestion}
                fullWidth
                sx={{ textTransform: "none" }}
              >
                Thêm câu hỏi
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  marginTop: "auto",
                  mt: 2,
                }}
              >
                <Button variant="outlined">Hủy</Button>
                <Button
                  variant="contained"
                  onClick={handleSaveQuestionCheckBox}
                >
                  Lưu
                </Button>
              </Box>
            </Box>
          </TabPanel>
        </Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Modal>
  );
};

export default CreateQuestionModal;
