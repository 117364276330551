// CreateRedeem.js
import React, { useState, useEffect } from "react";
import { Box, Typography, Stack, TextField, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePicker,
  TimePicker,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { uploadRedeem } from "../service";

const EditRedeem = ({
  dataRedeem,
  handleCloseModalEdit,
  style,
  updateRedeemList,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  fetchData,
  setAnchorEl,
}) => {
  const [editData, setEditData] = useState({
    _id: "",
    title: "",
    start_time: " ",
    end_time: " ",
    point: 0,
    coin: 0,
  });

  useEffect(() => {
    if (dataRedeem) {
      setEditData({
        ...editData,
        _id: dataRedeem._id,
        title: dataRedeem.title,
        start_time: dataRedeem.start_time,
        end_time: dataRedeem.end_time,
        point: dataRedeem.point,
        coin: dataRedeem.coin,
      });
    }
  }, [dataRedeem]);

  const handleInputChangeTextField = (fieldName, value) => {
    setEditData({
      ...editData,
      [fieldName]: value,
    });
  };

  const handleSave = async () => {
    try {
      handleCloseModalEdit();
      setAnchorEl(null);

      await uploadRedeem(
        editData._id,
        editData.title,
        editData.start_time,
        editData.end_time,
        editData.point,
        editData.coin
      );

      setSnackbarMessage("Cập nhật thành công");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      await fetchData();
    } catch (error) {
      setSnackbarMessage("Cập nhật thất bại");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
        Create Redeem
      </Typography>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "3px",
          position: "relative",
        }}
      >
        <Typography className="comingSoon" variant="body1">
          Coming soon!
        </Typography>
        <Typography
          sx={{ borderBottom: "1px solid rgba(0,0,0,0.2)", padding: 1 }}
          variant="body1"
        >
          Time Picker
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Box>
            <Typography variant="body2">Starts</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="column">
                <MobileDatePicker
                  className="datePicker"
                  defaultValue={dayjs("2022-04-17")}
                />
                <MobileTimePicker
                  className="timePicker"
                  defaultValue={dayjs("2022-04-17T15:30")}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography variant="body2">End</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="column">
                <MobileDatePicker
                  className="datePicker"
                  defaultValue={dayjs("2022-04-17")}
                />
                <MobileTimePicker
                  className="timePicker"
                  defaultValue={dayjs("2022-04-17T15:30")}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Stack>
      </Box>

      <Box mt={1} mb={1}>
        <TextField
          value={editData.title}
          onChange={(event) =>
            handleInputChangeTextField("title", event.target.value)
          }
          fullWidth
          placeholder="Enter redeem name"
          variant="outlined"
        />
        <Stack direction="row" gap={2} alignItems="center" mt={1}>
          <TextField
            value={editData.point}
            label="Point"
            onChange={(event) =>
              handleInputChangeTextField(
                "point",
                parseInt(event.target.value, 10)
              )
            }
            fullWidth
            placeholder="Number point"
            variant="outlined"
          />
          <TextField
            value={editData.coin}
            label="Coin"
            onChange={(event) =>
              handleInputChangeTextField(
                "coin",
                parseInt(event.target.value, 10)
              )
            }
            fullWidth
            placeholder="Number coin"
            variant="outlined"
          />
        </Stack>
      </Box>
      <Stack direction="row" gap={2}>
        <Button onClick={handleCloseModalEdit} fullWidth variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} fullWidth variant="contained">
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default EditRedeem;
