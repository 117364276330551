import axios from "axios";
import { getCookie } from "utils/setCookie";

export const getListCoupon = async (
  title: string = "",
  payment_method: string = "all",
  visible: string = "public"
): Promise<any> => {
  const url = `${process.env.REACT_APP_AJAX_URL}coupon/list?order_by=DESC&title=${title}&payment_method=${payment_method}&visible=${visible}`;

  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.get(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch coupon data");
  }
};

export const createCoupon = async (
  title: string,
  code: string,
  description: string,
  payment_method: string,
  type: any,
  promotion: number,
  promotion_type: string,
  total: number,
  expired: string,
  availableAt: string,
  visible: string
): Promise<any> => {
  const requestData = {
    title,
    code,
    description,
    payment_method,
    type,
    promotion,
    promotion_type,
    total,
    expired,
    availableAt,
    visible,
  };
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "coupon/create";
  return axios.post(url, requestData, axiosConfig);
};

export const updateCoupon = async (
  _id: string,
  title: string,
  code: string,
  description: string,
  payment_method: string,
  type: any,
  promotion: number,
  promotion_type: string,
  total: number,
  expired: string,
  availableAt: string,
  visible: string
): Promise<any> => {
  const axiosConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const requestData = {
    _id,
    title,
    code,
    description,
    payment_method,
    type,
    promotion,
    promotion_type,
    total,
    expired,
    availableAt,
    visible,
  };
  const url = process.env.REACT_APP_AJAX_URL + "coupon/update";
  return axios.patch(url, requestData, axiosConfig);
};

export const deleteCoupon = async (_id: string): Promise<any> => {
  const url = process.env.REACT_APP_AJAX_URL + `coupon/delete/${_id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };

    return await axios.delete(url, axiosConfig);
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
