import React, { useState } from "react";
import { createRedeemMission } from "../service";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IoCloseOutline } from "react-icons/io5";

const CreateMission = ({
  selectedMissionId,
  handleCloseModalCreateMission,
  updateMissionList,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [redeemId, setRedeemId] = useState(selectedMissionId);
  const [title, setTitle] = useState("");
  const [actionType, setActionType] = useState("");
  const [actionTarget, setActionTarget] = useState("");
  const [actionAmount, setActionAmount] = useState(0);
  const [point, setPoint] = useState(0);
  const [coin, setCoin] = useState(0);
  const [navigate, setNavigate] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newMission = await createRedeemMission(
        redeemId,
        title,
        actionType,
        actionTarget,
        actionAmount,
        point,
        coin,
        navigate
      );

      setTitle("");
      setActionType("");
      setActionTarget("");
      setPoint(0);
      setCoin(0);
      setActionAmount(0);

      updateMissionList(newMission.data);

      setSnackbarMessage("Tạo thành công");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setLoading(false);
      handleCloseModalCreateMission(true);
      setError("");
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to fetch data.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setActionType(event.target.value as string);
  };

  const handleChangeTarget = (event: SelectChangeEvent) => {
    setActionTarget(event.target.value as string);
  };

  const getActionType = (type: string): string => {
    switch (type) {
      case "results":
        return "Kết quả";
      case "buy":
        return "Mua";
      case "comment":
        return "Bình luận";
      case "complete":
        return "Hoàn thành";
      case "join":
        return "Tham gia";
      case "like":
        return "Thích";
      case "post":
        return "Đăng";
      case "referral":
        return "Giới thiệu";
      case "share":
        return "Chia sẻ";
      case "view":
        return "Xem";
      case "watch":
        return "Theo dõi";
      default:
        return "Khác";
    }
  };

  const getActionTargetLabel = (target: string): string => {
    switch (target) {
      case "community":
        return "Cộng đồng";
      case "course":
        return "Khóa học";
      case "test":
        return "Kiểm tra";
      case "account":
        return "Tài khoản";
      case "product":
        return "Sản phẩm";
      case "class":
        return "Lớp học";
      case "oneone":
        return "Một kèm một";
      case "freecourse":
        return "Khóa học miễn phí";
      case "instagram":
        return "Instagram";
      case "telegram":
        return "Telegram";
      case "tiktok":
        return "TikTok";
      case "twitter":
        return "Twitter";
      case "facebook":
        return "Facebook";
      case "youtube":
        return "YouTube";
      default:
        return "Khác";
    }
  };

  const actionTypes = [
    "results",
    "buy",
    "comment",
    "complete",
    "join",
    "like",
    "post",
    "referral",
    "share",
    "view",
    "watch",
  ];

  const actionTargets = [
    "community",
    "course",
    "test",
    "account",
    "product",
    "class",
    "oneone",
    "freecourse",
    "instagram",
    "telegram",
    "tiktok",
    "twitter",
    "facebook",
    "youtube",
  ];

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Tạo nhiệm vụ</Typography>
        <IoCloseOutline
          onClick={handleCloseModalCreateMission}
          fontSize={20}
          cursor="pointer"
        />
      </Stack>

      <TextField
        fullWidth
        disabled
        label="Redeem ID"
        value={redeemId}
        onChange={(e) => setRedeemId(e.target.value)}
        sx={{ display: "none" }}
      />
      <TextField
        fullWidth
        label="Tiêu đề"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Điền tiêu đề"
        sx={{ mb: 2 }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        sx={{ mb: 2 }}
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Loại</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
            fullWidth
            value={actionType}
            onChange={handleChangeType}
          >
            {actionTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {getActionType(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Mục đích</InputLabel>
          <Select
            labelId="action-target-select-label"
            id="action-target-select"
            value={actionTarget}
            fullWidth
            label="Target"
            onChange={handleChangeTarget}
          >
            {actionTargets.map((target) => (
              <MenuItem key={target} value={target}>
                {getActionTargetLabel(target)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        sx={{ mb: 2 }}
      >
        <TextField
          fullWidth
          value={point}
          onChange={(e) => setPoint(parseFloat(e.target.value))}
          label="Point"
        />
        <TextField
          fullWidth
          value={coin}
          onChange={(e) => setCoin(parseFloat(e.target.value))}
          label="Coin"
        />
        <TextField
          fullWidth
          value={actionAmount}
          onChange={(e) => setActionAmount(parseFloat(e.target.value))}
          label="Amount"
        />
      </Stack>
      <TextField
        fullWidth
        disabled
        label="Navigate"
        value={navigate}
        onChange={(e) => setNavigate(e.target.value)}
        sx={{ mb: 2 }}
      />

      <Stack direction="row" gap={1} alignItems="center" justifyContent="end">
        <Button
          variant="outlined"
          onClick={handleCloseModalCreateMission}
          sx={{ border: "1px solid #2e3192", color: "#333", fontWeight: 550 }}
        >
          Hủy
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: "#2e3192", fontWeight: 550 }}
        >
          Tạo
        </Button>
      </Stack>
    </Box>
  );
};

export default CreateMission;
