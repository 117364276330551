import React, { useRef, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Modal,
  Fade,
  CircularProgress,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  Chip,
} from "@mui/material";
import { getDetailClub, updateClub } from "../service";
import { getCookie } from "utils/setCookie";
import { uploadMedia } from "entities/course/service";

interface Club {
  _id: string;
  name: string;
  description: string;
  featured_image: string[];
  isEliteClub: boolean;
  member_counter: number;
  createdAt: string;
  skills: string[];
}

interface ClubDetail {
  name: string;
  description: string;
  member_counter: number;
  createdAt: string;
  skills: string[];
  additional_info: string;
}

interface ListClubProps {
  clubs: Club[];
  placeholderImage: string;
}

const ListClub: React.FC<ListClubProps> = ({ clubs, placeholderImage }) => {
  const [loading, setLoading] = useState(false);
  const [selectedClub, setSelectedClub] = useState<string | null>(null);
  const [clubDetail, setClubDetail] = useState<ClubDetail | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cover, setCover] = useState<string>("");
  const [uploadingCover, setUploadingCover] = useState<boolean>(false);
  const [clubName, setClubName] = useState("");
  const [isEliteClub, setIsEliteClub] = useState(false);
  const [uploadingFeatured, setUploadingFeatured] = useState<boolean>(false);
  const [featuredImage, setFeaturedImage] = useState<
    { id: string; src: string }[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [selectedClubId, setSelectedClubId] = useState<string>("");

  const fileInputRefFeatured = useRef<HTMLInputElement | null>(null);
  const fileInputRefCover = useRef<HTMLInputElement | null>(null);

  const fetchClubDetail = async (
    id: string,
    setStateCallback: (detail: any) => void
  ) => {
    try {
      const detail = await getDetailClub(id);
      setStateCallback(detail);
    } catch (error) {
      console.error("Failed to fetch club detail:", error);
    }
  };

  const handleOpen = async (id: string) => {
    setSelectedClub(id);
    setIsLoading(true);

    await fetchClubDetail(id, (detail) => {
      setSelectedClubId(detail._id || "");
      setClubDetail(detail);
      setClubName(detail.name || "");
      setIsEliteClub(detail.isElite || false);
      setCover(detail.cover || "");
      setFeaturedImage(detail.featured_image || []);
      setSelectedCategories(detail.skills || []);
      setDescription(detail.description || "");
    });

    setIsLoading(false);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadingCover(true);
    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };
      const response = await uploadMedia(file, headers);
      setCover(response.src);
      // toast.success("Upload image success!");
    } catch (error) {
      // toast.error("Upload failed:", error);
    } finally {
      setUploadingCover(false);
    }
  };

  const handleFileChangeFeatured = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadingFeatured(true);
    try {
      const headers = {
        "X-Authorization": getCookie("session"),
      };
      const response = await uploadMedia(file, headers);
      setFeaturedImage((prev) => [
        ...prev,
        { id: response.id, src: response.src },
      ]);
      // toast.success("Upload image success!");
    } catch (error) {
      // toast.error("Upload failed:", error);
    } finally {
      setUploadingFeatured(false);
    }
  };

  const handleDeleteFeaturedImage = (idOrSrc: string) => {
    setFeaturedImage((prev) =>
      prev.filter((item) => {
        if (typeof item !== "string" && item.id === idOrSrc) return false;
        if (typeof item === "string" && item === idOrSrc) return false;
        return true;
      })
    );
  };

  const handleChangeCategories = (
    event: SelectChangeEvent<typeof selectedCategories>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = async () => {
    setLoading(true);

    const updatedFeaturedImages = featuredImage
      .map((image) => {
        if (typeof image === "string") {
          return image;
        } else if (image && image.src) {
          return image.src;
        } else {
          return null;
        }
      })
      .filter((image): image is string => image !== null);

    try {
      const result = await updateClub(
        selectedClubId,
        clubName,
        cover,
        description,
        isEliteClub,
        selectedCategories,
        updatedFeaturedImages,
        "vn"
      );
      // toast.success("Club updated successfully!");
      setSelectedClub(false);
      // await fetchGroups();
    } catch (error) {
      // toast.error("Failed to update club.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedClub(null);
    setClubDetail(null);
  };

  return (
    <Box width="100%" mt={2}>
      <Grid container spacing={2} p={3}>
        {clubs.map((club) => (
          <Grid item xs={12} sm={6} md={4} key={club._id}>
            <Box
              p={2}
              width="100%"
              height={350}
              boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
              bgcolor="#fff"
              borderRadius={3}
            >
              <Box width="100%" height={100}>
                <img
                  src={
                    club.featured_image && club.featured_image.length > 0
                      ? club.featured_image[0]
                      : placeholderImage
                  }
                  alt="banner"
                  width="100%"
                  height={100}
                  style={{ borderRadius: 8, objectFit: "cover" }}
                />
              </Box>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Box>
                  <Typography variant="body1" fontWeight={550}>
                    {club.name
                      ? club.name.split(" ").length > 3
                        ? club.name.split(" ").slice(0, 3).join(" ") + "..."
                        : club.name
                      : "Club Name"}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {club.description
                      ? club.description.split(" ").length > 3
                        ? club.description.split(" ").slice(0, 3).join(" ") +
                          "..."
                        : club.description
                      : "Không có mô tả nào"}
                  </Typography>
                </Box>
                {club.isEliteClub && (
                  <Typography
                    variant="body2"
                    fontWeight={550}
                    color="#B32519"
                    p="5px 15px"
                    borderRadius={1}
                    bgcolor="rgba(179, 37, 25, 0.1)"
                  >
                    Elite
                  </Typography>
                )}
              </Stack>

              <Box mt={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <Box
                    width="100%"
                    height={50}
                    bgcolor="#ebebeb5c"
                    p={0.7}
                    borderRadius={2}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontSize={12}
                    >
                      Thành viên
                    </Typography>
                    <Typography variant="body1" fontWeight={550}>
                      {club.member_counter || 0}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    height={50}
                    bgcolor="#ebebeb5c"
                    p={0.7}
                    borderRadius={2}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontSize={12}
                    >
                      Ngày tạo
                    </Typography>
                    <Typography variant="body1" fontWeight={550}>
                      {new Date(club.createdAt).toLocaleDateString() || "N/A"}
                    </Typography>
                  </Box>
                </Stack>
                <Box
                  width="100%"
                  height={50}
                  bgcolor="#ebebeb5c"
                  p={0.7}
                  borderRadius={2}
                  mt={1}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontSize={12}
                  >
                    Kĩ năng:
                  </Typography>
                  <Typography variant="body1" fontWeight={550} fontSize={14}>
                    {club.skills && club.skills.length > 0
                      ? club.skills.length > 2
                        ? club.skills.slice(0, 2).join(", ") + "..."
                        : club.skills.join(", ")
                      : "None"}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 1.5,
                    borderRadius: 4,
                    textTransform: "none",
                    bgcolor: "#B32519",
                    "&:hover": {
                      bgcolor: "#B32519",
                    },
                  }}
                  onClick={() => handleOpen(club._id)}
                >
                  Xem chi tiết Club
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* <Modal open={!!selectedClub} onClose={handleClose}>
        <Box
          width="400px"
          bgcolor="white"
          borderRadius={3}
          p={3}
          boxShadow="rgba(0, 0, 0, 0.25) 0px 4px 12px"
          mx="auto"
          mt={5}
        >
          {isLoading ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
              <Typography variant="body1" mt={2}>
                Đang tải...
              </Typography>
            </Stack>
          ) : clubDetail ? (
            <>
              <Typography variant="h6" fontWeight={600} mb={2}>
                {clubDetail.name}
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={1}>
                Mô tả: {clubDetail.description || "Không có mô tả nào"}
              </Typography>
              <Typography variant="body1" mb={1}>
                Thành viên: {clubDetail.member_counter || 0}
              </Typography>
              <Typography variant="body1" mb={1}>
                Ngày tạo:{" "}
                {new Date(clubDetail.createdAt).toLocaleDateString() || "N/A"}
              </Typography>
              <Typography variant="body1" mb={1}>
                Kĩ năng:{" "}
                {clubDetail.skills && clubDetail.skills.length > 0
                  ? clubDetail.skills.join(", ")
                  : "Không có Kĩ năng nào"}
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleClose}
              >
                Đóng
              </Button>
            </>
          ) : (
            <Typography variant="body1">
              Không thể tải thông tin chi tiết.
            </Typography>
          )}
        </Box>
      </Modal> */}

      <Modal
        open={!!selectedClub}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: 700,
            overflowY: "auto",
            maxWidth: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            fontWeight={550}
          >
            Chỉnh sửa câu lạc bộ
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            mb={2}
            mt={2}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: 150,
                height: "150px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (fileInputRefCover.current) {
                  fileInputRefCover.current.click();
                }
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  border: cover.length > 0 ? "none" : "2px dashed #ccc",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "#cfcfcf",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor:
                      cover.length > 0 ? "rgba(0, 0, 0, 0.5)" : "#f1f1f1",
                  },
                }}
              >
                {uploadingCover ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {cover.length > 0 ? (
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={cover}
                          alt={`Uploaded Cover ${cover}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography variant="body1" color="textSecondary">
                        Nhấn thêm hình ảnh
                      </Typography>
                    )}
                  </>
                )}
                <input
                  ref={fileInputRefCover}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
          </Stack>

          <TextField
            label="Club Name"
            variant="outlined"
            fullWidth
            value={clubName}
            onChange={(e) => setClubName(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box pl={1} mb={2}>
            <RadioGroup
              value={isEliteClub ? "elite" : "free"}
              onChange={(e) => setIsEliteClub(e.target.value === "elite")}
            >
              <Stack direction="row" gap={2}>
                <FormControlLabel
                  value="free"
                  control={<Radio />}
                  label="Free"
                />
                <FormControlLabel
                  value="elite"
                  control={<Radio />}
                  label="Elite Club"
                />
              </Stack>
            </RadioGroup>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              cursor: "pointer",
              mb: 2,
            }}
            onClick={() => {
              if (fileInputRefFeatured.current) {
                fileInputRefFeatured.current.click();
              }
            }}
          >
            <Box
              sx={{
                width: "100%",
                minHeight: "40px",
                border: "2px dashed #ccc",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "#cfcfcf",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              {uploadingFeatured ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Typography variant="body1" color="textSecondary">
                    Nhấn để tải nhiều hình ảnh
                  </Typography>
                </>
              )}
              <input
                ref={fileInputRefFeatured}
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChangeFeatured}
              />
            </Box>

            {/* Hiển thị danh sách ảnh */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {featuredImage.map((item, index) => (
                <Grid item xs={4} sm={3} md={3} key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={typeof item === "string" ? item : item.src}
                      alt={`Uploaded Featured ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFeaturedImage(
                          typeof item === "string" ? item : item.id
                        );
                      }}
                    >
                      <Typography variant="body2" color="error">
                        ✕
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <FormControl fullWidth>
            <InputLabel id="select-multiple-label">Categories</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={handleChangeCategories}
              input={<OutlinedInput label="Categories" />}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
            >
              <MenuItem
                value="Career Guidance"
                disabled={selectedCategories.includes("All skills")}
              >
                Career Guidance
              </MenuItem>
              <MenuItem
                value="Life"
                disabled={selectedCategories.includes("All skills")}
              >
                Life
              </MenuItem>
              <MenuItem
                value="Finance"
                disabled={selectedCategories.includes("All skills")}
              >
                Finance
              </MenuItem>
              <MenuItem
                value="Health"
                disabled={selectedCategories.includes("All skills")}
              >
                Health
              </MenuItem>
              <MenuItem
                value="Business"
                disabled={selectedCategories.includes("All skills")}
              >
                Business
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mt: 2 }}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
            <Button onClick={handleClose}>Hủy</Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                bgcolor: "#B32519 ",
                "&:hover": {
                  bgcolor: "#B32519 ",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Cập nhật"
              )}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ListClub;
