import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const WarningDeleteUser = ({
  openDeleteConfirm,
  handleClose,
  deleteUser,
  title,
  message,
}) => {
  // const handleClose = () => {
  //   setOpenDeleteConfirm(false);
  // };

  //   const handleConfirmDelete = () => {
  //     onDelete();
  //     setOpen(false);
  //   };

  // const handleSubmit = async () => {
  //   try {
  //     deleteUser();
  //   } catch (error) {
  //     console.error("Failed to delete redeem", error);
  //   }
  // };

  return (
    <div>
      <Dialog
        open={openDeleteConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Hủy
          </Button>
          <Button onClick={deleteUser} color="primary" autoFocus>
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WarningDeleteUser;
