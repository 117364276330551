import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { createCourseCallGroup } from "../service";
import { CreateGroup } from "../interface";
import { VscSymbolNamespace } from "react-icons/vsc";
import dayjs from "dayjs";
import { FaArrowDown, FaTimes } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import DeleteConfirmation from "components/delete_confirm";
import { DatePicker } from "@mui/x-date-pickers";

const CallCourse = ({ callGroup, getDetailCourse, styleModule }) => {
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const daysOfWeek = [
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
    "Chủ nhật",
  ];
  const [showCreateCall, setShowCreateCall] = useState(false);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [selectedTimeStart, setSelectedTimeStart] =
    useState<dayjs.Dayjs | null>(null);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const [selectedButton, setSelectedButton] = useState<string>("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [getIdClass, setGetIdClass] = useState<string>("");
  const [dataGroup, setDataGroup] = useState<CreateGroup>({
    name: "",
    end_time: "",
    course_id: getDetailCourse._id,
    start_time: "",
    limit_member: 0,
    course_calendars: [],
  });

  const handleDeleteClick = (id: string) => {
    setGetIdClass(id);
    setOpenDeleteConfirm(true);
  };

  const handleButtonClick = (time) => {
    setSelectedButton(time);
  };

  const handleShowCall = () => {
    setShowCreateCall(true);
  };

  const handleSubmit = async () => {
    if (!dataGroup.start_time || !dataGroup.end_time) {
      console.error("Start time and End time are required");
      return;
    }

    try {
      const response = await createCourseCallGroup(
        dataGroup.name,
        dataGroup.end_time,
        dataGroup.course_id,
        dataGroup.start_time,
        dataGroup.limit_member,
        dataGroup.course_calendars
      );
      setDataGroup(response);
      console.log("Response from createCourse:", response);
    } catch (error) {
      console.error("Error calling createCourse:", error);
    }
  };

  const handleChangeCallGroup = (name, value) => {
    setDataGroup({
      ...dataGroup,
      [name]: value,
    });
  };

  const handleChangeCallGroupNumber = (name, value) => {
    const parsedValue = value === "" ? 0 : parseInt(value);
    setDataGroup({
      ...dataGroup,
      [name]: parsedValue,
    });
  };

  const handleTimeStartChange = (time: dayjs.Dayjs | null) => {
    setSelectedTimeStart(time);
  };

  const handleAddItem = () => {
    if (selectedDay !== null && selectedTimeStart && selectedButton) {
      const newCalendarItem = {
        day: selectedDay,
        time_start: selectedTimeStart.format("HH:mm"),
        time_duration: parseInt(selectedButton),
      };
      setDataGroup((prevDataGroup) => ({
        ...prevDataGroup,
        course_calendars: [...prevDataGroup.course_calendars, newCalendarItem],
      }));
      setSelectedDay(null);
      setSelectedTimeStart(null);
      setSelectedButton("");
    }
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY");
  };

  return (
    <Box sx={styleModule}>
      {/* {showCreateCall && ( */}
      <Box mb={2} p={1} sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
        <Typography variant="body1">Tên lớp học:</Typography>
        <TextField
          fullWidth
          value={dataGroup.name}
          onChange={(e) => handleChangeCallGroup("name", e.target.value)}
          variant="outlined"
          size="small"
          placeholder="Điền tên lớp học"
          sx={{ mb: 2 }}
        />
        <Typography variant="body1">Số lượng người tham gia:</Typography>
        <TextField
          fullWidth
          value={dataGroup.limit_member}
          onChange={(e) =>
            handleChangeCallGroupNumber("limit_member", e.target.value)
          }
          variant="outlined"
          size="small"
          placeholder="Số lượng người tham gia"
          sx={{ mb: 2 }}
        />
        <Stack direction="row" gap={1} alignItems="center">
          <Button
            fullWidth
            variant={selectedButton === "1 giờ" ? "contained" : "outlined"}
            size="small"
            onClick={() => setSelectedButton("1 giờ")}
          >
            1 giờ
          </Button>
          <Button
            fullWidth
            variant={selectedButton === "2 giờ" ? "contained" : "outlined"}
            size="small"
            onClick={() => setSelectedButton("2 giờ")}
          >
            2 giờ
          </Button>
        </Stack>
        <Typography variant="body1">Chọn ngày dạy:</Typography>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          {weekDays?.map((day, index) => (
            <Button
              key={index}
              variant="contained"
              sx={{
                backgroundColor:
                  selectedDay === index ? "rgba(19, 44, 51, 0.8)" : "#132c33",
                color: "#fff",
              }}
              onClick={() => setSelectedDay(index)}
            >
              {day}
            </Button>
          ))}
        </Stack>
        <Typography variant="body1">Chọn thời gian dạy:</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="space-between"
          >
            <DatePicker
              className="datePicker"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                handleChangeCallGroup(
                  "start_time",
                  newValue ? newValue.format("YYYY-MM-DD") : ""
                );
              }}
              slotProps={{ textField: { placeholder: "Chọn ngày bắt đầu" } }}
              sx={{ width: "100%" }}
            />
            <DatePicker
              className="datePicker"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                handleChangeCallGroup(
                  "end_time",
                  newValue ? newValue.format("YYYY-MM-DD") : ""
                );
              }}
              slotProps={{ textField: { placeholder: "Chọn ngày kết thúc" } }}
              sx={{ width: "100%" }}
            />
          </Stack>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["TimePicker"]}>
            <TimePicker
              label="Chọn thời gian dạy: "
              value={selectedTimeStart}
              onChange={handleTimeStartChange}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box>
          <Typography variant="body2">Lịch học:</Typography>
          {dataGroup.course_calendars?.map((calendarItem, index) => (
            <Box key={index}>
              <Typography variant="body2">
                {daysOfWeek[calendarItem.day]}, Thời gian bắt đầu:{" "}
                {calendarItem.time_start}, Thời lượng:{" "}
                {calendarItem.time_duration} giờ
              </Typography>
            </Box>
          ))}
        </Box>
        <Button variant="contained" onClick={handleAddItem}>
          Thêm
        </Button>
      </Box>
      {/* )} */}
      <Button variant="contained" startIcon={<FiPlus />} onClick={handleSubmit}>
        Thêm lịch học mới
      </Button>
      <Divider sx={{ mt: 2, mb: 2 }}></Divider>

      <Typography variant="body1" fontWeight={550} mt={1} mb={2}>
        Lịch học:
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {callGroup.map((items, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box
                sx={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                  borderRadius: "3px",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: 1, borderBottom: "1px solid #f1f1f1" }}
                >
                  <Stack direction="row" gap={2} alignItems="center">
                    <VscSymbolNamespace fontSize={20} />
                    <Typography variant="body2">{items.name}</Typography>
                  </Stack>
                  <IconButton onClick={() => handleDeleteClick(items._id)}>
                    <LiaTimesSolid fontSize={18} />
                  </IconButton>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                >
                  <Typography variant="body2">Được tạo:</Typography>
                  <Typography variant="body2">
                    {formatDate(items.createAt)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                >
                  <Typography variant="body2">Số lượng thành viên:</Typography>
                  <Typography variant="body2">{items.limit_member}</Typography>
                </Stack>
                <Box p={1}>
                  {items.course_calendar_ids.length > 0 && (
                    <Accordion sx={{ boxShadow: "unset !important" }}>
                      <AccordionSummary
                        expandIcon={<FaArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: "0 !important",
                          minHeight: 15,
                          maxHeight: 15,
                        }}
                      >
                        <Typography variant="body2">
                          Có {items.course_calendar_ids.length} lịch học:{" "}
                        </Typography>
                      </AccordionSummary>
                      {items.course_calendar_ids.map(
                        (calendar, calendarIndex) => (
                          <AccordionDetails
                            key={calendarIndex}
                            sx={{ padding: "0" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              p={1}
                            >
                              <Typography variant="body2">
                                {daysOfWeek[calendar.day]}
                              </Typography>
                              <Typography variant="body2">
                                {calendar.time_start} - {calendar.time_end}
                              </Typography>
                            </Stack>
                          </AccordionDetails>
                        )
                      )}
                    </Accordion>
                  )}
                </Box>
                <Box p={1} sx={{ backgroundColor: "#f9f9f9" }}>
                  {items.members.length > 0 && (
                    <Accordion
                      sx={{
                        boxShadow: "unset !important",
                        backgroundColor: "transparent",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<FaArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: "0 !important",
                          minHeight: 15,
                          maxHeight: 15,
                        }}
                      >
                        <Typography variant="body2">
                          Có {items.members.length} thành viên trong lớp học:{" "}
                        </Typography>
                      </AccordionSummary>
                      {items.members.map((member, memberIndex) => (
                        <AccordionDetails
                          key={memberIndex}
                          sx={{ padding: "0" }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            p={1}
                          >
                            <img
                              src={member.user_avatar}
                              alt={member.display_name}
                              width={30}
                              style={{ borderRadius: "50%" }}
                            />
                            <Typography variant="body2">
                              {member.display_name}
                            </Typography>
                          </Stack>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  )}
                </Box>
              </Box>
              <DeleteConfirmation
                getIdClass={getIdClass}
                openDeleteConfirm={openDeleteConfirm}
                setOpenDeleteConfirm={setOpenDeleteConfirm}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CallCourse;
