import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "utils/setCookie";
import { ClubDetail } from "../interface";

export const getListClub = async (): Promise<any[]> => {
  const url = process.env.REACT_APP_AJAX_URL + "group/list?order_by=DESC";
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    const response = await axios.post(url, {}, axiosConfig);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch group data");
  }
};

export const getDetailClub = async (id: string): Promise<ClubDetail> => {
  const url = process.env.REACT_APP_AJAX_URL + `group/detail/${id}`;
  try {
    const axiosConfig = {
      headers: {
        "X-Authorization": getCookie("session"),
      },
    };
    const response = await axios.get(url, axiosConfig);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch group data");
  }
};

export const updateClub = async (
  _id: string,
  name: string,
  cover: string,
  description: string,
  isEliteClub: boolean,
  skills: string[],
  featured_image: string[],
  location: string
): Promise<any> => {
  const requestData = {
    _id,
    name,
    cover,
    description,
    isEliteClub,
    skills,
    featured_image,
    location,
  };
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      "X-Authorization": getCookie("session"),
    },
  };
  const url = process.env.REACT_APP_AJAX_URL + "group/admin-update";
  return axios.patch(url, requestData, axiosConfig);
};
